import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.scss']
})
export class HistoriqueComponent implements OnInit {

  @Input() user: any;

  purchases: any;
  purchasesSub: any;
  credits: any
  
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.listPurchases();
    this.listCredits();
    this.listPurchasesSub();
  }

  listPurchases() {
    this.apiService.listPurchases().pipe(first()).subscribe({
      next: (v) => {
        v.data.forEach((element: any) => {
          element.created_at = new Date(element.created_at.substring(0, 10)).toLocaleDateString("fr");
        });
        this.purchases = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  listCredits() {
    this.apiService.listCreditsUse().pipe(first()).subscribe({
      next: (v) => {
        v.data.forEach((element: any) => {
          element.created_at = new Date(element.created_at.substring(0, 10)).toLocaleDateString("fr");
        });
        this.credits = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  listPurchasesSub() {
    this.apiService.listPurchasesActiveSub().pipe(first()).subscribe({
      next: (v) => {
        v.data.forEach((element: any) => {
          element.created_at = new Date(element.created_at.substring(0, 10)).toLocaleDateString("fr");
        });
        this.purchasesSub = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  viewSent(id: any) {
    this.router.navigate(['/sent/' + id]);
  }

  navigateCredits() {
    this.router.navigate(['/account/credits']);
  }

}
