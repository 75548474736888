import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { first } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss']
})
export class InfosComponent implements OnInit {

  @Input() user: any;

  username: any;
  firstname: any;
  lastname: any;
  email: any;
  password: any;

  constructor(private snackBar: MatSnackBar, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {

  }

  ngOnChanges() {
    if (!this.user) return;
    this.username = this.user.username;
    this.firstname = this.user.firstname;
    this.lastname = this.user.lastname;
    this.email = this.user.email;  
  }

  onSave() {
    let modifsObject: any = {};
    
    if (this.username !== this.user.username) modifsObject.username = this.username;
    if (this.firstname !== this.user.firstname) modifsObject.firstname = this.firstname;
    if (this.lastname !== this.user.lastname) modifsObject.lastname = this.lastname;
    if (this.email !== this.user.email) modifsObject.email = this.email;
    if (this.password !== undefined) modifsObject.password = this.password;

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editUser(this.user.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
    }
  }

}
