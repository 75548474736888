import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-user-board',
  templateUrl: './user-board.component.html',
  styleUrls: ['./user-board.component.scss'],
})
export class UserBoardComponent implements OnInit {
  isLoading: boolean = true;

  brandRes: any;
  brand: any;

  boxes: any;
  activeBoxes: any;
  privateBoxes: any;
  sents: any;
  lastSents: any = [];
  models: any;
  categories: any;
  questions: any;
  formsResponses: number = 0;
  credits: any;
  user: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public brandsService: BrandsService
  ) {
    this.brandsService.getBrand().subscribe((res: any) => {
      this.brandRes = res;
      this.initialization();
    });
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.listBoxes();
    this.listSents();
    this.listModels();
    this.listCategories();
    this.listQuestions();
    this.listForms();
    this.getUser();
    this.getBrand(this.brandRes);
  }

  getBrand(id: any) {
    this.apiService
      .getBrand(id)
      .pipe(first())
      .subscribe({
        next: (v) => {
          // this.brand = v.data[0];
          this.brand = v.data;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        },
        error: (e) => console.error(e),
      });
  }

  listBoxes() {
    this.apiService
      .listBoxes()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.boxes = v.data;
          this.activeBoxes = this.boxes.filter(
            (x: any) => x.status == 4 || x.status == 5 || x.status == 10
          );
          this.privateBoxes = this.boxes.filter((x: any) => x.status == 10);
        },
        error: (e) => console.error(e),
      });
  }

  listSents() {
    this.apiService
      .listSentIds()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.sents = v.data;
          this.getSents([
            this.sents[0],
            this.sents[1],
            this.sents[2],
            this.sents[3],
            this.sents[4],
          ]);
        },
        error: (e) => console.error(e),
      });
  }

  getSents(ids: any) {
    this.apiService
      .getSents(ids)
      .pipe(first())
      .subscribe({
        next: (v) => {
          let arr = v.data.reverse();
          arr.forEach((data: any) => {
            data.created_at = new Date(
              data.created_at.substring(0, 10)
            ).toLocaleDateString('fr');
          });
          this.lastSents = arr;
        },
        error: (e) => console.error(e),
      });
  }

  listModels() {
    this.apiService
      .listModels()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.models = v.data;
        },
        error: (e) => console.error(e),
      });
  }

  listCategories() {
    this.apiService
      .listCategories()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.categories = v.data;
        },
        error: (e) => console.error(e),
      });
  }

  listQuestions() {
    this.apiService
      .listQuestions()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.questions = v.data;
        },
        error: (e) => console.error(e),
      });
  }

  listForms() {
    this.apiService
      .listForms()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.formsResponses = 0;
          let datas = v.data;
          datas.forEach((data: any) => {
            data['nbrFormResponses'] = 0;
            data.boxes.forEach((element: any) => {
              data['nbrFormResponses'] =
                data['nbrFormResponses'] + element.form_responses.length;
            });
            this.formsResponses =
              this.formsResponses + Number(data['nbrFormResponses']);
          });
        },
        error: (e) => console.error(e),
      });
  }

  getUser() {
    const jsonData: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(jsonData);
    if (userData) {
      this.apiService
        .getUser(userData.id)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.user = v.data[0];
            console.log(this.user);
            this.credits = v.data[0].credits;
            this.apiService
              .listActiveSubscription()
              .pipe(first())
              .subscribe({
                next: (v) => {
                  if (v.data.length == 0) {
                    this.user.subscription_type_active = this.user.subscription_type;
                    return;
                  }

                  const dateDisabled = new Date(
                    v.data[v.data.length - 1].disable_date
                  );
                  const lastPayment = new Date(
                    v.data[v.data.length - 1].next_payment
                  );

                  if (dateDisabled == null) {
                    this.user.subscription_type_active =
                      v.data[v.data.length - 1].subscription_type;
                  } else if (lastPayment != null && new Date() < lastPayment) {
                    this.user.subscription_type_active =
                      v.data[v.data.length - 1].subscription_type;
                  } else if (
                    lastPayment != null &&
                    new Date() > lastPayment &&
                    new Date() > dateDisabled
                  ) {
                    this.user.subscription_type_active = 'Freemium';
                  }
                },
                error: (e) => console.error(e),
              });
          },
          error: (e) => console.error(e),
        });
    }
  }

  viewSent(sent: any) {
    this.router.navigate(['/sent/' + sent.id]);
  }

  viewBoxes() {
    this.router.navigate(['/contents', 'boxes']);
  }

  viewSents() {
    this.router.navigate(['/sent']);
  }

  viewModeles() {
    this.router.navigate(['/contents', 'models']);
  }

  viewCategories() {
    this.router.navigate(['/contents', 'categories']);
  }

  viewQuestions() {
    this.router.navigate(['/contents', 'questions']);
  }

  viewForms() {
    this.router.navigate(['/contents', 'forms']);
  }

  viewCredits() {
    this.router.navigate(['/account', 'credits']);
  }

  viewAbonnements() {
    this.router.navigate(['/account', 'abonnement']);
  }
}
