<mat-progress-bar
  mode="indeterminate"
  color="primary"
  *ngIf="isLoading"
></mat-progress-bar>
<div class="page">
  <div class="head">
    <h1>Mes boîtes à questions</h1>
    <div
      *ngIf="
        user &&
        (user.subscription_type_active == 'Freemium' ||
          user.subscription_type_active == 'Basic')
      "
      class="cta"
    >
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewAbonnements()">
        <strong>Passer en Premium</strong>
      </button>
    </div>
    <div *ngIf="user && user.subscription_type_active == 'Premium'" class="cta">
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewCredits()">
        Acheter des crédits vidéo
      </button>
    </div>
  </div>
  <div class="content">
    <mat-tab-group
      mat-stretch-tabs="true"
      [selectedIndex]="currentTabIndex"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab label="Boîtes">
        <app-boxes></app-boxes>
      </mat-tab>
      <mat-tab
        label="Questions"
        *ngIf="
          user &&
          user.subPlan &&
          user.subPlan.parameters &&
          user.subPlan.parameters.nav_questions_list == 2
        "
      >
        <app-questions></app-questions>
      </mat-tab>
      <mat-tab
        label="Formulaires"
        *ngIf="
          user &&
          user.subPlan &&
          user.subPlan.parameters &&
          user.subPlan.parameters.nav_form_list == 2
        "
      >
        <app-forms></app-forms>
      </mat-tab>
      <mat-tab
        label="Catégories"
        *ngIf="
          user &&
          user.subPlan &&
          user.subPlan.parameters &&
          user.subPlan.parameters.nav_categories_list == 2
        "
      >
        <app-categories></app-categories>
      </mat-tab>
      <mat-tab
        label="Modèles"
        *ngIf="
          user &&
          user.subPlan &&
          user.subPlan.parameters &&
          user.subPlan.parameters.nav_modeles_list == 2
        "
      >
        <app-models></app-models>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
