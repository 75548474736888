import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss']
})
export class ConfigsComponent implements OnInit {

  isLoading: boolean = true;
  configs: any;
  displayedColumns: string[] = ['title', 'value', 'edit'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
    this.listConfigs();
  }

  listConfigs() {
    this.isLoading = true;
    this.apiService.listConfigs().pipe(first()).subscribe({
      next: (v) => {
        let datas = v.data;
        datas.forEach((data: any) => {
          data['titleWithoutAccents'] = data.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        });

        this.configs = new MatTableDataSource(datas);
        this.configs.paginator = this.paginator;
        
        this.paginator._intl.itemsPerPageLabel = "Configuration par page";
        this.paginator._intl.nextPageLabel = 'Page suivante';
        this.paginator._intl.previousPageLabel = 'Page précédente'; 
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 à ${length }`;
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1} - ${endIndex} sur ${length}`;
        };
        this.isLoading = false;
      },
      error: (e) => console.error(e)
    });
  }

  filterTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.configs.filter = filterValue.trim().toLowerCase();
  }

  onEdit(config: any) {
    this.router.navigate(['/configs/edit/' + config.id]);
  }

  onAddConfig() {
    const dialogRef = this.dialog.open(DialogCreateConfig, {
      data: {title: '', value: ''},
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.title && result.value) {
        this.apiService.addConfig(result.title, result.value).pipe(first()).subscribe({
          next: (v) => {
            this.listConfigs();
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      } else {
        this.snackBar.open("Il faut renseigner un nom et une valeur", "OK", {
          panelClass: ['warning-snackbar']
        });
      }
    });
  }

}

export interface DialogData {
  title: string;
  value: string;
}

@Component({
  selector: 'add-config',
  templateUrl: './add-config.html',
})
export class DialogCreateConfig implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCreateConfig>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.dialogRef.close(this.data);
  }

  onNoClick() {
    this.data.title = '';
    this.data.value = '';
    this.dialogRef.close();
  }

}