<div *ngIf="user" class="page">
  <div class="head">
    <h1>{{user.username}}</h1>
    <mat-checkbox *ngIf="admin" [(ngModel)]="is_admin">Administrateur</mat-checkbox>
  </div>
  <mat-card>
    <div class="infos">
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Nom d'utilisateur</mat-label>
          <input matInput type="text" [(ngModel)]="username">
        </mat-form-field>
        <div style="display: flex; flex-direction: column; width: 100%; margin: 10px; position: relative;">
          <mat-form-field appearance="fill" style="margin: 10px 0;">
            <mat-label>Email</mat-label>
            <input matInput type="text" [(ngModel)]="email">
          </mat-form-field>
          <mat-checkbox [(ngModel)]="is_active" style="width: auto; position: absolute; bottom: 0;">Email actif</mat-checkbox>
        </div>

      </div>

      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Nom</mat-label>
          <input matInput type="text" [(ngModel)]="lastname">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Prénom</mat-label>
          <input matInput type="text" [(ngModel)]="firstname">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Nouveau mot de passe</mat-label>
          <input matInput type="text" [(ngModel)]="password">
        </mat-form-field>

        <mat-form-field *ngIf="admin" appearance="fill">
          <mat-label>Marque(s)</mat-label>
          <mat-select [formControl]="brands" type="text" multiple placeholder="Selectionner une ou plusieurs marques"
            (selectionChange)="onBrandsSelect($event)">
            <mat-select-trigger>Sélectionner</mat-select-trigger>
            <mat-option *ngFor="let brand of allBrands" [value]="brand.id">
              {{brand.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="action">
      <button mat-raised-button class="success" (click)="onSave()">
        Enregistrer
      </button>
    </div>
    <div class="action">
      <button mat-raised-button class="success" (click)="giveCredits()" *ngIf="iAmAdmin">
        Offrir des crédits
      </button>
    </div>
  </mat-card>
</div>