<mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
<div class="page" *ngIf="question">
  <div class="head">
    <button mat-icon-button class="close" matTooltip="Fermer sans sauvegarder" (click)="close()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h1 class="title" *ngIf="question.new">
      Création d'une question
    </h1>
    <h1 class="title" *ngIf="!question.new">
      Modification d'une question
    </h1>
    <button mat-icon-button class="delete" matTooltip="Supprimer la question" (click)="onDelete()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="question-title">
      <mat-form-field appearance="fill">
        <mat-label>Saisir la question</mat-label>
        <input matInput type="text" [(ngModel)]="question.title" (input)="isTitleValid()">
        <!-- <input matInput type="text" [(ngModel)]="question.title"> -->
      </mat-form-field>
    </div>
    <div *ngIf="isTitleDisabled" style="color: red; width: 480px; margin: auto auto 32px auto; font-weight: 600;">N'utilisez pas d'emojis ou de caractères spéciaux comme #,&,*,/... svp.</div>

    <app-slide-generator *ngIf="generator && box.encode_type == 2" [text]="question.title" [encodeType]="box.encode_type" [config]="question.config" (slideGenerated)="slideGenerated($event)"></app-slide-generator>

    <div class="rowSlide" *ngIf="!generator && box.encode_type == 2">
      <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectSlide($event)" *ngIf="fileSlides?.length == 0">
          <mat-icon>cloud_upload</mat-icon>
      </div>
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileSlides" [file]="f" [removable]="true" (removed)="onRemoveSlide()" id="preview-img-edit-question-light">
      </ngx-dropzone-image-preview>

      <div class="text-content">
        L'image devrait être au format PNG ou JPG, d'une hauteur minimale de 1024 pixels et d'une largeur minimale de 1365 pixels pour un rendu optimal.
      </div>

      <div class="color">
        <h2 style="margin-bottom: 0;">Couleur de fond :</h2>
        <input type="color" name="bg_color" [(ngModel)]="bgColor">
      </div>

      <div class="text-content">
        La couleur de fond est utilisée lors du du montage final de la vidéo, dans le cas où l'image ne peut pas occuper toute la largeur de la vidéo.
      </div>
    </div>

    <div class="btns" *ngIf="box.encode_type == 2">
      <button mat-raised-button class="success" (click)="onSaveQuestion()" *ngIf="!generator" [ngClass]="{'disabled': isTitleDisabled}">
        Valider
      </button>

      <div class="or" *ngIf="box.encode_type == 2">
        Ou
      </div>

      <button mat-raised-button (click)="generatorToggle()" *ngIf="generator && box.encode_type == 2">
        Télécharger une image
      </button>

      <button mat-raised-button (click)="generatorToggle()" *ngIf="!generator">
        Créer mon image
      </button>
    </div>

    <div class="question-video" *ngIf="box.encode_type == '3'">
      <div class="rowSlide">

        <div class="title">
          Vidéo de la question à afficher à l'utilisateur
        </div>

        <div class="custom-dropzone" ngx-dropzone [accept]="'video/*'" (change)="onSelectVideo($event)" *ngIf="canUploadVideo">
            <mat-icon>cloud_upload</mat-icon>
        </div>

        <button mat-icon-button class="remove-video" matTooltip="Remplacer la vidéo" (click)="onRemoveVideo()" *ngIf="videoPreview">
          <mat-icon>close</mat-icon>
        </button>
        <video [src]="videoPreview" *ngIf="videoPreview" controls></video>

        <div class="text-content">
          La vidéo devrait être au format MP4 ou MPEG, d'une hauteur minimale de 1080 pixels et d'une largeur minimale de 1920 pixels pour un rendu optimal.
          <br><br>
          Pensez aux petites connexions, envoyez vos vidéos dans le format le plus léger possible.
        </div>

      </div>

      <button mat-raised-button class="success" (click)="onSaveQuestion()" [ngClass]="{'disabled': isTitleDisabled}">
        Valider
      </button>
    </div>

    <div class="btns" *ngIf="box.encode_type == '1'">
      <button mat-raised-button class="success" (click)="onSaveQuestion()" [ngClass]="{'disabled': isTitleDisabled}">
        Valider
      </button>
    </div>

  </div>
</div>