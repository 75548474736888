<div class="questionProblem" *ngIf="box && box.has_problem">
  Attention, votre boîte contient des questions qui ne sont pas correctement configurées. Veuillez
  <a [routerLink]="'/boxes/edit-box/scenario/' + box.id">les modifier</a>
</div>

<mat-progress-bar
  mode="indeterminate"
  color="primary"
  *ngIf="isLoadingNext"
></mat-progress-bar>
<div class="page" *ngIf="box">
  <div class="head">
    <div class="back">
      <button
        mat-icon-button
        class="copy"
        matTooltip="Revenir"
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>Configuration de la page d'enregistrement pour vos utilisateurs</h1>
    <div class="empty"></div>
  </div>

  <mat-card
    *ngIf="
      user &&
      user.subPlan &&
      user.subPlan.parameters &&
      (user.subPlan.parameters.rec_box_illustration == 1 ||
        user.subPlan.parameters.rec_box_illustration == 2)
    "
  >
    <div
      class="activate"
      *ngIf="user.subPlan.parameters.rec_box_illustration == 1"
    >
      <button mat-button class="success pink" (click)="seePlans()">
        Activer cette fonction
      </button>
    </div>
    <div class="title">Illustration pour votre boîte</div>

    <div class="subtitle">
      D’un format conseillé de 400 x 150.
      <br />
      L’illustration pourra être utilisée lors d’une publication de votre boite
      en mode public.
    </div>

    <div class="rowSlide">
      <div
        class="custom-dropzone"
        ngx-dropzone
        [accept]="'image/*'"
        (change)="onSelectMedia($event)"
        *ngIf="(fileMedia?.length == 0 && !isLoading) || !this.box.cover.length"
      >
        <mat-icon>cloud_upload</mat-icon>
      </div>
      <mat-spinner
        *ngIf="fileMedia?.length == 0 && isLoading && this.box.cover.length"
        >sync</mat-spinner
      >
      <ngx-dropzone-image-preview
        ngProjectAs="ngx-dropzone-preview"
        *ngFor="let f of fileMedia"
        [file]="f"
        [removable]="true"
        (removed)="onRemoveMedia()"
      >
      </ngx-dropzone-image-preview>
    </div>
  </mat-card>

  <mat-card
    class="content"
    *ngIf="
      user &&
      user.subPlan &&
      user.subPlan.parameters &&
      (user.subPlan.parameters.rec_box_general == 1 ||
        user.subPlan.parameters.rec_box_general == 2)
    "
  >
    <div class="activate" *ngIf="user.subPlan.parameters.rec_box_general == 1">
      <button
        mat-button
        class="success pink"
        (click)="seePlans('PAQ_ACTIVERGENERAL')"
      >
        Activer cette fonction
      </button>
    </div>

    <div class="title">Général</div>

    <mat-form-field
      appearance="fill"
      *ngIf="box.status && user && user.is_admin"
    >
      <mat-label>Statuts</mat-label>
      <mat-select
        [(ngModel)]="box.status"
        (selectionChange)="onSelectStatus($event.value)"
      >
        <mat-option
          *ngFor="let element of status_type_array"
          [value]="element.value"
        >
          {{ element.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="box.status == 5">
      <mat-label>Code salon</mat-label>
      <input
        matInput
        maxlength="4"
        minlength="4"
        type="text"
        placeholder="Code"
        [(ngModel)]="box.admin_exhibition_code"
        pattern="^[0-9]*$"
        required
      />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Ordre des questions</mat-label>
      <mat-select
        [(ngModel)]="box.order_type"
        (selectionChange)="onOrderTypeSelect($event.value)"
      >
        <mat-option
          *ngFor="let element of order_type_array"
          [value]="element.value"
        >
          {{ element.text }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br /><br />

    <mat-checkbox [(ngModel)]="box.interview"
      >Donner la possibilité à l’utilisateur de valider chaque réponse ou de
      recommencer son enregistrement</mat-checkbox
    >

    <br /><br />

    <mat-checkbox
      [(ngModel)]="onlyEmail"
      (change)="
        onlyEmail ? (box.mandatory_form = false) : (box.mandatory_form = true)
      "
      >Je souhaite récupérer l'adresse email de mon utilisateur</mat-checkbox
    >
    <mat-form-field
      style="margin-top: 20px"
      appearance="fill"
      *ngIf="onlyEmail"
    >
      <mat-label>Demander l'email</mat-label>
      <mat-select [(ngModel)]="box.mail_end">
        <mat-option value="start"> Avant la boîte </mat-option>
        <mat-option value="end"> Après la boîte </mat-option>
      </mat-select>
    </mat-form-field>

    <br /><br />

    <!-- <mat-checkbox
      [(ngModel)]="box.mandatory_form"
      (change)="box.mandatory_form ? (onlyEmail = false) : (onlyEmail = true)"
      >Je souhaite un formulaire qui demande plus d'informations</mat-checkbox
    > -->
    <mat-form-field
      style="margin-top: 20px"
      appearance="fill"
      *ngIf="box.mandatory_form"
    >
      <mat-label>Formulaire</mat-label>
      <mat-select [(ngModel)]="box.form_id">
        <mat-option *ngFor="let form of allForms" [value]="form.id">
          {{ form.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <mat-card
    *ngIf="
      user &&
      user.subPlan &&
      user.subPlan.parameters &&
      (user.subPlan.parameters.rec_box_description == 1 ||
        user.subPlan.parameters.rec_box_description == 2)
    "
  >
    <div
      class="activate"
      *ngIf="user.subPlan.parameters.rec_box_description == 1"
    >
      <button mat-button class="success pink" (click)="seePlans()">
        Activer cette fonction
      </button>
    </div>

    <div class="title">Texte de description de la "Boîte à Questions"</div>
    <editor
      [(ngModel)]="box.moreinfo"
      ngDefaultControl
      modelEvents="change input nodechange undo redo"
      [apiKey]="tinyMCE"
      [init]="tinyMceInit"
    >
    </editor>
  </mat-card>

  <mat-card
    *ngIf="
      user &&
      user.subPlan &&
      user.subPlan.parameters &&
      (user.subPlan.parameters.rec_box_waiting_text == 1 ||
        user.subPlan.parameters.rec_box_waiting_text == 2)
    "
  >
    <div
      class="activate"
      *ngIf="user.subPlan.parameters.rec_box_waiting_text == 1"
    >
      <button
        mat-button
        class="success pink"
        (click)="seePlans('PAQ_ACTIVERTXTENCODE')"
      >
        Activer cette fonction
      </button>
    </div>

    <div class="waitingText">
      <div class="title">Texte de la page d'attente pendant l'encodage</div>
      <div class="subtitle">
        Ce texte est affiché aux utilisateurs pendant le traitement de leurs
        vidéos. Le début du texte est affiché et vos utilisateurs peuvent
        décider de voir l’ensemble du contenu. Une bonne occasion pour parler de
        vous, votre projet, votre entreprise …
      </div>
      <editor
        [(ngModel)]="box.waiting_text"
        ngDefaultControl
        modelEvents="change input nodechange undo redo"
        [apiKey]="tinyMCE"
        [init]="tinyMceInit"
      >
      </editor>
    </div>
  </mat-card>

  <div class="medias">
    <mat-card
      class="media"
      *ngIf="
        user &&
        user.subPlan &&
        user.subPlan.parameters &&
        (user.subPlan.parameters.rec_box_logo == 1 ||
          user.subPlan.parameters.rec_box_logo == 2)
      "
    >
      <div class="activate" *ngIf="user.subPlan.parameters.rec_box_logo == 1">
        <button
          mat-button
          class="success pink"
          (click)="seePlans('PAQ_ACTIVERLOGO')"
        >
          Activer cette fonction
        </button>
      </div>
      <div class="headMedia">
        <div class="title">Logo</div>
        <div class="subtitle">
          D’un format minimum de 200px de large.
          <br />
          Présent sur les pages d’enregistrement de vos boîtes.
        </div>
      </div>
      <div class="rowSlide">
        <div
          class="custom-dropzone"
          ngx-dropzone
          [accept]="'image/*'"
          (change)="onSelectLogo($event)"
          *ngIf="
            (fileLogo?.length == 0 && !isLoading) || !this.model.logo.length
          "
        >
          <mat-icon>cloud_upload</mat-icon>
        </div>
        <mat-spinner
          *ngIf="fileLogo?.length == 0 && isLoading && this.model.logo.length"
          >sync</mat-spinner
        >
        <ngx-dropzone-image-preview
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let f of fileLogo"
          [file]="f"
          [removable]="true"
          (removed)="onRemoveLogo()"
        >
        </ngx-dropzone-image-preview>
      </div>
    </mat-card>
    <mat-card
      class="media"
      *ngIf="
        user &&
        user.subPlan &&
        user.subPlan.parameters &&
        (user.subPlan.parameters.rec_box_background == 1 ||
          user.subPlan.parameters.rec_box_background == 2)
      "
    >
      <div
        class="activate"
        *ngIf="user.subPlan.parameters.rec_box_background == 1"
      >
        <button mat-button class="success pink" (click)="seePlans()">
          Activer cette fonction
        </button>
      </div>
      <div class="headMedia">
        <div class="title">Image d'arrière plan</div>
        <div class="subtitle">
          Cette image est présente en arrière plan du retour caméra lors des
          enregistrements.
        </div>
      </div>
      <div class="rowSlide">
        <div
          class="custom-dropzone"
          ngx-dropzone
          [accept]="'image/*'"
          (change)="onSelectBackgroundRecImage($event)"
          *ngIf="
            (fileBackgroundRecImage?.length == 0 && !isLoading) ||
            !this.model.background_rec_image.length
          "
        >
          <mat-icon>cloud_upload</mat-icon>
        </div>
        <mat-spinner
          *ngIf="
            fileBackgroundRecImage?.length == 0 &&
            isLoading &&
            this.model.background_rec_image.length
          "
          >sync</mat-spinner
        >
        <ngx-dropzone-image-preview
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let f of fileBackgroundRecImage"
          [file]="f"
          [removable]="true"
          (removed)="onRemoveBackgroundRecImage()"
        >
        </ngx-dropzone-image-preview>
      </div>
    </mat-card>
    <mat-card
      class="group-media"
      *ngIf="
        user &&
        user.subPlan &&
        user.subPlan.parameters &&
        (user.subPlan.parameters.rec_box_btn_color == 1 ||
          user.subPlan.parameters.rec_box_btn_color == 2)
      "
    >
      <div
        class="activate"
        *ngIf="user.subPlan.parameters.rec_box_btn_color == 1"
      >
        <button
          mat-button
          class="success pink"
          (click)="seePlans('PAQ_ACTIVERCOLORBTN')"
        >
          Activer cette fonction
        </button>
      </div>
      <div class="flex">
        <div class="media">
          <div class="headMedia">
            <div class="title">
              Couleur de fond du bouton "suivant" lors des enregistrements
            </div>
          </div>
          <div class="dropZone">
            <div class="leftCol">
              <div class="color">
                <input
                  type="color"
                  id="background_next_btn"
                  name="background_next_btn"
                  [(ngModel)]="background_next_btn"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="media">
          <div class="headMedia">
            <div class="title">
              Couleur du texte du bouton "suivant" lors des enregistrements
            </div>
          </div>
          <div class="dropZone">
            <div class="leftCol">
              <div class="color">
                <input
                  type="color"
                  id="color_next_btn "
                  name="color_next_btn"
                  [(ngModel)]="color_next_btn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="title">
          Prévisualisation du bouton "suivant" selon vos paramètres
        </div>
        <div class="previ-btn">
          <div
            class="btn"
            [ngStyle]="{
              color: color_next_btn,
              background: background_next_btn
            }"
          >
            <svg
              [ngStyle]="{ fill: color_next_btn }"
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 96 960 960"
              width="48"
            >
              <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />
            </svg>
            suivant
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card
      class="media"
      *ngIf="
        user &&
        user.subPlan &&
        user.subPlan.parameters &&
        (user.subPlan.parameters.rec_box_transition_sound == 1 ||
          user.subPlan.parameters.rec_box_transition_sound == 2)
      "
    >
      <div
        class="activate"
        *ngIf="user.subPlan.parameters.rec_box_transition_sound == 1"
      >
        <button
          mat-button
          class="success pink"
          (click)="seePlans('PAQ_ACTIVERSONTRANSI')"
        >
          Activer cette fonction
        </button>
      </div>
      <div class="headMedia">
        <div class="title">Son de transition</div>
        <div class="subtitle">
          Ce son sera joué à chaque passage de question. Evitez les fichiers de
          plus de <b>1 seconde</b>.
        </div>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div
            class="custom-dropzone"
            ngx-dropzone
            [accept]="'audio/*'"
            (change)="onSelectTransition($event)"
            *ngIf="!transitionUrl"
          >
            <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol" *ngIf="transitionUrl">
          <audio controls>
            <source [src]="transitionUrl" type="audio/mpeg" />
            Audio non supporté par le navigateur
          </audio>
          <button
            mat-icon-button
            class="delete"
            matTooltip="Supprimer l'audio"
            (click)="onRemoveTransition()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="action">
    <button mat-raised-button class="success" (click)="next()">
      Configurer le montage <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
