import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-payment-abonnement',
  templateUrl: './payment-abonnement.component.html',
  styleUrls: ['./payment-abonnement.component.scss']
})
export class PaymentAbonnementComponent implements OnInit {

  @Input() paymentId: string = '';
  @Input() subPlanId: string = '';
  @Input() activeSubId: any = '';

  url: any;
  publicKey: string = 'pprod_cUQUzMg8UusXseQ2iKKVMAbL';
  // publicKey: string = 'ptest_pzmUxnXyPBtetYIM0v75VED4';

  constructor(private router: Router, private snackBar: MatSnackBar, private domSanitizer: DomSanitizer, private apiService: ApiService) { }

  ngOnInit(): void {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://payment.stancer.com/${this.publicKey}/${this.paymentId}`); 
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (event.origin !== 'https://payment.stancer.com') {
      return;
    }
    if (event.data.status) {
      if (event.data.status == "error" || event.data.status == "invalid") {
        this.snackBar.open("Une erreur est survenue lors du paiement", "OK", {
          panelClass: ['error-snackbar']
        });
      }
      if (event.data.status == "finished") {
        this.snackBar.open("Paiement validé. Merci de ne pas quitter la page avant le rechargement automatique.", "OK", {
          panelClass: ['success-snackbar']
        });

        this.apiService.getStancerPaymentActiveSubscription(`https://api.stancer.com/v1/checkout/${this.paymentId}`).pipe(first()).subscribe({
          next: (v) => {
            if (this.activeSubId) {
              this.apiService.changePaymentMethod(this.activeSubId, v.data.card.id, v.data.card.exp_month, v.data.card.exp_year).pipe(first()).subscribe({
                next: (v) => {
                  setTimeout(() => {
                    this.router.navigate(['/account/abonnement']).then(() => {
                      window.location.reload();
                    });
                  }, 1000);
                },
                error: (e) => {
                  this.snackBar.open("Une erreur est survenue lors de l'affectation de l'abonnement. Le paiement est bien valide. Merci de nous contacter.", "OK", {
                    panelClass: ['error-snackbar']
                  });
                  console.error(e)
                }
              });
            } else {
              this.apiService.addActiveSubscription(this.subPlanId, v.data.card.id, v.data.card.exp_month, v.data.card.exp_year).pipe(first()).subscribe({
                next: (v) => {
                  setTimeout(() => {
                    this.router.navigate(['/account/abonnement']).then(() => {
                      window.location.reload();
                    });
                  }, 1000);
                },
                error: (e) => {
                  this.snackBar.open("Une erreur est survenue lors de l'affectation de l'abonnement. Le paiement est bien valide. Merci de nous contacter.", "OK", {
                    panelClass: ['error-snackbar']
                  });
                  console.error(e)
                }
              });
            }
          },
          error: (e) => {
            console.error(e);
          }
        });
      }
    }
  }

}
