<div class="authentication">
  <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
  <div class="authentication-container">
    <div class="logo">
      <img src="assets/icon.png">
    </div>
    <form #loginForm="ngForm" (ngSubmit)='onLogin(loginForm)' *ngIf="formLogin">
      <mat-form-field appearance="standard">
        <mat-label>Adresse e-mail</mat-label>
        <input 
          matInput
          type="login"
          id="login"
          class="form-control input-text"
          ngModel
          email
          name="login"
          required
          autocomplete="email"
        >
      </mat-form-field>
      <br>
      <mat-form-field appearance="standard">
        <mat-label>Mot de passe</mat-label>
        <input 
          matInput
          type="password"
          id="password"
          class="form-control input-text"
          ngModel
          password
          name="password"
          required
          autocomplete="password"
        >
      </mat-form-field>
      <div class="form-validate">
        <button *ngIf="browser != 'Chrome'" mat-raised-button [disabled]="!loginForm.valid" type="submit" class="success">Se connecter</button>
        <button *ngIf="browser == 'Chrome'" mat-raised-button [disabled]="!loginForm.valid && loginForm.touched" type="submit" class="success">Se connecter</button>
      </div>
    </form>
    <form #lostPassForm="ngForm" (ngSubmit)='onGetToken(lostPassForm)' *ngIf="formLostPass">
      <mat-form-field appearance="standard">
        <mat-label>Adresse e-mail</mat-label>
        <input 
          matInput
          type="login"
          id="login"
          class="form-control input-text"
          ngModel
          email
          name="login"
          required
        >
      </mat-form-field>
      <div class="form-validate">
        <button mat-raised-button [disabled]="!lostPassForm.valid" type="submit" class="success">Valider</button>
      </div>
    </form>
    <div class="switch-form" (click)="toggleLostPass()" *ngIf="formLogin">
      Mot de passe oublié ?
    </div>
    <div class="switch-form" (click)="toggleLogin()" *ngIf="formLostPass">
      Se connecter
    </div>
  </div>
</div>
