import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-boxes-light',
  templateUrl: './boxes-light.component.html',
  styleUrls: ['./boxes-light.component.scss']
})
export class BoxesLightComponent implements OnInit {

  isLoading: boolean = true;
  boxes: any;
  boxesFiltered: any;

  user: any = {firstname: '', lastname: ''};

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private router: Router, private apiService: ApiService, public brandsService: BrandsService) {
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
    }

  ngOnInit(): void {
    this.initialization();
  }

  initialization(){
    this.isLoading = true;
    this.listBoxes();
    this.getCurrentUser();
  }

  listBoxes() {
    this.apiService.listBoxes().pipe(first()).subscribe({
      next: (v) => {
        let datas = v.data;

        datas.forEach((data: any) => {
          data.created_at = data.created_at.substring(0, 10)
          data['nbrFormResponses'] = data.form_responses.length;
        });

        this.boxes = datas;
        this.boxesFiltered = this.boxes;

        this.isLoading = false;
      },
      error: (e) => console.error(e)
    });
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().pipe(first()).subscribe({
      next: (v) => {
        // this.user = v.data[0]
  this.user = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  onEdit(box: any) {
    this.router.navigate(['/boxes/edit-box/setup/' + box.id]);
  }

  onAddBox() {
    const dialogRef = this.dialog.open(DialogCreateBoxLight, {
      data: {title: ''},
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.addBox(result).pipe(first()).subscribe({
          next: (v) => {
            let modeleTitle = result + '-' + v.data.id;
            this.apiService.addModel(modeleTitle).pipe(first()).subscribe({
              next: (vModele) => {
                this.apiService.editBox(v.data.id, {status: 10, model: vModele.data.id}).pipe(first()).subscribe({
                  next: (done) => {
                    this.router.navigate(['/boxes/edit-box/setup/' + v.data.id]);
                  },
                  error: (e) => {
                    this.snackBar.open("Une erreur est survenue", "OK", {
                      panelClass: ['error-snackbar']
                    });
                    console.error(e);
                  }
                });
              },
              error: (e) => {
                this.snackBar.open("Une erreur est survenue", "OK", {
                  panelClass: ['error-snackbar']
                });
                console.error(e);
              }
            });
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onDeleteBox(box: any) {
    const dialogRef = this.dialog.open(DialogConfirmDeleteBoxArchiveLight);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeBox(box.id).pipe(first()).subscribe({
          next: (v) => {
            this.listBoxes();
            this.snackBar.open("Scénario supprimé", "OK", {
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onCopyCode(box: any) {
    navigator.clipboard.writeText("<iframe src='https://questions.place/box/" + box.id + "?embed=true' frameborder='0' allow='camera *;microphone *'></iframe>");
    this.snackBar.open("Code d'intégration copié", "OK", {
      panelClass: ['success-snackbar']
    });
  }

  onViewBox(box: any) {
    let url = `https://www.questions.place/box/${box.id}`;
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: url,
    }).click();
  }

  filterBoxes(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase();
    if (filterValue) {
      this.boxesFiltered = this.boxes.filter((el: any) => el.title.toLowerCase().includes(filterValue));
    } else {
      this.boxesFiltered = this.boxes;
    }
  }

}


export interface DialogData {
  title: string;
}

@Component({
  selector: 'add-box',
  templateUrl: './add-box.html',
})
export class DialogCreateBoxLight implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCreateBoxLight>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }

  onNoClick() {
    this.data.title = '';
    this.dialogRef.close();
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteBoxArchiveLight {}