<mat-dialog-content class="dialogEdit">
  <div class="fields">
    <mat-form-field appearance="fill" *ngIf="form_element.type != 'email'">
      <mat-label>Titre</mat-label>
      <input matInput type="text" placeholder="Titre" [(ngModel)]="element_title">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Description</mat-label>
      <textarea matInput type="text" placeholder="Description" [(ngModel)]="element_description"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="form_element.type != 'email'">
      <mat-label>Type</mat-label>
      <mat-select [(ngModel)]="element_type" (selectionChange)="onTypeSelect($event.value)">
        <mat-option *ngFor="let type of types_array" [value]="type.value">
          {{type.text}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="form_element.type != 'email'">
      <mat-label>Largeur</mat-label>
      <input matInput type="text" placeholder="Largeur" [(ngModel)]="element_width">
    </mat-form-field>

    <mat-checkbox labelPosition="before" [(ngModel)]="element_required" *ngIf="form_element.type != 'email'">Obligatoire</mat-checkbox>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annuler</button>
  <button mat-button class="success" [mat-dialog-close]="true" cdkFocusInitial (click)="onEdit()">Modifier</button>
</mat-dialog-actions>