import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss'],
})
export class ContentsComponent implements OnInit {
  isLoading: boolean = false;

  currentTabName: string = '';
  currentTabIndex: number = 0;

  user: any;
  plans: any = [];

  constructor(
    private route: ActivatedRoute,
    public brandsService: BrandsService,
    private apiService: ApiService,
    private router: Router
  ) {
    this.brandsService.getBrand().subscribe((res: any) => {
      this.initialization();
    });
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.initialization();
    this.apiService.listSubscriptionPlans().subscribe({
      next: (v) => {
        this.plans = v.data;
        console.log(this.plans);
      },
      error: (e) => console.error(e),
    });
  }

  getCurrentUser() {
    this.apiService
      .getCurrentUser()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.user = v.data;
          this.apiService
            .listActiveSubscription()
            .pipe(first())
            .subscribe({
              next: (v) => {
                if (v.data.length == 0) {
                  this.user.subscription_type_active =
                    this.user.subscription_type;
                    this.user.subPlan = this.plans.find(
                      (plan: any) =>
                        plan.subscription_type == this.user.subscription_type_active
                    );
                    this.user.subPlan.parameters = JSON.parse(this.user.subPlan.parameters);
                  return;
                }

                const dateDisabled = new Date(
                  v.data[v.data.length - 1].disable_date
                );
                const lastPayment = new Date(
                  v.data[v.data.length - 1].next_payment
                );

                if (dateDisabled == null) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (lastPayment != null && new Date() < lastPayment) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (
                  lastPayment != null &&
                  new Date() > lastPayment &&
                  new Date() > dateDisabled
                ) {
                  this.user.subscription_type_active = 'Freemium';
                }
                this.user.subPlan = this.plans.find(
                  (plan: any) =>
                    plan.subscription_type == this.user.subscription_type_active
                );
                this.user.subPlan.parameters = JSON.parse(this.user.subPlan.parameters);
              },
              error: (e) => console.error(e),
            });
        },
        error: (e) => console.error(e),
      });
  }

  initialization() {
    this.currentTabName = 'boxes';
    this.currentTabIndex = 0;
    this.route.params.subscribe((params: any) => {
      if (params['tab']) {
        this.currentTabName = params['tab'];
        switch (this.currentTabName) {
          case 'boxes':
            this.currentTabIndex = 0;
            break;
          case 'questions':
            this.currentTabIndex = 1;
            break;
          case 'forms':
            this.currentTabIndex = 2;
            break;
          case 'categories':
            this.currentTabIndex = 3;
            break;
          case 'models':
            this.currentTabIndex = 4;
            break;
          default:
            this.currentTabName = 'boxes';
            this.currentTabIndex = 0;
            break;
        }
      }
    });
  }

  onTabChange(event: MatTabChangeEvent) {
    this.currentTabIndex = event.index;
  }

  viewAbonnements() {
    this.router.navigate(['/account', 'abonnement']);
  }

  viewCredits() {
    this.router.navigate(['/account', 'credits']);
  }
}
