<mat-progress-bar
  mode="indeterminate"
  color="primary"
  *ngIf="isLoading"
></mat-progress-bar>
<div class="page">
  <div class="head" *ngIf="brand">
    <h1>Tableau de bord {{ brand.title }}</h1>
    <div
      *ngIf="
        user &&
        (user.subscription_type_active == 'Freemium' ||
          user.subscription_type_active == 'Basic')
      "
      class="cta"
    >
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewAbonnements()">
        <strong>Passer en Premium</strong>
      </button>
    </div>
    <div *ngIf="user && user.subscription_type_active == 'Premium'" class="cta">
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewCredits()">
        Acheter des crédits vidéo
      </button>
    </div>
  </div>
  <div class="content" [class.hide]="isLoading">
    <div class="records" *ngIf="lastSents.length">
      <mat-card>
        <h2>5 derniers enregistrements</h2>
        <div class="sents">
          <div class="sent" *ngFor="let sent of lastSents">
            <div class="date">
              {{ sent.created_at }}
            </div>
            <div class="title">
              {{ sent.box.title }}
            </div>
            <div class="price">
              {{ sent.email }}
            </div>
            <div class="info">
              <button
                mat-icon-button
                class="info"
                matTooltip="Voir le détail"
                (click)="viewSent(sent)"
              >
                <mat-icon>info_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="stats">
      <mat-card *ngIf="boxes && boxes.length" (click)="viewBoxes()">
        <h2>Boîtes</h2>
        <div class="content">
          {{ boxes.length }}
        </div>
      </mat-card>
      <mat-card *ngIf="activeBoxes && activeBoxes.length" (click)="viewBoxes()">
        <h2>Boîtes actives</h2>
        <div class="content">
          {{ activeBoxes.length }}
        </div>
      </mat-card>
      <mat-card
        *ngIf="privateBoxes && privateBoxes.length"
        (click)="viewBoxes()"
      >
        <h2>Boîtes privées</h2>
        <div class="content">
          {{ privateBoxes.length }}
        </div>
      </mat-card>
      <mat-card *ngIf="sents && sents.length" (click)="viewSents()">
        <h2>Vidéos</h2>
        <div class="content">
          {{ sents.length }}
        </div>
      </mat-card>
      <mat-card *ngIf="models && models.length" (click)="viewModeles()">
        <h2>Modèles</h2>
        <div class="content">
          {{ models.length }}
        </div>
      </mat-card>
      <mat-card
        *ngIf="categories && categories.length"
        (click)="viewCategories()"
      >
        <h2>Catégories</h2>
        <div class="content">
          {{ categories.length }}
        </div>
      </mat-card>
      <mat-card *ngIf="questions && questions.length" (click)="viewQuestions()">
        <h2>Questions</h2>
        <div class="content">
          {{ questions.length }}
        </div>
      </mat-card>
      <mat-card *ngIf="formsResponses" (click)="viewForms()">
        <h2>Remontées de formulaire</h2>
        <div class="content">
          {{ formsResponses }}
        </div>
      </mat-card>
      <!-- <mat-card (click)="viewCredits()">
        <h2>Crédits</h2>
        <div class="content">
          {{ credits || 0 }}
        </div>
      </mat-card> -->
    </div>
  </div>
</div>
