import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent implements OnInit {
  @Input() user: any;

  products: any;
  purchases: any;

  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (!this.user) {
      return;
    }
    this.listProducts();
    this.listPurchases();
  }

  navigateHistorique() {
    this.router.navigate(['/account/historique']);
  }

  navigateAbonnement() {
    this.router.navigate(['/account/abonnement']);
  }

  listProducts() {
    this.apiService
      .listProducts()
      .pipe(first())
      .subscribe({
        next: (v) => {
          let datas = v.data;
          this.products = datas.filter(
            (p: any) => p.subscription_type == this.user.subscription_type
          );
        },
        error: (e) => console.error(e),
      });
  }

  buyProduct(product: any) {
    if (this.user.baskets.length) {
      this.apiService
        .addProductToBasket(
          product.id,
          this.user.baskets[this.user.baskets.length - 1].id
        )
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.router.navigate(['/cart']);
          },
          error: (e) => console.error(e),
        });
    } else {
      this.apiService
        .addBasket()
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.apiService
              .addProductToBasket(product.id, v.data.id)
              .pipe(first())
              .subscribe({
                next: (v) => {
                  this.router.navigate(['/cart']);
                },
                error: (e) => console.error(e),
              });
          },
          error: (e) => console.error(e),
        });
    }
  }

  listPurchases() {
    this.apiService
      .listPurchases()
      .pipe(first())
      .subscribe({
        next: (v) => {
          v.data.forEach((element: any) => {
            element.created_at = new Date(
              element.created_at.substring(0, 10)
            ).toLocaleDateString('fr');
          });
          this.purchases = v.data;
        },
        error: (e) => console.error(e),
      });
  }
}
