import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-brand',
  templateUrl: './edit-brand.component.html',
  styleUrls: ['./edit-brand.component.scss']
})

export class EditBrandComponent implements OnInit {

  tinyMCE: string = environment.tinyMCE;

  brand: any;
  title: any;
  description: any;
  bigDescription: any;
  bigDescriptionRefFocus: any;
  fileMedia: any = [];

  tinyMceInit: any;

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.getBrand(params['id']);
    });

    this.tinyMceInit = {
      plugins: 'preview importcss tinydrive searchreplace autolink save directionality visualblocks visualchars fullscreen link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
      importcss_append: true,
      height: 300,
      contextmenu: false,
      setup: (editor: any) => {
        editor.on('focus', () => {
          this.bigDescriptionFocus();
        });
        editor.on('blur', () => {
          this.bigDescriptionFocusOut();
        });
      }
    };
  }

  bigDescriptionFocus() {
    this.bigDescriptionRefFocus = this.bigDescription;
  }

  bigDescriptionFocusOut() {
    if (!this.bigDescription) {
      this.bigDescription = this.bigDescriptionRefFocus;
      return;
    }
    if (this.bigDescription !== this.bigDescriptionRefFocus) {
    }
  }

  getBrand(id: any) {
    this.apiService.getBrand(id).pipe(first()).subscribe({
      next: (v) => {
        this.brand = v.data[0];
        this.title = this.brand.title;
        this.description = this.brand.description;
        this.bigDescription = this.brand.big_description;

        if (this.brand.logoBrand){
          for (const value of Object.entries(this.brand.logoBrand)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileMedia);
          }
        }

      },
      error: (e) => console.error(e)
    });    
  }

  urlToFile(element: any, typeMedia: any) {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.original_url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.name.split('.');
      type = type[type.length - 1];
      let file = new File([blob], element.name, {type: "image/" + type, lastModified:new Date().getTime()});
      typeMedia.push(file);      
    }
    xhr.send();
  }

  onSelectMedia(event: any) {
    let files = event.addedFiles;
    this.fileMedia[0] = files[0];
  }

  onRemoveMedia(){
    this.fileMedia = [];
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteBrand);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeBrand(this.brand.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Marque supprimée", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/brands']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onSave() {
    let modifsObject: any = {};

    if (this.title !== this.brand.title) modifsObject.title = this.title;
    if (this.description !== this.brand.description) modifsObject.description = this.description;
    if (this.bigDescription !== this.brand.big_description) modifsObject.big_description = this.bigDescription;

    if (this.fileMedia.length == 0){
      modifsObject.logoBrand = "delete";
    } else if(this.fileMedia[0] !== this.brand.logoBrand){
      modifsObject.logoBrand = this.fileMedia[0];
    }

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editBrand(this.brand.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
    }
    this.router.navigate(['/brands']);
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteBrand {}
