<div class="page">
  <div class="head">
    <h1>Ordonner les marques</h1>
  </div>

  <div class="content">
    <div class="colLeft">
      <div class="fields">
        <div cdkDropList class="order-list" (cdkDropListDropped)="drop($event)">
          <div class="fieldsList" *ngFor="let brand of brands; let i = index" cdkDrag>        
            <div class="field">
              <span>{{i+1}}.&nbsp;</span>
              {{brand.title}}
            </div>
          </div>
        </div>   
      </div>
    </div>
  </div>

</div>