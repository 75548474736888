import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { AdminGuard } from './auth/admin.guard';
import { UserValidationComponent } from './auth/user-validation/user-validation.component';

import { HomeComponent } from './pages/home/home.component';
import { CategoriesComponent } from './pages/contents/categories/categories.component';
import { EditCategoryComponent } from './pages/contents/categories/edit-category/edit-category.component';
import { FormsComponent } from './pages/contents/forms/forms.component';
import { EditFormComponent } from './pages/contents/forms/edit-form/edit-form.component';
import { ConfigsComponent } from './pages/configs/configs.component';
import { EditConfigComponent } from './pages/configs/edit-config/edit-config.component';
import { SentComponent } from './pages/sent/sent.component';
import { UsersComponent } from './pages/users/users.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { BoxesLightComponent } from './pages/boxes-light/boxes-light.component';
import { EditBoxComponent } from './pages/contents/boxes/edit-box/edit-box.component';
import { QuestionsComponent } from './pages/contents/questions/questions.component';
import { EditQuestionComponent } from './pages/contents/questions/edit-question/edit-question.component';
import { ModelsComponent } from './pages/contents/models/models.component';
import { EditModelComponent } from './pages/contents/models/edit-model/edit-model.component';
import { SingleSentComponent } from './pages/sent/single-sent/single-sent.component';
import { SingleFormComponent } from './pages/contents/forms/single-form/single-form.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { EditBrandComponent } from './pages/brands/edit-brand/edit-brand.component';
import { OrderBrandsComponent } from './pages/brands/order-brands/order-brands.component';
import { BoardComponent } from './pages/board/board.component';
import { ContentsComponent } from './pages/contents/contents.component';
import { ProductsComponent } from './pages/products/products.component';
import { AccountComponent } from './pages/account/account.component';
import { UserBoardComponent } from './pages/user-board/user-board.component';
import { EditProductComponent } from './pages/products/edit-product/edit-product.component';
import { CartComponent } from './pages/cart/cart.component';
import { SetupComponent } from './pages/boxes-light/setup/setup.component';
import { ValidationComponent } from './pages/boxes-light/validation/validation.component';
import { ScenarioComponent } from './pages/boxes-light/scenario/scenario.component';
import { EditQuestionLightComponent } from './pages/boxes-light/scenario/edit-question-light/edit-question-light.component';
import { ConfigRecordComponent } from './pages/boxes-light/config-record/config-record.component';
import { ConfigMontageComponent } from './pages/boxes-light/config-montage/config-montage.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';



const routes: Routes = [
  { path: '', redirectTo: 'user-board', pathMatch: 'full' },
  { path: 'contents', component: ContentsComponent, canActivate: [AuthGuard]},
  { path: 'contents/:tab', component: ContentsComponent, canActivate: [AuthGuard]},
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard]},
  { path: 'products/edit/:id', component: EditProductComponent, canActivate: [AuthGuard]},
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
  { path: 'account/:tab', component: AccountComponent, canActivate: [AuthGuard]},
  { path: 'board', component: BoardComponent, canActivate: [AuthGuard]},
  { path: 'cart', component: CartComponent, canActivate: [AuthGuard]},
  { path: 'user-board', component: UserBoardComponent, canActivate: [AuthGuard]},
  { path: 'sent', component: SentComponent, canActivate: [AuthGuard]},
  { path: 'sent/:id', component: SingleSentComponent, canActivate: [AuthGuard]},
  { path: 'boxes', component: BoxesLightComponent, canActivate: [AuthGuard]},
  { path: 'boxes/edit-box/setup/:id', component: SetupComponent, canActivate: [AuthGuard]},
  { path: 'boxes/edit-box/scenario/:id', component: ScenarioComponent, canActivate: [AuthGuard]},
  { path: 'boxes/edit-box/validation/:id', component: ValidationComponent, canActivate: [AuthGuard]},
  { path: 'boxes/edit-box/:boxId/question/:id', component: EditQuestionLightComponent, canActivate: [AuthGuard]},
  { path: 'boxes/edit-box/config-record/:id', component: ConfigRecordComponent, canActivate: [AuthGuard]},
  { path: 'boxes/edit-box/config-montage/:id', component: ConfigMontageComponent, canActivate: [AuthGuard]},
  { path: 'boxes/edit/:id', component: EditBoxComponent, canActivate: [AuthGuard]},
  { path: 'questions/edit/:id', component: EditQuestionComponent, canActivate: [AuthGuard]},
  { path: 'categories/edit/:id', component: EditCategoryComponent, canActivate: [AuthGuard]},
  { path: 'forms/edit/:id', component: EditFormComponent, canActivate: [AuthGuard]},
  { path: 'forms/:id', component: SingleFormComponent, canActivate: [AuthGuard]},
  { path: 'forms', component: FormsComponent, canActivate: [AuthGuard]},
  { path: 'configs', component: ConfigsComponent, canActivate: [AuthGuard]},
  { path: 'configs/edit/:id', component: EditConfigComponent, canActivate: [AuthGuard]},
  { path: 'utilisateurs', component: UsersComponent, canActivate: [AuthGuard]},
  { path: 'utilisateurs/edit/:id', component: EditUserComponent, canActivate: [AuthGuard]},
  { path: 'models/edit/:id', component: EditModelComponent, canActivate: [AuthGuard]},
  { path: 'brands', component: BrandsComponent, canActivate: [AdminGuard]},
  { path: 'brands/edit/:id', component: EditBrandComponent, canActivate: [AdminGuard]},
  { path: 'brands/order', component: OrderBrandsComponent, canActivate: [AdminGuard]},
  { path: 'login', component: AuthComponent },
  { path: 'user-validation/:token/:id', component: UserValidationComponent },
  { path: 'reset-password/:token/:id', component: ResetPasswordComponent },
  { path: '**', redirectTo: 'boxes'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
