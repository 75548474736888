<mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
<div class="page">
  <div class="head">
    <h1>Mes scénarios</h1>
  </div>
  <div class="header">
    <mat-form-field appearance="fill">
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="filterBoxes($event)" placeholder="Effectuer une recherche" autocomplete="off">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div class="action">
      <button mat-raised-button class="pink" (click)="onAddBox()">
        <mat-icon>add</mat-icon>
        Nouveau scénario
      </button>
    </div>
  </div>
  <div class="content" [class.hide]="isLoading">

    <div class="boxes">
      <mat-card class="box" *ngFor="let box of boxesFiltered">
        <div class="cover-container" [class.has-cover]="box.cover.length && box.cover[0].original_url" (click)="onEdit(box)">
          <div class="cover" *ngIf="box.cover.length && box.cover[0].original_url">
            <img [src]="box.cover[0].original_url" alt="" srcset="">
          </div>
          <div class="box-head" *ngIf="box.interview">
            <div class="type">
              Boîte interview
            </div>
            <div class="icone">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85.536" height="85.536" viewBox="0 0 85.536 85.536">
                <defs>
                  <linearGradient id="linear-gradient-home" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#0338ee"/>
                    <stop offset="0.126" stop-color="#2338ee"/>
                    <stop offset="0.441" stop-color="#6e38ef"/>
                    <stop offset="0.702" stop-color="#a538ef"/>
                    <stop offset="0.895" stop-color="#c838ef"/>
                    <stop offset="1" stop-color="#d538f0"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-2" x1="-0.006" y1="0.5" x2="1.006" y2="0.5" xlink:href="#linear-gradient-home"/>
                </defs>
                <g id="Groupe_3443" data-name="Groupe 3443" transform="translate(-3725.204 -1070.641)">
                  <g id="Groupe_3442" data-name="Groupe 3442">
                    <rect id="Rectangle_509" data-name="Rectangle 509" width="37.697" height="26.017" transform="translate(3748.595 1104.245)" fill="url(#linear-gradient-home)"/>
                    <rect id="Rectangle_510" data-name="Rectangle 510" width="37.697" height="6.388" transform="translate(3747.103 1098.034) rotate(-13.513)" fill="url(#linear-gradient-home)"/>
                    <path id="Tracé_1781" data-name="Tracé 1781" d="M3764.668,1121.368v-9.081l7.8,4.54Z" fill="#fff"/>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="box-head" *ngIf="!box.interview">
            <div class="type">
              Boîte flash
            </div>
            <div class="icone">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85.536" height="85.536" viewBox="0 0 85.536 85.536">
                <defs>
                  <linearGradient id="linear-gradient-home" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#0338ee"/>
                    <stop offset="0.126" stop-color="#2338ee"/>
                    <stop offset="0.441" stop-color="#6e38ef"/>
                    <stop offset="0.702" stop-color="#a538ef"/>
                    <stop offset="0.895" stop-color="#c838ef"/>
                    <stop offset="1" stop-color="#d538f0"/>
                  </linearGradient>
                </defs>
                <g id="Groupe_3439" data-name="Groupe 3439" transform="translate(-3309.472 -1070.641)">
                  <g id="Groupe_3438" data-name="Groupe 3438">
                    <path id="Tracé_1779" data-name="Tracé 1779" d="M3366.692,1108.99h-10.6l11.136-26.281h-17.638L3338.9,1116.5h8.868l-8.138,30.919Z" fill="url(#linear-gradient-home)"/>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="content">
            <div class="title">
              {{ box.title }}
            </div>
          </div>
          <div class="bottom">
            <div class="left">
              <span *ngIf="box.category">{{ box.category.title }}</span>
            </div>
            <div class="right">
              C'est parti !
            </div>
          </div>
        </div>
        <div class="contents">
          <div class="flex-infos">
            <div class="infos">
              <div class="info">
                <div class="title">
                  Nombre d'utilisations :
                </div>
                <div class="data">
                  {{ box.sents_count }}
                </div>
              </div>
              <div class="info">
                <div class="title">
                  Statut :
                </div>
                <div class="data" *ngIf="box.status == 1">
                  Brouillon
                </div>
                <div class="data" *ngIf="box.status == 2">
                  Désactivée
                </div>
                <div class="data" *ngIf="box.status == 4">
                  Publiée
                </div>
                <div class="data" *ngIf="box.status == 5">
                  Salon
                </div>
                <div class="data" *ngIf="box.status == 10">
                  Privée
                </div>
              </div>
              <div class="info">
                <div class="title">
                  Contacts enregistrés :
                </div>
                <div class="data">
                  {{ box.nbrFormResponses }}
                </div>
              </div>
            </div>
            <div class="icons">
              <div class="icon">
                <button mat-icon-button aria-label="Supprimer" class="error" matTooltip="Supprimer le scénario" (click)="onDeleteBox(box)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="actions">
            <div class="action" (click)="onEdit(box)">
              Éditer
            </div>
            <div class="action" (click)="onViewBox(box)">
              Voir en ligne
            </div>
            <div class="action" (click)="onCopyCode(box)">
              Intégrer
            </div>
          </div>
        </div>
      </mat-card>
      <mat-card *ngIf="boxes && !boxes.length && !isLoading" class="no-boxes">
        <div class="title">
          Hello {{ user.firstname }} {{ user.lastname }} !
        </div>
        <div class="text">
          Bienvenue dans ton espace de création de scénarios de questions.
          <br>
          Pour démarrer, il te suffit de cliquer sur "+ Nouveau scénario" et de te laisser guider...
        </div>
      </mat-card>
    </div>


  </div>
</div>