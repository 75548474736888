import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { filter, first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandsService } from 'src/app/services/brands.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  isLoading: boolean = true;
  questions: any;
  questionsToShow: any;

  displayedColumns: string[] = ['title', 'categories', 'boxes', 'image', 'edit'];

  categories: any;
  categoriesOptionsFiltered: any[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private router: Router, private apiService: ApiService, public brandsService: BrandsService) { 
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
  }

  ngOnInit(): void {
    this.initialization();    
  }

  initialization(){
    this.isLoading = true;
    this.listQuestions();
    this.listCategories();
  }

  listQuestions() {
    this.apiService.listQuestions().pipe(first()).subscribe({
      next: (v) => {
        let datas = v.data;

        datas.forEach((data: any) => {
          data['nbrBoxes'] = data.boxes.length;
        });

        this.questions = datas;
        this.questionsToShow = new MatTableDataSource(this.questions);
        this.questions.paginator = this.paginator;
        
        this.paginator._intl.itemsPerPageLabel = "Boites par page";
        this.paginator._intl.nextPageLabel = 'Page suivante';
        this.paginator._intl.previousPageLabel = 'Page précédente'; 
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 à ${length }`;
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1} - ${endIndex} sur ${length}`;
        };
        this.isLoading = false;
      },
      error: (e) => console.error(e)
    });
  }

  filterTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.questions.filter = filterValue.trim().toLowerCase();
  }

  listCategories() {
    this.categoriesOptionsFiltered = [];
    this.apiService.listCategories().pipe(first()).subscribe({
      next: (v) => {
        this.categories = v.data;

        this.categories.forEach((category: any) => {
          this.categoriesOptionsFiltered.push(category);
        });

      },
      error: (e) => console.error(e)
    });
  }

  filterCategories(category: any) {
    if(typeof category != "string"){
      this.questionsToShow = [];
      this.questions.forEach((question: any) => {
        question.categories.forEach((cat: any) => {
          if(cat.id === category){
            this.questionsToShow.push(question);
          }
        });
      });
    } else if(typeof category === "string"){
      this.questionsToShow = this.questions;
    }

  }

  onEdit(question: any) {
    this.router.navigate(['/questions/edit/' + question.id]);
  }

  onAddQuestion() {
    const dialogRef = this.dialog.open(DialogCreateQuestion, {
      data: {title: ''},
    });
    dialogRef.afterClosed().subscribe((result: any) => {  
      if (result) {
        this.apiService.addQuestion(result).pipe(first()).subscribe({
          next: (v) => {
            this.router.navigate(['/questions/edit/' + v.data.id]);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }
}

export interface DialogData {
  title: string;
}

@Component({
  selector: 'add-question',
  templateUrl: './add-question.html',
})
export class DialogCreateQuestion implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCreateQuestion>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }

  onNoClick() {
    this.data.title = '';
    this.dialogRef.close();
  }

}