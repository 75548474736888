<mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
<div class="page">

  <div class="header">
    <mat-form-field appearance="fill">
      <mat-label>Rechercher une catégorie</mat-label>
      <input matInput (keyup)="filterTable($event)" placeholder="Effectuer une recherche par nom de catégorie" autocomplete="off">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div class="action">
      <button mat-raised-button class="success" (click)="onAddCategory()">Créer une catégorie</button>
    </div>
  </div>

  <div class="content" [class.hide]="isLoading">
    <div class="table-container mat-elevation-z2">
      <table mat-table [dataSource]="categories">

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Nom de la catégorie</th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> Nombre de boite </th>
          <td mat-cell *matCellDef="let element"> {{element.boxes.length}} </td>
        </ng-container>

        <ng-container matColumnDef="edit" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="edit" matTooltip="Éditer la catégorie" (click)="onEdit(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Aucun résultat</td>
        </tr>
    
      </table>
    
      <mat-paginator [pageSizeOptions]="[5, 10, 20, 100]" [pageSize]="20"></mat-paginator>
    </div>

  </div>
</div>