<mat-card>
  <h2>Vos crédits</h2>
  <div class="content" *ngIf="user">
    Vous avez <b>{{ user.credits || 0 }} crédits</b> restants. Les crédits sont utilisés dès 30 secondes de vidéo.
    <br><br>
    <a (click)="navigateHistorique()">Voir l'historique d'utilisation des crédits</a>
  </div>
</mat-card>

<mat-card>
  <h2>Créditer mon compte</h2>
  <div class="text">
    L'abonnement <strong>Basic</strong> inclut 5 vidéos par mois et l'abonnement <strong>Premium</strong>, 10 vidéos (voir <a (click)="navigateAbonnement()">les tarifs</a>).
    <br>
    Besoin de vidéos supplémentaires ? Ajoutez des crédits vidéos à l'unité ou par lots à votre abonnement :
    <br><br>
  </div>
  <div class="content">
    <div class="products" *ngIf="products">
      <div class="product" *ngFor="let product of products">
        <div class="media">
          <img [src]="product.imgProduct[0].original_url" alt="" srcset="" *ngIf="product.imgProduct.length">
        </div>
        <div class="title">
          {{ product.title }}
        </div>
        <div class="description">
          {{ product.description }}
        </div>
        <div class="price">
          {{ product.price_ht / 100 }}€ HT
        </div>
        <div class="price-ttc">
          soit {{ product.price_ttc / 100 }}€ TTC
        </div>
        <div class="action">
          <button mat-raised-button class="success" (click)="buyProduct(product)">
            Acheter
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-card>