<mat-progress-bar
  mode="indeterminate"
  color="primary"
  *ngIf="isLoading"
></mat-progress-bar>
<div class="page" *ngIf="!paymentPage">
  <div class="head">
    <h1>Mon panier</h1>
    <div
      *ngIf="
        user &&
        (user.subscription_type_active == 'Freemium' ||
          user.subscription_type_active == 'Basic')
      "
      class="cta"
    >
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewAbonnements()">
        <strong>Passer en Premium</strong>
      </button>
    </div>
    <div *ngIf="user && user.subscription_type_active == 'Premium'" class="cta">
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewCredits()">
        Acheter des crédits vidéo
      </button>
    </div>
  </div>
  <div class="content" *ngIf="user">
    <div class="empty" *ngIf="!user.baskets.length">
      Mon panier est vide.
      <br /><br />
      <a routerLink="/account/credits">Créditer mon compte</a>
    </div>

    <div class="basket" *ngIf="user.baskets.length && products">
      <div
        class="products"
        *ngIf="user.baskets[user.baskets.length - 1].basket_products.length"
      >
        <div class="product" *ngFor="let product of products">
          <div class="media">
            <img
              [src]="product.imgProduct[0].original_url"
              alt=""
              srcset=""
              *ngIf="product.imgProduct.length"
            />
          </div>
          <div class="title">
            {{ product.title }}
          </div>
          <div class="description">
            {{ product.description }}
          </div>
          <div class="price">{{ product.price_ht / 100 }}€ HT</div>
          <div class="price-ttc">soit {{ product.price_ttc / 100 }}€ TTC</div>
          <div class="action">
            <button
              mat-raised-button
              class="error"
              (click)="removeProduct(product)"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
      <div
        class="empty"
        *ngIf="!user.baskets[user.baskets.length - 1].basket_products.length"
      >
        Mon panier est vide.
        <br /><br />
        <a routerLink="/account/credits">Créditer mon compte</a>
      </div>
      <div
        class="validate"
        *ngIf="user.baskets[user.baskets.length - 1].basket_products.length"
      >
        <button mat-raised-button class="success" (click)="validateCart()">
          Valider mon panier
        </button>
      </div>
    </div>
  </div>
</div>

<app-payment
  *ngIf="paymentPage"
  [paymentId]="paymentId"
  [price_ht]="price_ht"
  [price_ttc]="price_ttc"
  [taxe]="taxe"
  [credits]="credits"
  [basket_id]="basket_id"
></app-payment>
