<div class="authentication">
  <mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
  <div class="authentication-container">
    <div class="logo">
      <img src="assets/icon.png">
    </div>
    <form #resetForm="ngForm" (ngSubmit)='onReset(resetForm)'>
      <mat-form-field appearance="standard">
        <mat-label>Nouveau mot de passe</mat-label>
        <input 
          matInput
          type="password"
          id="password"
          class="form-control input-text"
          ngModel
          password
          name="password"
          required
        >
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Confirmer le mot de passe</mat-label>
        <input 
          matInput
          type="password"
          id="confirmation"
          class="form-control input-text"
          ngModel
          password
          name="confirmation"
          required
        >
      </mat-form-field>
      <div class="form-validate">
        <button mat-raised-button [disabled]="!resetForm.valid" type="submit" class="success">Modifier mon mot de passe</button>
      </div>
    </form>
  </div>
</div>
