import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { User } from './user.model';
import { ApiService } from '../services/api.service';

interface AuthResponseData {
  token: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user = new BehaviorSubject<any>(null);
  jwt_token = new BehaviorSubject<any>(null);

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  constructor(private http: HttpClient, private router: Router) { }

  login(
    email: string,
    password: string
  ) {
    const form: FormData = new FormData();
    form.set('email', email);
    form.set('password', password);

    const httpFormOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
      })
    };

    return this.http.post<AuthResponseData>(
      `${environment.baseApiUrl}/users/connect`,
      form,
      httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        if (errorRes.error.message == 'Inactive account') {
          throw new Error('Compte inactif. Vérifiez vos e-mails pour activer le compte.');
        } else {
          throw new Error('Utilisateur inconnu ou mot de passe erroné');
        }
      }),
      tap(resData => {
        // const user = new User(resData.data.user_id, resData.data.token, resData.data.is_admin);
        this.user.next(resData);
        // localStorage.setItem('userData', JSON.stringify(user));
        localStorage.removeItem('userData');
        this.jwt_token.next(resData.token);
        localStorage.setItem('jwt_token', resData.token);
      })
    );
  }

  autoLogin() {
    const jsonData: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(jsonData);
    const token: any = localStorage.getItem('jwt_token');
    if (!token) return;

    const loadedUser = new User(userData.id, userData.token, userData.is_admin);
    this.user.next(loadedUser);
    this.jwt_token.next(token);
  }

  async logout() {
    this.user.next(null);
    this.jwt_token.next(null);
    localStorage.removeItem('userData');
    localStorage.removeItem('jwt_token');
    window.location.reload();
    // this.router.navigate(['/login']);
  }

  resetPassword(email: string) {
    const form: FormData = new FormData();
    form.set('email', email);

    return this.http.post<AuthResponseData>(
      `${environment.baseApiUrl}/users/reset_password`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        throw new Error('Adresse e-mail inconnue');
      }),
      tap(resData => {

      })
    );
  }

  newPassword(user_id: string, token: string, password: string) {
    const form: FormData = new FormData();
    form.set('user_id', user_id);
    form.set('token', token);
    form.set('password', password);

    return this.http.post<AuthResponseData>(
      `${environment.baseApiUrl}/users/new_password`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        throw new Error('Adresse e-mail inconnue');
      }),
      tap(resData => {

      })
    );
  }

}
