import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  admin: boolean = false;
  user: any;

  username: string | undefined;
  firstname: string | undefined;
  lastname: string | undefined;
  email: string | undefined;
  password: string | undefined;
  is_admin: any;
  is_active: any;

  allBrands: any;
  brands = new FormControl();
  brandsList: any;

  iAmAdmin: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private route: ActivatedRoute,
    public brandsService: BrandsService
  ) {
    this.brandsService.getBrand().subscribe((res: any) => {
      this.initialization();
    });
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.route.params.pipe(first()).subscribe((params) => {
      this.getUser(params['id']);
    });
    const jsonData: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(jsonData);
    this.admin = userData.is_admin;
    this.iAmAdmin = userData.is_admin;
    this.listBrands();
  }

  listBrands() {
    this.apiService
      .listBrands()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.allBrands = v.data;
        },
        error: (e) => console.error(e),
      });
  }

  onBrandsSelect(event: any) {
    if (event.relatedTarget) {
      return;
    }

    let newBrandIds = [...this.brands.value];
    let removeBrands: any;
    let addBrands: any;

    let currentBrandIds = this.user.brands.map((brand: any) => brand.id);
    removeBrands = currentBrandIds.filter(
      (brand: any) => !newBrandIds.includes(brand)
    );
    addBrands = newBrandIds.filter(
      (brand: any) => !currentBrandIds.includes(brand)
    );

    if (addBrands.length > 0 && removeBrands.length > 0) {
      this.apiService
        .linkUserToBrand(this.user.id, newBrandIds)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.apiService
              .unlinkUserToBrand(this.user.id, removeBrands)
              .pipe(first())
              .subscribe({
                next: (v) => {
                  this.snackBar.open('Marques enregistrées', 'OK', {
                    panelClass: ['success-snackbar'],
                  });
                  this.getUser(this.user.id);
                },
                error: (e) => {
                  console.error(e);
                },
              });
            this.snackBar.open('Marques enregistrées', 'OK', {
              panelClass: ['success-snackbar'],
            });
          },
          error: (e) => {
            console.error(e);
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
          },
        });
    }

    if (addBrands.length > 0 && removeBrands.length == 0) {
      this.apiService
        .linkUserToBrand(this.user.id, newBrandIds)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.snackBar.open('Marques enregistrées', 'OK', {
              panelClass: ['success-snackbar'],
            });
            this.getUser(this.user.id);
          },
          error: (e) => {
            console.error(e);
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
          },
        });
    }

    if (addBrands.length == 0 && removeBrands.length > 0) {
      this.apiService
        .unlinkUserToBrand(this.user.id, removeBrands)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.snackBar.open('Marques enregistrées', 'OK', {
              panelClass: ['success-snackbar'],
            });
            this.getUser(this.user.id);
          },
          error: (e) => {
            console.error(e);
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
          },
        });
    }
    return;
  }

  getUser(id: any) {
    this.apiService
      .getUser(id)
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.user = v.data[0]
          console.log(this.user)
          // this.user = v.data;
          this.username = this.user.username;
          this.firstname = this.user.firstname;
          this.lastname = this.user.lastname;
          this.email = this.user.email;
          this.is_admin = this.user.is_admin;
          this.is_active = this.user.active;

          if (this.user.brands.length) {
            this.brandsList = this.user.categories;
            let brandIds = this.user.brands.map((brand: any) => brand.id);
            this.brands.setValue(brandIds);
          } else {
            this.brandsList = [];
            this.brands.setValue([]);
          }
        },
        error: (e) => console.error(e),
      });
  }

  onSave() {
    let modifsObject: any = {};

    if (this.username !== this.user.username)
      modifsObject.username = this.username;
    if (this.firstname !== this.user.firstname)
      modifsObject.firstname = this.firstname;
    if (this.lastname !== this.user.lastname)
      modifsObject.lastname = this.lastname;
    if (this.email !== this.user.email) modifsObject.email = this.email;
    if (this.password !== undefined) modifsObject.password = this.password;
    if (this.is_admin !== this.user.is_admin)
      modifsObject.is_admin = this.is_admin;
    if (this.is_active !== this.user.active)
      modifsObject.active = this.is_active;

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      console.log(modifsObject);
      this.apiService
        .editUser(this.user.id, modifsObject)
        .pipe(first())
        .subscribe({
          next: (v) => {
            if (modifsObject.is_admin == true) {
              this.apiService
                .listBrands()
                .pipe(first())
                .subscribe({
                  next: (v) => {
                    v.data.forEach((brand: any) => {
                      this.apiService
                        .linkUserToBrand(this.user.id, brand.id)
                        .pipe(first())
                        .subscribe({
                          next: (v) => { },
                          error: (e) => {
                            this.snackBar.open(
                              'Une erreur est survenue',
                              'OK',
                              {
                                panelClass: ['error-snackbar'],
                              }
                            );
                            console.error(e);
                          },
                        });
                    });
                  },
                  error: (e) => {
                    this.snackBar.open('Une erreur est survenue', 'OK', {
                      panelClass: ['error-snackbar'],
                    });
                    console.error(e);
                  },
                });
            }
            this.snackBar.open('Modifications enregistrées', 'OK', {
              panelClass: ['success-snackbar'],
            });
          },
          error: (e) => {
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
            console.error(e);
          },
        });
    } else {
      this.snackBar.open('Modifications enregistrées', 'OK', {
        panelClass: ['success-snackbar'],
      });
    }
  }

  giveCredits() {
    const dialogRef = this.dialog.open(DialogGiveCredits, {
      data: { credits: 0 },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.credits && result.credits > 0) {
        this.apiService
          .giveCredits(this.user.id, result.credits)
          .pipe(first())
          .subscribe({
            next: (v) => {
              this.snackBar.open(result.credits + ' crédits offerts', 'OK', {
                panelClass: ['success-snackbar'],
              });
            },
            error: (e) => {
              this.snackBar.open('Une erreur est survenue', 'OK', {
                panelClass: ['error-snackbar'],
              });
              console.error(e);
            },
          });
      } else {
        this.snackBar.open('Il faut renseigner tous les champs', 'OK', {
          panelClass: ['warning-snackbar'],
        });
      }
    });
  }
}

export interface DialogData {
  credits: number;
}

@Component({
  selector: 'give-credits',
  templateUrl: './give-credits.html',
})
export class DialogGiveCredits implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogGiveCredits>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.dialogRef.close(this.data);
  }

  onNoClick() {
    this.data.credits = 0;
    this.dialogRef.close();
  }
}
