import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { BrandsService } from '../services/brands.service';
import { first } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menus: any = [
    {url: '/user-board', title: 'Tableau de bord'},
    {url: '/contents', title: 'Mes boîtes à questions'},
    // {url: '/boxes', title: 'Mes scénarios'},
    {url: '/sent', title: 'Mes vidéos'},
  ];

  accounts: any = [
    {url: '/account/infos', title: 'Gérer mon compte'},
    {url: '/account/credits', title: 'Créditer mon compte'},
    {url: '/account/abonnement', title: 'Mon abonnement'},
    {url: '/cart', title: 'Mon panier'},
  ];

  administrations: any = [];

  brandSelected: any;
  listBrands: any;

  @Input() user: any;

  adminCollapsed: boolean = true;
  accountCollapsed: boolean = true;

  constructor(private router: Router, private authService: AuthService, private dialog: MatDialog, private brandsService: BrandsService, private apiService: ApiService) {}

  ngOnInit(): void {
  }

  ngOnChanges() {
    // this.authService.autoLogin();
    if (!this.user) return;
    if (this.user.is_admin){
      this.listAllBrands();
      this.administrations = [];
      this.administrations.unshift({url: '/products', title: 'Gestion des produits'});
      // this.administrations.unshift({url: '/board', title: 'Champs personnalisés'});
      this.administrations.unshift({url: '/utilisateurs', title: 'Gestion des utilisateurs'});
      this.administrations.unshift({url: '/brands', title: 'Gestion des marques'});
      this.administrations.unshift({url: '/board', title: 'Tableau de bord général'});
    } else {
      this.listBrands = this.user.brands;
      this.brandSelected = this.listBrands[0].id;
      this.brandsService.setBrand(this.brandSelected);
    }
  }

  listAllBrands() {
    this.listBrands = this.user.brands;
    this.brandSelected = this.listBrands[0].id;
    this.brandsService.setBrand(this.brandSelected);
  }

  onBrandSelect(brand: any){
    this.brandSelected = brand;
    this.brandsService.setBrand(this.brandSelected);
  }

  onLogout() {
    const dialogRef = this.dialog.open(DialogConfirmLogout);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.authService.logout();
      }
    });
  }

  triggerAccountMenu() {
    this.accountCollapsed = !this.accountCollapsed;
  }

  triggerAdminMenu() {
    this.adminCollapsed = !this.adminCollapsed;
  }

}

@Component({
  selector: 'confirm-logout',
  templateUrl: './confirm-logout.html',
})
export class DialogConfirmLogout {}