<mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
<div class="page">
  <div class="head">
    <h1>Produits</h1>
    <button mat-raised-button class="success" (click)="addProduct()">
      Ajouter un produit
    </button>
  </div>
  <div class="content" [class.hide]="isLoading">
    <div class="products">
      <mat-card class="product" *ngFor="let product of products">
        <div class="media" (click)="onEdit(product)">
          <img [src]="product.imgProduct[0].original_url" alt="" srcset="" *ngIf="product.imgProduct.length">
        </div>
        <div class="contents">
          <div class="infos">
            <div class="info">
              <div class="title">
                Titre :
              </div>
              <div class="data">
                {{ product.title }}
              </div>
            </div>
            <div class="info">
              <div class="title">
                Prix TTC :
              </div>
              <div class="data">
                {{ product.price_ttc / 100 }}€
              </div>
            </div>
            <!-- <div class="info">
              <div class="title">
                Prix HT :
              </div>
              <div class="data">
                {{ product.price_ht }}€
              </div>
            </div>
            <div class="info">
              <div class="title">
                Taxe :
              </div>
              <div class="data">
                {{ product.taxe }}
              </div>
            </div> -->
            <div class="info">
              <div class="title">
                Visibilité :
              </div>
              <div class="data" *ngIf="product.visibility">
                Visible
              </div>
              <div class="data" *ngIf="!product.visibility">
                Masqué
              </div>
            </div>
            <div class="info">
              <div class="title">
                Crédits :
              </div>
              <div class="data">
                {{ product.credits }}
              </div>
            </div>
          </div>
          <div class="actions">
            <div class="action" (click)="onEdit(product)">
              Éditer
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>