import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-edit-question-light',
  templateUrl: './edit-question-light.component.html',
  styleUrls: ['./edit-question-light.component.scss'],
})
export class EditQuestionLightComponent implements OnInit {
  question: any;
  box: any;

  generator: boolean = true;

  fileSlides: any = [];
  fileVideo: any = [];

  isLoading: boolean = false;

  generatedOrUpload = '';

  bgColor: string = '';
  bgColor2: string = '';
  config: any;
  direction: any;
  videoPreview: any;
  canUploadVideo: boolean = false;

  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.fileSlides = [];
    this.fileVideo = [];
    this.route.params.pipe(first()).subscribe((params) => {
      this.getBox(params['boxId'], params['id']);
    });
  }

  getBox(boxId: any, questionId: any) {
    this.apiService
      .getBox(boxId)
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.box = v.data[0];

          this.question = this.box.questions.find(
            (q: any) => q.id == questionId
          );
          if (this.question) {
            if (this.box.encode_type == 3) {
              if (this.question.video_slide.length) {
                this.isLoading = true;
                this.canUploadVideo = false;
                this.previewVideoUrl(this.question.video_slide[0].original_url);
              } else {
                this.canUploadVideo = true;
              }
            } else {
              if (this.question.slide.length) {
                this.question.slide[0].name = this.question.slide[0].file_name;
                this.urlToFile(this.question.slide[0], 'slide');
              }
              this.generator =
                this.question.is_generated && !this.question.is_imported
                  ? true
                  : false;
            }
          } else {
            this.question = { title: '', new: true };
            if (this.box.encode_type == 3) {
              this.canUploadVideo = true;
            }
          }
        },
        error: (e) => console.error(e),
      });
  }

  urlToFile(element: any, fileType: string) {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      'https://cors.belooga-inc.ca/raw?url=' + element.original_url
    );
    xhr.responseType = 'blob';
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.file_name.split('.');
      type = type[type.length - 1];
      // if (fileType == 'slide') {
      let file = new File([blob], element.name, {
        type: 'image/' + type,
        lastModified: new Date().getTime(),
      });
      this.fileSlides = [];
      this.fileSlides.push(file);
      // }
    };
    xhr.send();
  }

  close() {
    this.isLoading = false;
    this.router.navigate(['/boxes/edit-box/scenario/' + this.box.id]);
  }

  onDelete() {
    if (this.question.id) {
      this.apiService
        .unlinkBoxeToQuestion(this.question.id, this.box.id)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.router.navigate(['/boxes/edit-box/scenario/' + this.box.id]);
          },
          error: (e) => {
            console.error(e);
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
          },
        });
    } else {
      this.router.navigate(['/boxes/edit-box/scenario/' + this.box.id]);
    }
  }

  slideGenerated(event: any) {
    this.bgColor = event.color;
    if (event.color2) this.bgColor2 = event.color2;
    if (event.direction) this.direction = event.direction;
    this.config = {
      canvasBgColor: event.color,
      canvasBgColor2: event.color2,
      canvasFontFamily: event.fontFamily,
      canvasFontWeight: event.fontWeight,
      canvasFontSize: event.fontSize,
      canvasFontColor: event.fontColor,
      canvasLineHeight: event.lineHeight,
      canvasDirection: event.direction,
    };
    this.fileSlides = [];
    this.fileSlides.push(event.file);
    this.isLoading = true;
    this.generatedOrUpload = 'generated';
    setTimeout(() => {
      this.cd.detectChanges();
      this.onSaveQuestion();
    }, 200);
  }

  generatorToggle() {
    this.generator = !this.generator;
  }

  onSelectSlide(event: any) {
    let files = event.addedFiles;
    this.fileSlides.push(files[0]);
    this.generatedOrUpload = 'upload';
  }

  onRemoveSlide() {
    this.fileSlides = [];
  }

  async onSelectVideo(event: any) {
    let files = event.addedFiles;
    this.fileVideo.push(files[0]);

    this.previewVideoFile(this.fileVideo[0]);
    this.canUploadVideo = false;
  }

  onRemoveVideo() {
    this.fileVideo = [];
    this.videoPreview = '';
    this.canUploadVideo = true;
  }

  async previewVideoFile(file: any) {
    this.isLoading = true;
    this.videoPreview = await toBase64(file);
    this.isLoading = false;
  }

  async previewVideoUrl(url: any) {
    this.isLoading = true;
    this.videoPreview = url;
    this.isLoading = false;
  }

  onSaveQuestion() {
    const isTitleValid = this.isTitleValid();
    if (!isTitleValid) {
      this.snackBar.open('Le titre ne doit pas contenir de caractères spéciaux', 'OK', {
        panelClass: ['error-snackbar'],
      });
      this.isLoading = false;
      return;
    }
    this.isLoading = true;
    let modifsObject: any = {};

    modifsObject.title = this.question.title;

    modifsObject.bg_color = this.bgColor;
    if (this.bgColor2) modifsObject.bg_color2 = this.bgColor2;
    if (this.direction) modifsObject.direction = this.direction;

    modifsObject.config = JSON.stringify(this.config);

    if (this.fileSlides && this.box.encode_type == 2)
      modifsObject.slide = this.fileSlides[0];
    if (this.fileVideo && this.box.encode_type == 3)
      modifsObject.video_slide = this.fileVideo[0];
    if (this.generatedOrUpload == 'upload') {
      modifsObject.is_generated = 'false';
      modifsObject.is_imported = '1';
    } else if (this.generatedOrUpload == 'generated') {
      modifsObject.is_generated = '1';
      modifsObject.is_imported = 'false';
    }

    this.box.encode_type == 3
      ? (modifsObject.is_video = '1')
      : (modifsObject.is_video = 'false');
    if (this.question.id) {
      this.apiService
        .editQuestion(this.question.id, modifsObject)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.apiService
              .linkBoxeToQuestion(this.question.id, this.box.id, {
                order: this.question.pivot.order,
              })
              .pipe(first())
              .subscribe({
                next: (v) => {
                  this.isLoading = false;
                  this.snackBar.open('Question modifiée', 'OK', {
                    panelClass: ['success-snackbar'],
                  });
                  this.zone.run(() => {
                    this.close();
                  });
                },
                error: (e) => {
                  this.isLoading = false;
                  console.error(e);
                  this.snackBar.open('Une erreur est survenue', 'OK', {
                    panelClass: ['error-snackbar'],
                  });
                },
              });
          },
          error: (e) => {
            this.isLoading = false;
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
            console.error(e);
          },
        });
    } else {
      this.apiService
        .addQuestion(this.question.title)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.question = v.data;
            this.apiService
              .editQuestion(this.question.id, modifsObject)
              .pipe(first())
              .subscribe({
                next: (v) => {
                  this.apiService
                    .linkBoxeToQuestion(this.question.id, this.box.id, {
                      order: this.box.questions.length + 1,
                    })
                    .pipe(first())
                    .subscribe({
                      next: (v) => {
                        this.isLoading = false;
                        this.zone.run(() => {
                          this.close();
                        });
                      },
                      error: (e) => {
                        this.isLoading = false;
                        console.error(e);
                        this.snackBar.open('Une erreur est survenue', 'OK', {
                          panelClass: ['error-snackbar'],
                        });
                      },
                    });
                },
                error: (e) => {
                  this.isLoading = false;
                  this.snackBar.open('Une erreur est survenue', 'OK', {
                    panelClass: ['error-snackbar'],
                  });
                  console.error(e);
                },
              });
          },
          error: (e) => {
            this.isLoading = false;
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
            console.error(e);
          },
        });
    }
  }

  isTitleDisabled = false;
  isTitleValid() {
    const regexCaracteresSpeciaux = /[@#$%^&*_+{}[\]:;<>,~\\/]|[\p{Extended_Pictographic}]/u;
    const result = regexCaracteresSpeciaux.test(this.question.title)
    if (result) {
      this.isTitleDisabled = true;
      return false;
    } else {
      this.isTitleDisabled = false;
      return true;
    }
  }
}

const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
