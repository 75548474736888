import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { first, lastValueFrom  } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  isLoading: boolean = true;
  users: any;
  currentBrand: any;

  newUsername: any;
  newEmail: any;
  newPassword: any;

  displayedColumns: string[] = ['firtsname', 'lastname', 'email', 'date', 'edit'];

  currentUser: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private router: Router, private apiService: ApiService, public brandsService: BrandsService) {
    this.currentBrand = this.brandsService.subjectValue;
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.currentBrand = res;
      this.initialization();
    })
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.isLoading = true;
    this.listUsers();
  }

  listUsers() {
    this.apiService.getCurrentUser().pipe(first()).subscribe({
      next: (v) => {
        // this.currentUser = v.data[0];
        this.currentUser = v.data;
        if (this.currentUser.is_admin) {
          this.apiService.listUsers().pipe(first()).subscribe({
            next: (v) => {
              // order v.data by created_at from the most recent to the oldest
              v.data.sort((a: any, b: any) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
              });

              this.users = new MatTableDataSource(v.data);
              // for each user, format .created_at to dd/mm/yyyy
              this.users.data.forEach((user: any) => {
                if (!user.created_at) return;
                let date = new Date(user.created_at);
                user.created_at = date.toLocaleDateString('fr-FR');
              });

              this.users.paginator = this.paginator;

              this.paginator._intl.itemsPerPageLabel = "Utilisateurs par page";
              this.paginator._intl.nextPageLabel = 'Page suivante';
              this.paginator._intl.previousPageLabel = 'Page précédente';
              this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
                if (length === 0 || pageSize === 0) {
                  return `0 à ${length }`;
                }
                length = Math.max(length, 0);
                const startIndex = page * pageSize;
                const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
                return `${startIndex + 1} - ${endIndex} sur ${length}`;
              };
              this.isLoading = false;
            },
            error: (e) => console.error(e)
          });
        } else {
          this.apiService.listUsers(this.currentUser.brands[this.currentUser.brands.length - 1].id).pipe(first()).subscribe({
            next: (v) => {
              v.data.sort((a: any, b: any) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
              });

              this.users = new MatTableDataSource(v.data);

              this.users.data.forEach((user: any) => {
                if (!user.created_at) return;
                let date = new Date(user.created_at);
                user.created_at = date.toLocaleDateString('fr-FR');
              });

              this.users.paginator = this.paginator;

              this.paginator._intl.itemsPerPageLabel = "Utilisateurs par page";
              this.paginator._intl.nextPageLabel = 'Page suivante';
              this.paginator._intl.previousPageLabel = 'Page précédente';
              this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
                if (length === 0 || pageSize === 0) {
                  return `0 à ${length }`;
                }
                length = Math.max(length, 0);
                const startIndex = page * pageSize;
                const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
                return `${startIndex + 1} - ${endIndex} sur ${length}`;
              };
              this.isLoading = false;
            },
            error: (e) => console.error(e)
          });
        }
      },
      error: (e) => console.error(e)
    });
  }

  onEdit(user: any) {
    this.router.navigate(['/utilisateurs/edit/' + user.id]);
  }

  filterTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();
  }

  onAddUser() {
    const dialogRef = this.dialog.open(DialogCreateUser, {
      data: {firstname: '', lastname: '', email: '', password: ''},
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.firstname && result.lastname && result.email && result.password) {
        this.apiService.addUser(result.email, result.password, {firstname: result.firstname, lastname: result.lastname}).pipe(first()).subscribe({
          next: (v) => {
            let userID = v.data.id;
            this.apiService.linkUserToBrand(v.data.id, this.currentBrand).pipe(first()).subscribe({
              next: (v) => {
                this.router.navigate(['/utilisateurs/edit/' + userID]);
              },
              error: (e) => {
                this.snackBar.open("Une erreur est survenue", "OK", {
                  panelClass: ['error-snackbar']
                });
                console.error(e);
              }
            });
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      } else {
        this.snackBar.open("Il faut renseigner tous les champs", "OK", {
          panelClass: ['warning-snackbar']
        });
      }
    });
  }

}


export interface DialogData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

@Component({
  selector: 'add-user',
  templateUrl: './add-user.html',
})
export class DialogCreateUser implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCreateUser>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.dialogRef.close(this.data);
  }

  onNoClick() {
    this.data.firstname = '';
    this.data.lastname = '';
    this.data.email = '';
    this.data.password = '';
    this.dialogRef.close();
  }

}