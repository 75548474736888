<div class="questionProblem" *ngIf="box && box.has_problem">
  Attention, votre boîte contient des questions qui ne sont pas correctement configurées. Veuillez
  <a [routerLink]="'/boxes/edit-box/scenario/' + box.id">les modifier</a>
</div>

<mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoadingNext"></mat-progress-bar>
<div class="page" *ngIf="box">
  <div class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>Configuration du montage de la vidéo</h1>
    <div class="empty"></div>
  </div>

  <mat-card class="content">

    <div class="title">Format de sortie</div>

    <div class="subtitle">
      Choisissez le format dans lequel vos utilisateurs recevront leur vidéo.
    </div>

    <div class="container" style="position: relative;" *ngIf="user && user.subPlan && user.subPlan.parameters && (user.subPlan.parameters.mount_type_show_questions == 1 || user.subPlan.parameters.mount_type_show_questions == 2) && box.encode_type != 1">
      <div class="activate" *ngIf="user.subPlan.parameters.mount_type_show_questions == 1">
        <button mat-button class="success pink" (click)="seePlans('PAQ_ACTIVERFORMATSORTIE')">
          Activer cette fonction
        </button>
      </div>
      <div class="subtitle" *ngIf="box.encode_type == 3 || box.encode_type == 2">
        <u>Choix d'affichage des questions par défaut :</u>
      </div>

      <mat-checkbox *ngIf="box.encode_type == 3" [(ngModel)]="questionVideo" (click)="selectQuestionVideo()">La question en vidéo et la réponse à la suite</mat-checkbox>
      <br *ngIf="box.encode_type == 3">
      <mat-checkbox *ngIf="box.encode_type == 3" [(ngModel)]="questionTxt" (click)="selectQuestionTxt()">La question en format texte en sous-titre des réponses</mat-checkbox>
      <br *ngIf="box.encode_type == 3">
      <mat-checkbox *ngIf="box.encode_type == 3" [(ngModel)]="questionImg" (click)="selectQuestionImg()">L'image de la question en transition avant chaque réponse</mat-checkbox>
      <br *ngIf="box.encode_type == 3"><br *ngIf="box.encode_type == 3">

      <mat-checkbox *ngIf="box.encode_type == 2" [(ngModel)]="questionTxt" (click)="selectQuestionTxt()">La question en format texte en sous-titre des réponses</mat-checkbox>
      <br *ngIf="box.encode_type == 2">
      <mat-checkbox *ngIf="box.encode_type == 2 && !box.interview" [(ngModel)]="questionImg" (click)="selectQuestionImg()">L'image de la question à côté de la vidéo de la réponse</mat-checkbox>
      <mat-checkbox *ngIf="box.encode_type == 2 && box.interview" [(ngModel)]="questionImg" (click)="selectQuestionImg()">L'image de la question en transition avant chaque réponse</mat-checkbox>
      <br *ngIf="box.encode_type == 2"><br *ngIf="box.encode_type == 2">
    </div>

    <div class="container" style="position: relative;margin-top:5px;" *ngIf="user && user.subPlan && user.subPlan.parameters && (user.subPlan.parameters.mount_type_result == 1 || user.subPlan.parameters.mount_type_result == 2)">
      <div class="activate" *ngIf="user.subPlan.parameters.mount_type_result == 1">
        <button mat-button class="success pink" (click)="seePlans('PAQ_ACTIVERFORMATSORTIE')">
          Activer cette fonction
        </button>
      </div>
      <div class="subtitle" *ngIf="box.encode_type == 3 || box.encode_type == 2">
        <u>Choix du format de sortie par défaut :</u>
      </div>

      <mat-checkbox [(ngModel)]="formatPortrait" (click)="selectPortrait()">Format portrait</mat-checkbox>
      <br>
      <mat-checkbox [(ngModel)]="formatPaysage" (click)="selectPaysage()">Format paysage</mat-checkbox>
      <br>
      <mat-checkbox [(ngModel)]="formatIdentique" (click)="selectIdentique()">Identique à l’enregistrement</mat-checkbox>
      <!-- <br><br>
      <mat-checkbox [(ngModel)]="answersOnly" (click)="selectAnswersOnly()">La vidéo des réponses sans les questions (format brut)</mat-checkbox>
      <br>
      <mat-checkbox [(ngModel)]="seperateFiles" (click)="selectSeperateFiles()">Chaque réponse dans un fichier individuel (format brut)</mat-checkbox>
      <br><br>
      <mat-checkbox [(ngModel)]="allFormats" (click)="selectAllFormats()">Tous les formats</mat-checkbox> -->
    </div>

  </mat-card>

  <div class="medias">
    <mat-card class="media" *ngIf="user && user.subPlan && user.subPlan.parameters && (user.subPlan.parameters.mount_logo_first == 1 || user.subPlan.parameters.mount_logo_first == 2)">
      <div class="activate" *ngIf="user.subPlan.parameters.mount_logo_first == 1">
        <button mat-button class="success pink" (click)="seePlans()">
          Activer cette fonction
        </button>
      </div>
      <div class="headMedia">
        <div class="title">Logo principal</div>
        <div class="subtitle">
          D’un format conseillé de 250 x 102.
        </div>
      </div>
      <div class="rowSlide">
        <div class="custom-dropzone" ngx-dropzone [compress]="compressImageConfig1" [accept]="'image/*'" (change)="onSelectTopLogo($event)" *ngIf="(fileTopLogo?.length == 0 && !isLoading) || !this.model.logo_top_left.length">
          <mat-icon>cloud_upload</mat-icon>
        </div>
        <mat-spinner *ngIf="fileTopLogo?.length == 0 && isLoading && this.model.logo_top_left.length">sync</mat-spinner>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let ltf of fileTopLogo" [file]="ltf" [removable]="true" (removed)="onRemoveTopLogo()">
        </ngx-dropzone-image-preview>
      </div>
    </mat-card>
    <mat-card class="media" *ngIf="user && user.subPlan && user.subPlan.parameters && (user.subPlan.parameters.mount_logo_second == 1 || user.subPlan.parameters.mount_logo_second == 2)">
      <div class="activate" *ngIf="user.subPlan.parameters.mount_logo_second == 1">
        <button mat-button class="success pink" (click)="seePlans('PAQ_ACTIVERLOGOSECOND')">
          Activer cette fonction
        </button>
      </div>
      <div class="headMedia">
        <div class="title">Logo secondaire</div>
        <div class="subtitle">
          D’un format conseillé de 500 x 205.
        </div>
      </div>
      <div class="rowSlide">
        <!-- <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectMiddleLogo($event)" *ngIf="(fileMiddleLogo?.length == 0 && !isLoading) || !this.model.middle_logo.length">
          <mat-icon>cloud_upload</mat-icon>
        </div> -->
        <div class="custom-dropzone" ngx-dropzone [compress]="compressImageConfig2" [accept]="'image/*'" (change)="onSelectMiddleLogo($event)" *ngIf="(fileMiddleLogo?.length == 0 && !isLoading) || !this.model.middle_logo.length">
          <mat-icon>cloud_upload</mat-icon>
        </div>
        <mat-spinner *ngIf="fileMiddleLogo?.length == 0 && isLoading && this.model.middle_logo.length">sync</mat-spinner>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let ml of fileMiddleLogo" [file]="ml" [removable]="true" (removed)="onRemoveMiddleLogo()">
        </ngx-dropzone-image-preview>
      </div>
    </mat-card>
    <mat-card class="group-media" *ngIf="user && user.subPlan && user.subPlan.parameters && (user.subPlan.parameters.mount_intro == 1 || user.subPlan.parameters.mount_intro == 2)">
      <div class="activate" *ngIf="user.subPlan.parameters.mount_intro == 1">
        <button mat-button class="success pink" (click)="seePlans('PAQ_ACTIVERINTROOUTRO')">
          Activer cette fonction
        </button>
      </div>
      <div class="flex">
        <div class="media">
          <div class="headMedia">
            <div class="title">Vidéo d'intro &nbsp;<i>Desktop</i></div>
            <div class="subtitle">
              Cette vidéo sera automatiquement ajoutée au début de chacun des enregistrements de vos boîtes en format “desktop” (paysage).
              <br>
              Il est conseillé d’envoyer une vidéo en format 1920 x 1080.
            </div>
          </div>
          <div class="dropZone">
            <div class="leftCol">
              <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectIntroDesktop($event)" *ngIf="(fileIntroDesktop?.length == 0 && !isLoading) || !this.model.intro_desktop.length">
                <mat-icon>cloud_upload</mat-icon>
              </div>
              <mat-spinner *ngIf="fileIntroDesktop?.length == 0 && isLoading && this.model.intro_desktop.length">sync</mat-spinner>
            </div>
            <div class="rightCol">
                <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let id of fileIntroDesktop" [file]="id" [removable]="true" (removed)="onRemoveIntroDesktop()">
                </ngx-dropzone-video-preview>
            </div>
          </div>
        </div>
        <div class="media">
          <div class="headMedia">
            <div class="title">Vidéo d'outro &nbsp;<i>Desktop</i></div>
            <div class="subtitle">
              Cette vidéo sera automatiquement ajoutée à la fin de chacun des enregistrements de vos boîtes en format “desktop” (paysage).
              <br>
              Il est conseillé d’envoyer une vidéo en format 1920 x 1080.
            </div>
          </div>
          <div class="dropZone">
            <div class="leftCol">
              <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectOutroDesktop($event)" *ngIf="(fileOutroDesktop?.length == 0 && !isLoading) || !this.model.outro_desktop.length">
                <mat-icon>cloud_upload</mat-icon>
              </div>
              <mat-spinner *ngIf="fileOutroDesktop?.length == 0 && isLoading && this.model.outro_desktop.length">sync</mat-spinner>
            </div>
            <div class="rightCol">
                <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let od of fileOutroDesktop" [file]="od" [removable]="true" (removed)="onRemoveOutroDesktop()">
                </ngx-dropzone-video-preview>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="group-media" *ngIf="user && user.subPlan && user.subPlan.parameters && (user.subPlan.parameters.mount_intro == 1 || user.subPlan.parameters.mount_intro == 2)">
      <div class="activate" *ngIf="user.subPlan.parameters.mount_intro == 1">
        <button mat-button class="success pink" (click)="seePlans('PAQ_ACTIVERINTROOUTRO')">
          Activer cette fonction
        </button>
      </div>
      <div class="flex">
        <div class="media">
          <div class="headMedia">
            <div class="title">Vidéo d'intro &nbsp;<i>Mobile</i></div>
            <div class="subtitle">
              Cette vidéo sera automatiquement ajoutée au début de chacun des enregistrements de vos boîtes en format “mobile” (portrait).
              <br>
              Il est conseillé d’envoyer une vidéo en format 1080 x 1920.
            </div>
          </div>
          <div class="dropZone">
            <div class="leftCol">
              <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectIntroMobile($event)" *ngIf="(fileIntroMobile?.length == 0 && !isLoading) || !this.model.intro_mobile.length">
                <mat-icon>cloud_upload</mat-icon>
              </div>
              <mat-spinner *ngIf="fileIntroMobile?.length == 0 && isLoading && this.model.intro_mobile.length">sync</mat-spinner>
            </div>
            <div class="rightCol">
                <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let im of fileIntroMobile" [file]="im" [removable]="true" (removed)="onRemoveIntroMobile()">
                </ngx-dropzone-video-preview>
            </div>
          </div>
        </div>
        <div class="media">
          <div class="headMedia">
            <div class="title">Vidéo d'outro &nbsp;<i>Mobile</i></div>
            <div class="subtitle">
              Cette vidéo sera automatiquement ajoutée à la fin de chacun des enregistrements de vos boîtes en format “mobile” (portrait).
              <br>
              Il est conseillé d’envoyer une vidéo en format 1080 x 1920.
            </div>
          </div>
          <div class="dropZone">
            <div class="leftCol">
              <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectOutroMobile($event)" *ngIf="(fileOutroMobile?.length == 0 && !isLoading) || !this.model.outro_mobile.length">
                <mat-icon>cloud_upload</mat-icon>
              </div>
              <mat-spinner *ngIf="fileOutroMobile?.length == 0 && isLoading && this.model.outro_mobile.length">sync</mat-spinner>
            </div>
            <div class="rightCol">
                <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let om of fileOutroMobile" [file]="om" [removable]="true" (removed)="onRemoveOutroMobile()">
                </ngx-dropzone-video-preview>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="media" *ngIf="user && user.subPlan && user.subPlan.parameters && (user.subPlan.parameters.mount_bed == 1 || user.subPlan.parameters.mount_bed == 2)">
      <div class="activate" *ngIf="user.subPlan.parameters.mount_bed == 1">
        <button mat-button class="success pink" (click)="seePlans('PAQ_ACTIVERFONDSONORE')">
          Activer cette fonction
        </button>
      </div>
      <div class="headMedia">
        <div class="title">Fond sonore</div>
        <div class="subtitle">
          Cette musique ou ce son sera joué automatiquement en boucle pendant la vidéo (à 15% de son niveau sonore).
        </div>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'audio/*'" (change)="onSelectMusic($event)" *ngIf="!musicUrl">
            <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol" *ngIf="musicUrl">
          <audio controls>
            <source [src]="musicUrl" type="audio/mpeg">
              Audio non supporté par le navigateur
          </audio>
          <button mat-icon-button class="delete" matTooltip="Supprimer l'audio" (click)="onRemoveMusic()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="action">
    <button mat-raised-button class="success" (click)="next()">
      Partager ma boîte <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>