<div class="app-container">
  <div class="sidebar" *ngIf="showSidebar" [class.collapsed]="menuMobile" [class.canswitch]="canSwitch">
    <button *ngIf="canSwitch" class="collapse" mat-icon-button color="white" aria-label="collapse" (click)="mobileMenu()">
      <mat-icon>menu_open</mat-icon>
    </button>
    <app-sidebar [user]="user"></app-sidebar>
  </div>
  <div class="sidebar-trigger" *ngIf="showSidebar && menuMobile">
    <button *ngIf="menuMobile" class="expand" mat-icon-button color="white" aria-label="expand" (click)="desktopMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="content" id="app-content">
    <router-outlet></router-outlet>
  </div>
</div>