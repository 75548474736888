import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  product: any;

  title: any;
  description: any;
  price_ht: any;
  taxe: any;
  price_ttc: any;
  credits: any;
  visibility: any;
  media: any = [];

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute, public brandsService: BrandsService) {
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
   }

  ngOnInit(): void {
    this.initialization();
  }

  initialization(){
    this.route.params.pipe(first()).subscribe(params => {
      this.getProduct(params['id']);
    });
  }

  getProduct(id: any) {
    this.apiService.getProduct(id).pipe(first()).subscribe({
      next: (v) => {
        let datas = v.data;
        this.product = datas[0];

        this.title = this.product.title;
        this.description = this.product.description;
        this.price_ht = this.product.price_ht;
        this.taxe = this.product.taxe;
        this.price_ttc = this.product.price_ttc;
        this.credits = this.product.credits;
        this.visibility = this.product.visibility;

        if (this.product.imgProduct){
          this.urlToFile(this.product.imgProduct[0]);
        }
      },
      error: (e) => console.error(e)
    });
  }

  urlToFile(element: any) {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.original_url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.name.split('.');
      type = type[type.length - 1];
      let file = new File([blob], element.name, {type: "image/" + type, lastModified:new Date().getTime()});
      this.media[0] = file;
    }
    xhr.send();
  }

  onSelectMedia(event: any) {
    let files = event.addedFiles;
    this.media[0] = files[0];
  }

  onRemoveMedia(){
    this.media = [];
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteProduct);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.deleteProduct(this.product.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Produit supprimé", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/products']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  goBack() {
    this.router.navigate(['/products']);
  }

  onSave() {
    let modifsObject: any = {};
    if (this.title !== this.product.title) modifsObject.title = this.title;
    if (this.description !== this.product.description) modifsObject.description = this.description;
    if (this.price_ht !== this.product.price_ht) modifsObject.price_ht = this.price_ht;
    if (this.taxe !== this.product.taxe) modifsObject.taxe = this.taxe;
    if (this.price_ttc !== this.product.price_ttc) modifsObject.price_ttc = this.price_ttc;
    if (this.credits !== this.product.credits) modifsObject.credits = this.credits;

    let visibility = this.visibility ? '1' : '0';
    modifsObject.visibility = visibility;

    if (this.media.length == 0){
      modifsObject.imgProduct = "delete";
    } else if(this.media[0] !== this.product.imgProduct){
      modifsObject.imgProduct = this.media[0];
    }

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editProduct(this.product.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/products']);
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
      this.router.navigate(['/products']);
    }
  }

}


@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteProduct {}