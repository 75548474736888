<div *ngIf="model" class="page">
  <div class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>{{model.title}}</h1>
  </div>

  <div class="title">
    <mat-form-field appearance="fill">
      <mat-label>Nom du modèle</mat-label>
      <input matInput type="text" [(ngModel)]="title">
    </mat-form-field>
  </div>

  <div class="medias">
    <mat-card class="media">
      <div class="headMedia">
        <h2>Logo</h2>
        <p>Présent sur les pages d’enregistrement de vos boites</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectLogo($event)">
            <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let l of fileLogo" [file]="l" [removable]="true" (removed)="onRemoveLogo()">
            </ngx-dropzone-image-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Logo "haut"</h2>
        <p>Présent dans le coin supérieur de votre vidéo</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectTopLogo($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let ltf of fileTopLogo" [file]="ltf" [removable]="true" (removed)="onRemoveTopLogo()">
            </ngx-dropzone-image-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Logo "milieu"</h2>
        <p>Présent au milieu de votre vidéo</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectMiddleLogo($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let ml of fileMiddleLogo" [file]="ml" [removable]="true" (removed)="onRemoveMiddleLogo()">
            </ngx-dropzone-image-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Fond sonore</h2>
        <p>Cette musique sera joué automatiquement en boucle pendant la vidéo (à 15% de son niveau)</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'audio/*'" (change)="onSelectMusic($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol" *ngIf="musicUrl">
          <audio controls>
            <source [src]="musicUrl" type="audio/mpeg">
              Audio non supporté par le navigateur
          </audio>
          <button mat-icon-button class="delete" matTooltip="Supprimer l'audio" (click)="onRemoveMusic()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Vidéo d'intro &nbsp;<i>Desktop</i></h2>
        <p>Cette vidéo sera automatiquement ajouté au début de chacun des enregistrements de vos boites</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectIntroDesktop($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let id of fileIntroDesktop" [file]="id" [removable]="true" (removed)="onRemoveIntroDesktop()">
            </ngx-dropzone-video-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Vidéo d'outro &nbsp;<i>Desktop</i></h2>
        <p>Cette vidéo sera automatiquement ajouté a la fin de chacun des enregistrements de vos boites</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectOutroDesktop($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let od of fileOutroDesktop" [file]="od" [removable]="true" (removed)="onRemoveOutroDesktop()">
            </ngx-dropzone-video-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Vidéo d'intro &nbsp;<i>Mobile</i></h2>
        <p>Cette vidéo sera automatiquement ajouté au début de chacun des enregistrements de vos boites</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectIntroMobile($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let im of fileIntroMobile" [file]="im" [removable]="true" (removed)="onRemoveIntroMobile()">
            </ngx-dropzone-video-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Vidéo d'outro &nbsp;<i>Mobile</i></h2>
        <p>Cette vidéo sera automatiquement ajouté a la fin de chacun des enregistrements de vos boites</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'video/mp4'" (change)="onSelectOutroMobile($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let om of fileOutroMobile" [file]="om" [removable]="true" (removed)="onRemoveOutroMobile()">
            </ngx-dropzone-video-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Son de transition</h2>
        <p>Ce son sera joué à chaque passage de question. Evitez les fichiers de plus de <b>1 seconde</b>.</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'audio/*'" (change)="onSelectTransition($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol" *ngIf="transitionUrl">
          <audio controls>
            <source [src]="transitionUrl" type="audio/mpeg">
              Audio non supporté par le navigateur
          </audio>
          <button mat-icon-button class="delete" matTooltip="Supprimer l'audio" (click)="onRemoveTransition()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Image d'arrière plan</h2>
        <p>Cette image est présente en arrière plan du retour caméra lors des enregistrements.</p>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectBackgroundRecImage($event)">
              <mat-icon>cloud_upload</mat-icon>
          </div>
        </div>
        <div class="rightCol">
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileBackgroundRecImage" [file]="f" [removable]="true" (removed)="onRemoveBackgroundRecImage()">
            </ngx-dropzone-image-preview>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Couleur de fond du bouton "suivant" lors des enregistrements</h2>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="color">
            <input type="color" id="background_next_btn" name="background_next_btn" [(ngModel)]="background_next_btn">
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="media">
      <div class="headMedia">
        <h2>Couleur du texte du bouton "suivant" lors des enregistrements</h2>
      </div>
      <div class="dropZone">
        <div class="leftCol">
          <div class="color">
            <input type="color" id="color_next_btn " name="color_next_btn" [(ngModel)]="color_next_btn ">
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="action">
    <button mat-raised-button class="success" (click)="onSave()">
      Enregistrer
    </button>
  </div>
</div>