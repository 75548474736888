import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-config-record',
  templateUrl: './config-record.component.html',
  styleUrls: ['./config-record.component.scss'],
})
export class ConfigRecordComponent implements OnInit {
  tinyMCE: string = environment.tinyMCE;
  tinyMceInit: any;

  box: any;
  model: any;

  allForms = [
    { id: 10, title: 'Alsace Fan Day Test 01' },
    { id: 9999, title: 'Form xxx' },
    { id: 99999, title: 'Form yyy' },
  ];

  order_type_array = [
    { value: 1, text: "Respecter l'ordre" },
    { value: 2, text: 'Ordre aléatoire' },
    { value: 3, text: 'Ordre en conservant la première et la dernière' },
  ];

  status_type_array = [
    { value: 1, text: 'Brouillon' },
    { value: 2, text: 'Désactivée' },
    { value: 5, text: 'Mode salon' },
    { value: 4, text: 'Publiée' },
    { value: 10, text: 'Privée' },
  ];

  fileLogo: any = [];
  fileLogoDeleted: boolean = false;
  fileMedia: any = [];
  fileMediaDeleted: boolean = false;

  isLoading: boolean = false;
  isLoadingNext: boolean = false;
  countLoads: number = 0;

  onlyEmail: boolean = true;

  fileTransition: any = [];
  fileTransitionDeleted: boolean = false;
  transitionUrl: any;
  fileBackgroundRecImage: any = [];
  fileBackgroundRecImageDeleted: boolean = false;

  background_next_btn: any;
  color_next_btn: any;

  user: any;

  constructor(
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    public brandsService: BrandsService
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
    this.route.params.pipe(first()).subscribe((params) => {
      this.getBox(params['id']);
    });
    this.tinyMceInit = {
      plugins:
        'preview importcss tinydrive searchreplace autolink save directionality visualblocks visualchars fullscreen link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
      importcss_append: true,
      height: 300,
      contextmenu: false,
    };
  }

  getCurrentUser() {
    this.apiService
      .getCurrentUser()
      .pipe(first())
      .subscribe({
        next: (v) => {
          // this.user = v.data[0];
          this.user = v.data;
          this.apiService
            .listSubscriptionPlans()
            .pipe(first())
            .subscribe({
              next: (v) => {
                this.user.subPlan = v.data.find(
                  (plan: any) => plan.title == this.user.subscription_type
                );
                if (!this.user.subPlan) {
                  this.user.subPlan = v.data.find(
                    (plan: any) => plan.title == 'Freemium'
                  );
                }
                this.user.subPlan.parameters = JSON.parse(
                  this.user.subPlan.parameters
                );
                if (this.user.is_admin) {
                  this.user.subPlan.parameters = {
                    box_type_1: 2,
                    box_type_2: 2,
                    box_type_3: 2,
                    mount_bed: 2,
                    mount_intro: 2,
                    mount_logo_first: 2,
                    mount_logo_second: 2,
                    mount_type_result: 2,
                    mount_type_show_questions: 2,
                    nav_categories_list: 2,
                    nav_form_list: 2,
                    nav_modeles_list: 2,
                    nav_questions_list: 2,
                    order: 3,
                    question_limit: 0,
                    rec_box_background: 2,
                    rec_box_btn_color: 2,
                    rec_box_description: 2,
                    rec_box_general: 2,
                    rec_box_illustration: 2,
                    rec_box_logo: 2,
                    rec_box_transition_sound: 2,
                    rec_box_waiting_text: 2,
                  };
                }
              },
              error: (e) => console.error(e),
            });
        },
        error: (e) => console.error(e),
      });
  }

  getBox(id: any) {
    this.apiService
      .getBox(id)
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.box = v.data[0];

          if (this.box.mandatory_form) {
            this.onlyEmail = false;
          }

          this.box.mail_end
            ? (this.box.mail_end = 'end')
            : (this.box.mail_end = 'start');

          if (this.box.cover) {
            for (const value of Object.entries(this.box.cover)) {
              const values: any = value[1];
              this.urlToFile(values, this.fileMedia);
            }
          }

          if (this.box.questions && this.box.questions.length) {
            this.box.questions.forEach((question: any) => {
              if (this.box.encode_type == 3 && !question.is_video) {
                this.box.has_problem = true;
              }
            });
          }

          this.setupModel();
        },
        error: (e) => console.error(e),
      });
  }

  setupModel() {
    this.fileTransition = [];
    this.fileBackgroundRecImage = [];

    this.model = this.box.model;

    if (this.model.background_next_btn) {
      this.background_next_btn = this.model.background_next_btn;
    }

    if (this.model.color_next_btn) {
      this.color_next_btn = this.model.color_next_btn;
    }

    if (this.model.logo) {
      for (const value of Object.entries(this.model.logo)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileLogo);
      }
    }

    if (this.model.transition) {
      for (const value of Object.entries(this.model.transition)) {
        const values: any = value[1];
        this.transitionUrl = values.original_url;
        this.urlToFile(values, this.fileTransition);
      }
    }

    if (this.model.background_rec_image) {
      for (const value of Object.entries(this.model.background_rec_image)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileBackgroundRecImage);
      }
    }
  }

  urlToFile(element: any, typeMedia: any) {
    this.isLoading = true;
    this.countLoads = this.countLoads + 1;
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open(
      'GET',
      'https://cors.belooga-inc.ca/raw?url=' + element.original_url
    );
    xhr.responseType = 'blob';
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.name.split('.');
      type = type[type.length - 1];
      if (type === 'mp3') {
        var file = new File([blob], element.name, {
          type: 'audio/' + type,
          lastModified: new Date().getTime(),
        });
        typeMedia.push(file);
      } else {
        var file = new File([blob], element.name, {
          type: 'image/' + type,
          lastModified: new Date().getTime(),
        });
        typeMedia.push(file);
      }
      this.countLoads = this.countLoads - 1;
      if (this.countLoads == 0) {
        this.isLoading = false;
      }
    };
    xhr.send();
  }

  goBack() {
    this.router.navigate(['/boxes/edit-box/scenario/' + this.box.id]);
  }

  onOrderTypeSelect(order_type: any) {
    if (this.user.subPlan.parameters.rec_box_general !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    this.box.order_type = Number(order_type);
  }

  onSelectStatus(status: any) {
    if (this.user.subPlan.parameters.rec_box_general !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    this.box.status = Number(status);
  }

  onSelectMedia(event: any) {
    if (this.user.subPlan.parameters.rec_box_illustration !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    let files = event.addedFiles;
    this.fileMedia[0] = files[0];
  }

  onRemoveMedia() {
    this.fileMedia = [];
    this.fileMediaDeleted = true;
  }

  onSelectTransition(event: any) {
    if (this.user.subPlan.parameters.rec_box_transition_sound !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    let files = event.addedFiles;
    this.fileTransition[0] = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.transitionUrl = (<FileReader>event.target).result;
      this.transitionUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.transitionUrl
      );
    };
  }

  onRemoveTransition() {
    this.fileTransition = [];
    this.transitionUrl = '';
    this.fileTransitionDeleted = true;
  }

  onSelectBackgroundRecImage(event: any) {
    if (this.user.subPlan.parameters.rec_box_background !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    let files = event.addedFiles;
    this.fileBackgroundRecImage[0] = files[0];
  }

  onRemoveBackgroundRecImage() {
    this.fileBackgroundRecImage = [];
    this.fileBackgroundRecImageDeleted = true;
  }

  onSelectLogo(event: any) {
    if (this.user.subPlan.parameters.rec_box_logo !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    let files = event.addedFiles;
    this.fileLogo[0] = files[0];
  }

  onRemoveLogo() {
    this.fileLogo = [];
    this.fileLogoDeleted = true;
  }

  next() {
    this.isLoadingNext = true;

    //Box
    let cover = '';
    if (this.fileMedia.length == 0 && this.fileMediaDeleted) {
      cover = 'delete';
    } else if (
      this.fileMedia.length &&
      this.box.cover &&
      this.fileMedia[0].name !== this.box.cover.name &&
      this.fileMedia[0].size !== this.box.cover.size
    ) {
      cover = this.fileMedia[0];
    }

    let interview = this.box.interview ? '1' : '0';
    let mandatory_form = this.box.mandatory_form ? '1' : '0';
    if (!this.box.waiting_text) this.box.waiting_text = ' ';
    if (!this.box.moreinfo) this.box.moreinfo = ' ';
    let mail_end = this.box.mail_end == 'end' ? '1' : '0';

    //Model
    let modifsObject: any = {};

    if (this.fileTransition.length == 0) {
      this.box.transition = '0';
      if (this.fileTransitionDeleted) {
        modifsObject.transition = 'delete';
      }
    } else {
      this.box.transition = '1';
      if (
        this.model.transition.length &&
        this.fileTransition[0].name !== this.model.transition[0].name &&
        this.fileTransition[0].size !== this.model.transition[0].size
      ) {
        modifsObject.transition = this.fileTransition[0];
      } else if (!this.model.transition.length) {
        modifsObject.transition = this.fileTransition[0];
      }
    }

    if (this.fileLogo.length == 0 && this.fileLogoDeleted) {
      modifsObject.logo = 'delete';
    } else if (
      this.model.logo.length &&
      this.fileLogo.length &&
      this.fileLogo[0].name !== this.model.logo[0].name &&
      this.fileLogo[0].size !== this.model.logo[0].size
    ) {
      modifsObject.logo = this.fileLogo[0];
    } else if (!this.model.logo.length && this.fileLogo.length) {
      modifsObject.logo = this.fileLogo[0];
    }

    if (
      this.fileBackgroundRecImage.length == 0 &&
      this.fileBackgroundRecImageDeleted
    ) {
      modifsObject.background_rec_image = 'delete';
    } else if (
      this.model.background_rec_image.length &&
      this.fileBackgroundRecImage.length &&
      this.fileBackgroundRecImage[0].name !==
        this.model.background_rec_image[0].name &&
      this.fileBackgroundRecImage[0].size !==
        this.model.background_rec_image[0].size
    ) {
      modifsObject.background_rec_image = this.fileBackgroundRecImage[0];
    } else if (
      !this.model.background_rec_image.length &&
      this.fileBackgroundRecImage.length
    ) {
      modifsObject.background_rec_image = this.fileBackgroundRecImage[0];
    }

    if (this.background_next_btn !== this.model.background_next_btn)
      modifsObject.background_next_btn = this.background_next_btn;
    if (this.color_next_btn !== this.model.color_next_btn)
      modifsObject.color_next_btn = this.color_next_btn;

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService
        .editModel(this.model.id, modifsObject)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.apiService
              .editBox(this.box.id, {
                interview: interview,
                order_type: this.box.order_type,
                moreinfo: this.box.moreinfo,
                waiting_text: this.box.waiting_text,
                form: this.box.form_id,
                mandatory_form: mandatory_form,
                cover: cover,
                mail_end: mail_end,
                transition: this.box.transition,
                status: this.box.status,
                admin_exhibition_code: this.box.admin_exhibition_code,
              })
              .pipe(first())
              .subscribe({
                next: (v) => {
                  this.isLoadingNext = false;
                  this.router.navigate([
                    '/boxes/edit-box/config-montage/' + this.box.id,
                  ]);
                },
                error: (e) => {
                  this.isLoadingNext = false;
                  this.snackBar.open('Une erreur est survenue', 'OK', {
                    panelClass: ['error-snackbar'],
                  });
                  console.error(e);
                },
              });
          },
          error: (e) => {
            this.isLoadingNext = false;
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
            console.error(e);
          },
        });
    } else {
      this.apiService
        .editBox(this.box.id, {
          interview: interview,
          order_type: this.box.order_type,
          moreinfo: this.box.moreinfo,
          waiting_text: this.box.waiting_text,
          form: this.box.form_id,
          mandatory_form: mandatory_form,
          cover: cover,
          mail_end: mail_end,
          status: this.box.status,
          admin_exhibition_code: this.box.admin_exhibition_code,
        })
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.isLoadingNext = false;
            this.router.navigate([
              '/boxes/edit-box/config-montage/' + this.box.id,
            ]);
          },
          error: (e) => {
            this.isLoadingNext = false;
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
            console.error(e);
          },
        });
    }
  }

  seePlans(active_type?: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/account/abonnement'])
    );
    if (!active_type) window.open(url, '_blank');
    else {
      this.apiService.sendClickInfo(active_type).subscribe({
        next: (v) => {
          window.open(url, '_blank');
        },
        error: (e) => console.error(e.message),
      });
    }
  }
}
