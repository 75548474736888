import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { BrandsService } from 'src/app/services/brands.service';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/user.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseApiUrl: string = environment.baseApiUrl;
  apiAuth: string = environment.apiAuth;
  apiPass: string = environment.apiPass;

  userData = (JSON.parse(String(localStorage.getItem('userData')))) || {};

  userID = this.userData.id;
  userToken = this.userData.token;

  // userID = '1';
  // userToken = '1r49GGexsgHv7BaKPVzV04d86ZDM11EMFmyB81sTwInFcK354XfkAksXjrcT';

  brandID: any;

  httpFormOptions = {
    headers: new HttpHeaders({
      Authorization: 'Basic ' + btoa(this.apiAuth + ':' + this.apiPass)
    })
  };

  constructor(private authService: AuthService, private http: HttpClient, public brandsService: BrandsService) {
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.brandID = res;
    });
    this.authService.user.subscribe(user => {
      if (user) {
        this.userID = user.id;
        this.userToken = user.token;
      }
    });
  }

  //USER
  listUsers(brand_id?: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    if (brand_id) {
      form.set('brand_id', brand_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listAdmins() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/admins`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editUser(user_id_target: any, options?: {username?: string, email?: any, password?: any, firstname?: string, lastname?: string, is_admin?: any, active?: any}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('user_id_target', user_id_target);
    if (options) {
      if (options.username) form.set('username', options.username);
      if (options.email) form.set('email', options.email);
      if (options.password) form.set('password', options.password);
      if (options.firstname) form.set('firstname', options.firstname);
      if (options.lastname) form.set('lastname', options.lastname);
      if (options.is_admin) form.set('is_admin', options.is_admin);
      form.set('active', options.active ? '1' : '0');
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addUser(email: string, password: string, options?: {firstname?: string, lastname?: string}) {
    const form: FormData = new FormData();
    // // form.set('user_id', this.userID);
    form.set('email', email);
    form.set('password', password);
    if (options) {
      if (options.firstname) form.set('firstname', options.firstname);
      if (options.lastname) form.set('lastname', options.lastname);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/add`,
      form,
      // // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getUser(users_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('users_ids', users_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getCurrentUser() {
    return this.http.post<any>(
      `${environment.baseApiUrl}/users/get`,
      undefined,
      // // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
        const user = new User(resData.data.id, resData.data.token, resData.data.is_admin);
        localStorage.setItem('userData', JSON.stringify(user));
      })
    );
  }

  activateUser(user_id: any, token: any) {
    const form: FormData = new FormData();
    form.set('user_id', user_id);
    form.set('token', token);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/validation`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeUser(user_id_target: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('user_id_target', user_id_target);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  giveCredits(user_id_target: any, credits: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('user_id_target', user_id_target);
    form.set('credits', credits);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/add_credits`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  //MODEL
  listModels() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', this.brandID)

    return this.http.post<any>(
      `${environment.baseApiUrl}/models/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editModel(model_id: any, options?:
    {title?: string, logo?: any, logo_top_left?: any, middle_logo?: any,
      intro_desktop?: any, outro_desktop?: any, intro_mobile?: any,
      outro_mobile?: any, music?: any, transition?: any, background_rec_image?: any,
      background_next_btn?: any,  color_next_btn?: any
    }){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('model_id', model_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.logo) form.set('logo', options.logo);
      if (options.logo_top_left) form.set('logo_top_left', options.logo_top_left);
      if (options.middle_logo) form.set('middle_logo', options.middle_logo);
      if (options.intro_desktop) form.set('intro_desktop', options.intro_desktop);
      if (options.outro_desktop) form.set('outro_desktop', options.outro_desktop);
      if (options.intro_mobile) form.set('intro_mobile', options.intro_mobile);
      if (options.outro_mobile) form.set('outro_mobile', options.outro_mobile);
      if (options.music) form.set('music', options.music);
      if (options.transition) form.set('transition', options.transition);
      if (options.background_rec_image) form.set('background_rec_image', options.background_rec_image);
      if (options.background_next_btn) form.set('background_next_btn', options.background_next_btn);
      if (options.color_next_btn) form.set('color_next_btn', options.color_next_btn);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/models/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addModel(title: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('brand_id', this.brandID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/models/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getModel(model_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('model_ids', model_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/models/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeModel(model_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('model_id', model_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/models/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //FORM ELEMENT
  addFormElement(title: any, form_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('form_id', form_id);
    form.set('brand_id', this.brandID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms_elements/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editFormElement(form_elements_id: any, options?: {title?: string, description?: any, type?: any, width?: any, required?: any, order?: any}) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('form_elements_id', form_elements_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.description) form.set('description', options.description);
      if (options.type) form.set('type', options.type);
      if (options.width) form.set('width', options.width);
      if (options.required) form.set('required', options.required);
      if (options.order) form.set('order', options.order);
    }


    return this.http.post<any>(
      `${environment.baseApiUrl}/forms_elements/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteFormElement(form_elements_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('form_elements_id', form_elements_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms_elements/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  orderFormElements(formElements: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('formElements', formElements);

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms_elements/order`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //FORM
  listForms() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', this.brandID)

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editForm(form_id: any, options?: {title?: string, category_id?: any, cc_email?: any, name_mail?: any, send_password?: any, public_title?: string}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('form_id', form_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.public_title) form.set('public_title', options.public_title);
      if (options.category_id) form.set('category_id', options.category_id);
      if (options.cc_email) form.set('cc_email', options.cc_email);
      if (options.name_mail) form.set('name_mail', options.name_mail);
      if (options.send_password) form.set('send_password', options.send_password);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addForm(title: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('brand_id', this.brandID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getForm(form_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('form_ids', form_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeForm(form_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('form_id', form_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getEntries(form_element_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('form_element_ids', form_element_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/forms/entries`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //CONFIG
  listConfigs() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/configs/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editConfig(config_id: any, options?: {title?: string, value?: any}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('config_id', config_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.value) form.set('value', options.value);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/configs/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addConfig(title: string, value: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('value', value);

    return this.http.post<any>(
      `${environment.baseApiUrl}/configs/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getConfig(config_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('config_ids', config_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/configs/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeConfig(config_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('config_id', config_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/configs/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //CATEGORY
  listCategories() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', this.brandID)

    return this.http.post<any>(
      `${environment.baseApiUrl}/categories/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editCategory(category_id: any, options?: {title?: string, imgCategory?: any}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('category_id', category_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.imgCategory) form.set('imgCategory', options.imgCategory);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/categories/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addCategory(title: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('brand_id', this.brandID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/categories/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getCategory(category_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('category_ids', category_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/categories/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeCategory(category_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('category_id', category_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/categories/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //BOX
  listBoxes() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', this.brandID)

    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/list`,
      form,
      // // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editBox(box_id: any, options?: {title?: string, model?: any, category?: any, form?: any, top_logo?: any, middle_logo?: any, audio_bed?: any, rec_on?: any, mail_end?: any, mandatory_form?: any, status?: any, order_type?: any, interview?: any, private_code?: any, admin_exhibition_code?: any, waiting_text?: any, cover?: any, transition?: any, moreinfo ?: any, encode_type?: any, parameters?: any}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('box_id', box_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.model) form.set('model_id', options.model);
      if (options.category) form.set('category_id', options.category);
      if (options.form) form.set('form_id', options.form);
      if (options.top_logo) form.set('top_logo', options.top_logo);
      if (options.middle_logo) form.set('middle_logo', options.middle_logo);
      if (options.audio_bed) form.set('audio_bed', options.audio_bed);
      if (options.rec_on) form.set('rec_on', options.rec_on);
      if (options.mail_end) form.set('mail_end', options.mail_end);
      if (options.mandatory_form) form.set('mandatory_form', options.mandatory_form);
      if (options.status) form.set('status', options.status);
      if (options.order_type) form.set('order_type', options.order_type);
      if (options.interview) form.set('interview', options.interview);
      if (options.private_code) form.set('private_code', options.private_code);
      if (options.admin_exhibition_code) form.set('admin_exhibition_code', options.admin_exhibition_code);
      if (options.waiting_text) form.set('waiting_text', options.waiting_text);
      if (options.cover) form.set('cover', options.cover);
      if (options.transition) form.set('transition', options.transition);
      if (options.moreinfo) form.set('moreinfo', options.moreinfo);
      if (options.encode_type) form.set('encode_type', options.encode_type);
      if (options.parameters) form.set('parameters', options.parameters);
    }


    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addBox(title: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('brand_id', this.brandID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  duplicateBox(title: string, box_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('box_id', box_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/duplicate`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addBoxInvitation(box_id: string, emails: any, value: any, link: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('box_id', box_id);
    form.set('emails', emails);
    form.set('value', value);
    form.set('link', link);

    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/invitations/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getBox(box_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('box_ids', box_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeBox(box_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('box_id', box_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  orderQuestion(questions: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('questions', questions);

    return this.http.post<any>(
      `${environment.baseApiUrl}/boxes/order`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //QUESTION
  listQuestions() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', this.brandID)

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editQuestion(question_id: any, options?: {title?: string, slide?: any, is_video?: any, video_slide?: any, is_generated?: any, is_imported?: any, bg_color?: any, config?: any}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('question_id', question_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.slide) form.set('slide', options.slide);
      if (options.is_video) form.set('is_video', options.is_video);
      if (options.video_slide) form.set('video_slide', options.video_slide);
      if (options.is_generated) form.set('is_generated', options.is_generated);
      if (options.is_imported) form.set('is_imported', options.is_imported);
      if (options.bg_color) form.set('bg_color', options.bg_color);
      if (options.config) form.set('config', options.config);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addQuestion(title: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);
    form.set('brand_id', this.brandID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getQuestion(question_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('question_ids', question_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeQuestion(question_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('question_id', question_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  linkCategoryToQuestion(question_id: any, categories: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('question_id', question_id);
    form.set('categories', categories);

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/link/categories`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  unlinkCategoryToQuestion(question_id: any, categories: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('question_id', question_id);
    form.set('categories', categories);

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/unlink/categories`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  linkBoxeToQuestion(question_id: any, box_id: any, options?: {order?: any}) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('question_id', question_id);
    form.set('box_id', box_id);
    if (options) {
      if (options.order) form.set('order', options.order);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/link/boxes`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  unlinkBoxeToQuestion(question_id: any, box_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('question_id', question_id);
    form.set('box_id', box_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/questions/unlink/boxes`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //SENT
  listSent() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', this.brandID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/sent/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listSentIds(brand_id?: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    // if brand_id is null, do not set it in the form
    if (brand_id == 'aucun') {}
    else form.set('brand_id', this.brandID);


    return this.http.post<any>(
      `${environment.baseApiUrl}/sent/list_ids`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getSents(sent_ids: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('sent_ids', sent_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/sent/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  download(vimeo_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('vimeo_id', vimeo_id)

    return this.http.post<any>(
      `${environment.baseApiUrl}/sent/download`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  allSents() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/sent/allSents`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //FORM ENTRIES
  getFormEntries(form_response_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('form_response_ids', form_response_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/form_entries/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  //BRAND
  listBrands() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/brands/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editBrand(brand_id: any, options?: {title?: string, logoBrand?: any, description?: any, big_description?: any}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', brand_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.logoBrand) form.set('logoBrand', options.logoBrand);
      if (options.description) form.set('description', options.description);
      if (options.big_description) form.set('big_description', options.big_description);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/brands/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );

  }

  addBrand(title: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);

    return this.http.post<any>(
      `${environment.baseApiUrl}/brands/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getBrand(brand_ids: string) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_ids', brand_ids);

    return this.http.post<any>(
      `${environment.baseApiUrl}/brands/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeBrand(brand_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brand_id', brand_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/brands/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  orderBrands(brands: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('brands', brands);

    return this.http.post<any>(
      `${environment.baseApiUrl}/brands/order`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  linkUserToBrand(user_id: any, brands: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('user_id_target', user_id);
    form.set('brands', brands);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/link/brands`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  unlinkUserToBrand(user_id: any, brands: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('user_id_target', user_id);
    form.set('brands', brands);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/unlink/brands`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  listProducts() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/products/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getProduct(product_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('product_id', product_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/products/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addProduct(title: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('title', title);

    return this.http.post<any>(
      `${environment.baseApiUrl}/products/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  editProduct(product_id: any, options?: {title?: string, description?: any, imgProduct?: any, price_ht?: any, taxe?: any, price_ttc?: any, credits?: any, visibility?: any}){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('product_id', product_id);
    if (options) {
      if (options.title) form.set('title', options.title);
      if (options.description) form.set('description', options.description);
      if (options.imgProduct) form.set('imgProduct', options.imgProduct);
      if (options.price_ht) form.set('price_ht', options.price_ht);
      if (options.taxe) form.set('taxe', options.taxe);
      if (options.price_ttc) form.set('price_ttc', options.price_ttc);
      if (options.credits) form.set('credits', options.credits);
      if (options.visibility) form.set('visibility', options.visibility);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/products/edit`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteProduct(product_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('product_id', product_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/products/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  getBasket(basket_id: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('basket_id', basket_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/baskets/get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addBasket() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('user_id_target', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/baskets/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  addProductToBasket(product_id: any, basket_id?: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('product_id', product_id);
    if (basket_id) form.set('basket_id', basket_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/baskets/product/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  removeProductFromBasket(product_id: any, basket_id?: any) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('product_id', product_id);
    form.set('basket_id', basket_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/baskets/product/remove`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  addPurchase(options?: {validity?: string, price_ht?: any, price_ttc?: any, taxe?: any, credits?: any, gift?: any, secure?: any, bank_token?: any, basket_id?: any}) {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    if (options) {
      if (options.validity) form.set('validity', options.validity);
      if (options.price_ht) form.set('price_ht', options.price_ht);
      if (options.price_ttc) form.set('price_ttc', options.price_ttc);
      if (options.taxe) form.set('taxe', options.taxe);
      if (options.credits) form.set('credits', options.credits);
      if (options.gift) form.set('gift', options.gift);
      if (options.secure) form.set('3d_sure', options.secure);
      if (options.bank_token) form.set('bank_token', options.bank_token);
      if (options.basket_id) form.set('basket_id', options.basket_id);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/purchases/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listPurchases() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('user_id_target', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/purchases/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listPurchasesActiveSub() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/purchases/list_purchase_active_sub`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  createStancerIntent(amount: any, currency: any, options?: {description?: string, order_id?: any, unique_id?: any, methods_allowed?: any, auth?: any}) {
    const form: FormData = new FormData();
    form.set('amount', amount);
    form.set('currency', currency);
    if (options) {
      if (options.description) form.set('description', options.description);
      if (options.order_id) form.set('order_id', options.order_id);
      if (options.unique_id) form.set('unique_id', options.unique_id);
      if (options.methods_allowed) form.set('methods_allowed', options.methods_allowed);
      if (options.auth) form.set('auth', options.auth);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/purchases/stancer_intention`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  getStancerPayment(url: any) {
    const form: FormData = new FormData();
    form.set('url', url);

    return this.http.post<any>(
      `${environment.baseApiUrl}/purchases/stancer_get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }



  addActiveSubscription(subscription_plan_id: any, card_id: any, card_exp_month: any, card_exp_year: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('subscription_plan_id', subscription_plan_id);
    form.set('card_id', card_id);
    form.set('card_exp_month', card_exp_month);
    form.set('card_exp_year', card_exp_year);

    return this.http.post<any>(
      `${environment.baseApiUrl}/active_subscriptions/add`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  reSub(active_subscription_id: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('active_subscription_id', active_subscription_id);

    return this.http.post<any>(
      `${environment.baseApiUrl}/active_subscriptions/resub`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listActiveSubscription(){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/active_subscriptions/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  deleteActiveSubscription(active_subscription_id: any, disable_reason: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('active_subscription_id', active_subscription_id);
    form.set('disable_reason', disable_reason);

    return this.http.post<any>(
      `${environment.baseApiUrl}/active_subscriptions/delete`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  createStancerIntentActiveSubscription(amount: any, currency: any, options?: {description?: string, order_id?: any, unique_id?: any, methods_allowed?: any, auth?: any}) {
    const form: FormData = new FormData();
    form.set('amount', amount);
    form.set('currency', currency);
    if (options) {
      if (options.description) form.set('description', options.description);
      if (options.order_id) form.set('order_id', options.order_id);
      if (options.unique_id) form.set('unique_id', options.unique_id);
      if (options.methods_allowed) form.set('methods_allowed', options.methods_allowed);
      if (options.auth) form.set('auth', options.auth);
    }

    return this.http.post<any>(
      `${environment.baseApiUrl}/active_subscriptions/stancer_intention`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }


  getStancerPaymentActiveSubscription(url: any) {
    const form: FormData = new FormData();
    form.set('url', url);

    return this.http.post<any>(
      `${environment.baseApiUrl}/active_subscriptions/stancer_get`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listSubscriptionPlans() {
    // const form: FormData = new FormData();
    // // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/subscription_plans/list`,
      undefined,
      // // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  listCreditsUse() {
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);

    return this.http.post<any>(
      `${environment.baseApiUrl}/credit_uses/list`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  changePaymentMethod(active_subscription_id: any, card_id: any, card_exp_month: any, card_exp_year: any){
    const form: FormData = new FormData();
    // form.set('user_id', this.userID);
    form.set('active_subscription_id', active_subscription_id);
    form.set('card_id', card_id);
    form.set('card_exp_month', card_exp_month);
    form.set('card_exp_year', card_exp_year);

    return this.http.post<any>(
      `${environment.baseApiUrl}/active_subscriptions/change_payment_method`,
      form,
      // this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  activatePromo(subscription_plan_title: any){
    const form: FormData = new FormData();
    form.set('user_id', this.userID);
    form.set('subscription_plan_title', subscription_plan_title);

    const url = environment.baseApiUrl.replace('back', 'extern');

    return this.http.post<any>(
      `${url}/users/promo`,
      form,
      this.httpFormOptions
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }

  sendClickInfo(click_info: any){
    const form: FormData = new FormData();
    form.set('active_type', click_info);

    return this.http.post<any>(
      `${environment.baseApiUrl}/users/click_info`,
      form,
    )
    .pipe(
      catchError(errorRes => {
        const errorMessage = errorRes.error.message;
        throw new Error(errorMessage);
      }),
      tap(resData => {
      })
    );
  }
}