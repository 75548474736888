import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})

export class EditQuestionComponent implements OnInit {

  question: any;
  title: any;

  fileSlides: any = [];

  allCategories: any;
  categories = new FormControl();
  categoriesList: any;

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute, public brandsService: BrandsService) {
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization(){
    this.route.params.pipe(first()).subscribe(params => {
      this.getQuestion(params['id']);
    });
    this.listCategories();
  }

  listCategories() {
    this.apiService.listCategories().pipe(first()).subscribe({
      next: (v) => {
        this.allCategories = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  onCategoriesSelect(event: any) {
    if (event.relatedTarget) {
      return;
    }

    let newCatIds = [...this.categories.value];
    let removeCats: any;
    let addCats: any;

    let currentCatIds = this.question.categories.map((cat: any) => cat.id);
    removeCats = currentCatIds.filter((cat: any) => !newCatIds.includes(cat));
    addCats = newCatIds.filter((cat: any) => !currentCatIds.includes(cat));

    if ((addCats.length > 0) && (removeCats.length > 0)){
      this.apiService.linkCategoryToQuestion(this.question.id, newCatIds).pipe(first()).subscribe({
        next: (v) => {
          this.apiService.unlinkCategoryToQuestion(this.question.id, removeCats).pipe(first()).subscribe({
            next: (v) => {
              // this.getQuestion(this.question.id);
              this.onSave();
            },
            error: (e) => {
              console.error(e);
            }
          });
          this.snackBar.open("Catégories enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          console.error(e);
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
        }
      });
    }

    if((addCats.length > 0) && (removeCats.length == 0)){
      this.apiService.linkCategoryToQuestion(this.question.id, newCatIds).pipe(first()).subscribe({
        next: (v) => {
          // this.getQuestion(this.question.id);
          this.onSave();

          this.snackBar.open("Catégories enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          console.error(e);
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
        }
      });
    }

    if((addCats.length == 0) && (removeCats.length > 0)){
      this.apiService.unlinkCategoryToQuestion(this.question.id, removeCats).pipe(first()).subscribe({
        next: (v) => {
          // this.getQuestion(this.question.id);
          this.onSave();

          this.snackBar.open("Catégories enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          console.error(e);
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
        }
      });
    }
    return;
  }

  getQuestion(id: any) {
    this.apiService.getQuestion(id).pipe(first()).subscribe({
      next: (v) => {

        this.question = v.data[0];
        this.title = this.question.title;

        if (this.question.categories.length) {
          this.categoriesList = this.question.categories;
          let catIds = this.question.categories.map((cat: any) => cat.id);
          this.categories.setValue(catIds);
        } else {
          this.categoriesList = [];
          this.categories.setValue([]);
        }

        this.fileSlides = [];
        for (const value of Object.entries(this.question.slide)) {
          const values: any = value[1];
          this.urlToFile(values);
        }
      },
      error: (e) => console.error(e)
    });
  }

  urlToFile(element: any) {
    var blob = null;
    var xhr = new XMLHttpRequest();
    // console.log(element);
    xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.original_url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      var file = new File([blob], element.name, {type: "image/png", lastModified:new Date().getTime()});
      // console.log(file);
      this.fileSlides[0] = file;
    }
    xhr.send();
  }

  onSelectSlide(event: any) {
    let files = event.addedFiles;
    this.fileSlides[0] = files[0];
  }

  onRemoveSlide(){
    this.fileSlides = [];
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteQuestion);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeQuestion(this.question.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Question supprimée", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/contents', 'questions']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue, vérifiez si la question est liée avec des boites", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onSave() {
    const isTitleValid = this.isTitleValid();
    if (!isTitleValid) {
      this.snackBar.open('Le titre ne doit pas contenir de caractères spéciaux', 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    let modifsObject: any = {};

    if (this.title !== this.question.title) modifsObject.title = this.title;
    // if (this.fileSlides !== this.question.slide) modifsObject.slide = this.fileSlides[0];

    if (this.fileSlides.length == 0){
      modifsObject.slide = "delete";
    } else if(this.fileSlides !== this.question.slide){
      modifsObject.slide = this.fileSlides[0];
    }

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editQuestion(this.question.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
          this.getQuestion(this.question.id);
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
      this.getQuestion(this.question.id);
    }

  }

  goBack() {
    this.router.navigate(['/contents', 'questions']);
  }

  isTitleDisabled = false;
  isTitleValid() {
    const regexCaracteresSpeciaux = /[@#$%^&*_+{}[\]:;<>,~\\/]|[\p{Extended_Pictographic}]/u;
    const result = regexCaracteresSpeciaux.test(this.title)
    if (result) {
      this.isTitleDisabled = true;
      return false;
    } else {
      this.isTitleDisabled = false;
      return true;
    }
  }
}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteQuestion {}
