import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { first, map, startWith, switchMap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandsService } from 'src/app/services/brands.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
})
export class BoardComponent implements OnInit {
  isLoading: boolean = true;
  sents: any;
  sentsToShow: any;
  user: any;

  tableData = new MatTableDataSource<any>();
  totalData = 0;

  allSentIds: any;

  displayedColumns: string[] = ['date', 'email', 'box', 'brand', 'info'];

  @ViewChild('paginator') paginator!: MatPaginator;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private apiService: ApiService,
    public brandsService: BrandsService,
    public sanitizer: DomSanitizer
  ) {
    this.brandsService.getBrand().subscribe((res: any) => {
      this.initialization();
    });


  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.initialization();

  }

  initialization() {
    this.isLoading = true;
    // this.listSent();
  }

  ngAfterViewInit() {
    this.tableData.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Eléments par page';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.previousPageLabel = 'Page précédente';

    this.apiService.listSentIds('aucun').subscribe((res) => {
      this.allSentIds = res.data;
      this.allSentIds.sort((a: any, b: any) => {
        return b - a;
      });
      this.totalData = this.allSentIds.length;

      this.paginator.page
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoading = true;
            let ids = this.allSentIds.slice(
              this.paginator.pageIndex * this.paginator.pageSize,
              (this.paginator.pageIndex + 1) * this.paginator.pageSize
            );

            return this.apiService.getSents(ids).pipe(first());
          }),
          map((data) => {
            data.data.sort((a: any, b: any) => {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });

            this.isLoading = false;
            return data.data;
          })
        )
        .subscribe((data) => {
          data.forEach((data: any) => {
            data.created_at = new Date(
              data.created_at.substring(0, 10)
            ).toLocaleDateString('fr');
          });
          this.tableData = new MatTableDataSource(data);
        });
    });
  }

  getCurrentUser() {
    this.apiService
      .getCurrentUser()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.user = v.data;
          this.apiService
            .listActiveSubscription()
            .pipe(first())
            .subscribe({
              next: (v) => {
                if (v.data.length == 0) {
                  this.user.subscription_type_active =
                    this.user.subscription_type;
                  return;
                }

                const dateDisabled = new Date(
                  v.data[v.data.length - 1].disable_date
                );
                const lastPayment = new Date(
                  v.data[v.data.length - 1].next_payment
                );

                if (dateDisabled == null) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (lastPayment != null && new Date() < lastPayment) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (
                  lastPayment != null &&
                  new Date() > lastPayment &&
                  new Date() > dateDisabled
                ) {
                  this.user.subscription_type_active = 'Freemium';
                }
              },
              error: (e) => console.error(e),
            });
        },
        error: (e) => console.error(e),
      });
  }



  view(sent: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/sent/' + sent.id])
    );

    window.open(url, '_blank');

    // this.router.navigate(['/sent/' + sent.id]);
  }

  viewAbonnements() {
    this.router.navigate(['/account', 'abonnement']);
  }

  viewCredits() {
    this.router.navigate(['/account', 'credits']);
  }
}
