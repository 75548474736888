<div *ngIf="form" class="page">
  <div  class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>{{form.title}}</h1>
    <button mat-icon-button class="delete" matTooltip="Supprimer le formulaire" (click)="onDelete()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <mat-card>
    <div class="infos">
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Nom du formulaire</mat-label>
          <input matInput type="text" [(ngModel)]="title">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Titre public</mat-label>
          <input matInput type="text" [(ngModel)]="public_title">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Nom de l'expéditeur</mat-label>
          <input matInput type="text" [(ngModel)]="name_mail">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Envoyer une copie à</mat-label>
          <input matInput type="text" [(ngModel)]="cc_email">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Catégorie</mat-label>
          <mat-select [(ngModel)]="category" class="select" (selectionChange)="onCategorySelect($event.value)">
            <mat-option *ngFor="let cat of allCategories" [value]="cat.id">
              {{cat.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-card>

  <mat-card>
    <div class="content">
      <div class="colLeft">
        <div class="fields">
          <h2>Mes champs ({{form.form_elements.length}})</h2>
          <div cdkDropList class="order-list" (cdkDropListDropped)="drop($event)">
            <div class="fieldsList" *ngFor="let form_element of form.form_elements; let i = index" cdkDrag>        
              <div class="field">
                <span>{{i+1}}.&nbsp;</span>
                <span class="type" *ngIf="form_element.typeText">{{form_element.typeText.text}}</span>
                <mat-icon>arrow_right</mat-icon>{{form_element.title}}
                <div class="actions">
                  <span class="required" *ngIf="form_element.required">*obligatoire</span>
                  <button mat-icon-button class="edit" matTooltip="Editer l'élément" (click)="onEditElement(form_element)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button class="delete" matTooltip="Supprimer l'élément" *ngIf="form_element.type != 'email'" (click)="removeFormElement(form_element)" >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </div>
              <div class="preview" *cdkDragPreview>
                {{form_element.title}}
              </div>
            </div>
          </div>   
        </div>
      </div>
      <div class="colRight">
        <div class="addElement">
          <div class="headElement">
            <h2>Créer un nouveau champ</h2>
          </div>
          <div class="fields">
            <mat-form-field appearance="fill">
              <mat-label>Titre</mat-label>
              <input matInput type="text" placeholder="Titre" [(ngModel)]="titleFormElement">
            </mat-form-field>
  
            <mat-form-field appearance="fill">
              <mat-label>Description</mat-label>
              <textarea matInput type="text" placeholder="Description" [(ngModel)]="description"></textarea>
            </mat-form-field>
  
            <mat-form-field appearance="fill">
              <mat-label>Type</mat-label>
              <mat-select [(ngModel)]="type" (selectionChange)="onTypeSelect($event.value)">
                <mat-option *ngFor="let type of types_array" [value]="type.value">
                  {{type.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field appearance="fill">
              <mat-label>Largeur</mat-label>
              <input matInput type="text" placeholder="Largeur" [(ngModel)]="width">
            </mat-form-field>
  
            <mat-checkbox labelPosition="before" [(ngModel)]="required">Obligatoire</mat-checkbox>
          </div>
          <div class="action">
            <button mat-raised-button class="success" (click)="onSaveFormElement()">
              Créer le champ
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <div class="action">
    <button mat-raised-button class="success" (click)="onSave()">
      Enregistrer
    </button>
  </div>
</div>