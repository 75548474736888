<div *ngIf="box" class="page">
  <div  class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1 >{{box.title}}</h1>
    <div class="buttons">
      <button mat-icon-button class="copy" matTooltip="Copier le code d'intégration" (click)="onCopyCode()">
        <mat-icon>content_copy</mat-icon>
      </button>
      <button mat-icon-button class="delete" matTooltip="Supprimer la boite" (click)="onDelete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div class="content">

    <mat-card>
      <div class="infos">
        <mat-form-field appearance="fill">
          <mat-label>Titre</mat-label>
          <input matInput type="text" [(ngModel)]="title">
        </mat-form-field>
      
        <mat-form-field appearance="fill">
          <mat-label>Catégorie</mat-label>
          <mat-select [(ngModel)]="category" (selectionChange)="onCategorySelect($event.value)">
            <mat-option *ngFor="let cat of allCategories" [value]="cat.id">
              {{cat.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="fill">
          <mat-label>Modèle</mat-label>
          <mat-select [(ngModel)]="model" (selectionChange)="onModelSelect($event.value)">
            <mat-option *ngFor="let mod of allModels" [value]="mod.id">
              {{mod.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="previ">
        <div class="cover">
          <h2>Illustration</h2>
          <h3>
            Ajouter une illustration pour votre boîte
          </h3>
          <div class="rowSlide">
            <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectMedia($event)" *ngIf="fileMedia?.length == 0">
                <mat-icon>cloud_upload</mat-icon>
            </div>
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileMedia" [file]="f" [removable]="true" (removed)="onRemoveMedia()">
            </ngx-dropzone-image-preview>
          </div>
        </div>
        <div class="visu">
          <div class="cover-container" [class.has-cover]="fileMediaUrl">
            <div class="cover">
              <img [src]="fileMediaUrl" alt="" srcset="">
            </div>
            <div class="box-head" *ngIf="interview">
              <div class="type">
                Boîte interview
              </div>
              <div class="icone">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85.536" height="85.536" viewBox="0 0 85.536 85.536">
                  <defs>
                    <linearGradient id="linear-gradient-home" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#0338ee"/>
                      <stop offset="0.126" stop-color="#2338ee"/>
                      <stop offset="0.441" stop-color="#6e38ef"/>
                      <stop offset="0.702" stop-color="#a538ef"/>
                      <stop offset="0.895" stop-color="#c838ef"/>
                      <stop offset="1" stop-color="#d538f0"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="-0.006" y1="0.5" x2="1.006" y2="0.5" xlink:href="#linear-gradient-home"/>
                  </defs>
                  <g id="Groupe_3443" data-name="Groupe 3443" transform="translate(-3725.204 -1070.641)">
                    <g id="Groupe_3442" data-name="Groupe 3442">
                      <rect id="Rectangle_509" data-name="Rectangle 509" width="37.697" height="26.017" transform="translate(3748.595 1104.245)" fill="url(#linear-gradient-home)"/>
                      <rect id="Rectangle_510" data-name="Rectangle 510" width="37.697" height="6.388" transform="translate(3747.103 1098.034) rotate(-13.513)" fill="url(#linear-gradient-home)"/>
                      <path id="Tracé_1781" data-name="Tracé 1781" d="M3764.668,1121.368v-9.081l7.8,4.54Z" fill="#fff"/>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="box-head" *ngIf="!interview">
              <div class="type">
                Boîte flash
              </div>
              <div class="icone">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85.536" height="85.536" viewBox="0 0 85.536 85.536">
                  <defs>
                    <linearGradient id="linear-gradient-home" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#0338ee"/>
                      <stop offset="0.126" stop-color="#2338ee"/>
                      <stop offset="0.441" stop-color="#6e38ef"/>
                      <stop offset="0.702" stop-color="#a538ef"/>
                      <stop offset="0.895" stop-color="#c838ef"/>
                      <stop offset="1" stop-color="#d538f0"/>
                    </linearGradient>
                  </defs>
                  <g id="Groupe_3439" data-name="Groupe 3439" transform="translate(-3309.472 -1070.641)">
                    <g id="Groupe_3438" data-name="Groupe 3438">
                      <path id="Tracé_1779" data-name="Tracé 1779" d="M3366.692,1108.99h-10.6l11.136-26.281h-17.638L3338.9,1116.5h8.868l-8.138,30.919Z" fill="url(#linear-gradient-home)"/>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div class="content">
              <div class="title">
                {{ title }}
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <span *ngIf="box.category">{{ categoryTitle }}</span>
              </div>
              <div class="right">
                C'est parti !
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>


    <mat-card class="questions">
      <div class="flex-content">

        <div class="colLeft">
          <div class="questions">
            <div class="header">
              <h2>Mes questions ({{box.questions.length}})</h2>
              <div class="action">
                <button mat-raised-button class="success" (click)="OnAddQuestionSelected()">Ajouter une question existante</button>
              </div>
            </div>
            <div cdkDropList class="order-list" (cdkDropListDropped)="drop($event)">
              <div class="questionsList" *ngFor="let question of box.questions; let i = index" cdkDrag>        
                <div class="question">
                  <span>{{i+1}}.&nbsp;</span>
                  {{question.title}} <mat-icon *ngIf="question.slide.length">image</mat-icon>
                  <button mat-icon-button class="delete" matTooltip="Supprimer la question" (click)="unLinkQuestion(question)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
                <div class="preview" *cdkDragPreview>
                  {{question.title}}
                </div>
              </div>
            </div>   
          </div>
        </div>
        
        <div class="colRight">
          <!-- <div class="medias mat-elevation-z2">
            <h1>Visualisation des éléments du model</h1>
            <div class="mediasList">
    
              <swiper [slidesPerView]="3" [spaceBetween]="30"[pagination]="{clickable: true}">        
                <ng-template swiperSlide *ngFor="let media of medias | keyvalue">
                  <audio controls *ngIf="media.key == 'music'">
                    <source [src]="media.value" type="audio/mpeg">
                      Audio non supporté par le navigateur
                  </audio>
    
                  <video controls  width="320" *ngIf="media.key == 'intro_desktop' || media.key == 'outro_desktop' || media.key == 'intro_mobile' || media.key == 'outro_mobile'">
                    <source [src]="media.value" type="video/mp4">
                  </video>
    
                  <img src={{media.value}} *ngIf="media.key == 'logo' || media.key == 'top_logo' || media.key == 'middle_logo'">
    
                  <div class="name">{{media.key}}</div>
                </ng-template>
              </swiper>
    
            </div>
          </div> -->

          <div class="addElement">
              <div class="headElement">
                <h2>Ajouter une nouvelle question</h2>
              </div>
              <div class="fields">
                <mat-form-field appearance="fill">
                  <mat-label>Titre</mat-label>
                  <input matInput type="text" placeholder="Titre" [(ngModel)]="titleQuestion">
                </mat-form-field>
    
                <mat-form-field appearance="fill">
                  <mat-label>Catégorie</mat-label>
                  <mat-select multiple [formControl]="categoriesQuestion" (selectionChange)="onCategoriesQuestionSelect()">
                    <mat-option *ngFor="let cat of allCategories" [value]="cat.id">
                      {{cat.title}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
  
              <div class="rowSlide">
                <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectSlide($event)" *ngIf="fileSlides?.length == 0">
                    <mat-icon>cloud_upload</mat-icon>
                </div>
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileSlides" [file]="f" [removable]="true" (removed)="onRemoveSlide()">
                </ngx-dropzone-image-preview>
              </div>
  
              <div class="action">
                <button mat-raised-button class="success" (click)="onAddQuestion()">
                  Ajouter une nouvelle question
                </button>
              </div>
          </div>
        </div>
  
      </div>
    </mat-card>

    <div class="flex-configs">
      <div class="col">
        <div class="configs">
          <mat-card>
            <div class="headElement">
              <h2>Configurations</h2>
            </div>
            <div class="status">
              <mat-form-field appearance="fill">
              <mat-label>Statut</mat-label>
                <mat-select [(ngModel)]="status" (selectionChange)="onStatusSelect($event.value)">
                  <mat-option *ngFor="let element of status_array" [value]="element.value">
                    {{element.text}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div *ngIf="status == 10" class="privateCode">
              <mat-form-field appearance="fill">
                <mat-label>Code privé</mat-label>
                <input matInput maxlength="6" minlength="6" type="text" placeholder="Code" [(ngModel)]="private_code" required>
              </mat-form-field>
            </div>
            <div *ngIf="status == 5" class="privateCode">
              <mat-form-field appearance="fill">
                <mat-label>Code salon</mat-label>
                <input matInput maxlength="4" minlength="4" type="text" placeholder="Code" [(ngModel)]="admin_exhibition_code" pattern="^[0-9]*$" required>
              </mat-form-field>
            </div>
        
            <mat-form-field appearance="fill">
              <mat-label>Type d'ordre des questions</mat-label>
              <mat-select [(ngModel)]="order_type" (selectionChange)="onOrderTypeSelect($event.value)">
                <mat-option *ngFor="let element of order_type_array" [value]="element.value">
                  {{element.text}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <br><br>
            <mat-checkbox [(ngModel)]="rec_on">Activer l’enregistrement</mat-checkbox>
            <br><br>
            <mat-checkbox [(ngModel)]="mail_end">Demander l'email en fin de boîte (sinon, il sera demandé au début)</mat-checkbox>
            <br><br>
            <mat-checkbox [(ngModel)]="audio_bed">Utiliser le fond sonore du modèle</mat-checkbox><a *ngIf="box.model && medias['music']" href="{{medias['music']}}" target="_blank"><mat-icon>play_circle_outline</mat-icon></a>
            <br><br>
            <mat-checkbox [(ngModel)]="top_logo">Utiliser le logo du haut du modèle</mat-checkbox><a *ngIf="box.model && medias['top_logo']" href="{{medias['top_logo']}}" target="_blank"><mat-icon>image</mat-icon></a>
            <br><br>
            <mat-checkbox [(ngModel)]="middle_logo">Utiliser le logo du milieu du modèle</mat-checkbox><a *ngIf="box.model && medias['middle_logo']" href="{{medias['middle_logo']}}" target="_blank"><mat-icon>image</mat-icon></a>
            <br><br>
            <mat-checkbox [(ngModel)]="transition">Utiliser le son de transition du modèle</mat-checkbox><a *ngIf="box.model && medias['transition']" href="{{medias['transition']}}" target="_blank"><mat-icon>play_circle_outline</mat-icon></a>
            <br><br>
            <mat-checkbox [(ngModel)]="interview">Boite de type interview</mat-checkbox>
            <br><br>
            <mat-checkbox [(ngModel)]="mandatory_form">Conditionner l’entrée dans une boite au remplissage d’un formulaire</mat-checkbox>&nbsp;
            <mat-form-field style="width: 30% !important;" appearance="fill" *ngIf="mandatory_form">
              <mat-label>Formulaire</mat-label>
                <mat-select [(ngModel)]="form" (selectionChange)="onFormSelect($event.value)">
                  <mat-option *ngFor="let form of allForms" [value]="form.id">
                    {{form.title}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </mat-card>
        </div>
      </div>

      <div class="col">
        <div class="moreinfo">
          <mat-card>
            <h2>Informations complémentaires sur la boite</h2>
            <editor
            [(ngModel)]="moreinfo"
            ngDefaultControl
            modelEvents="change input nodechange undo redo"
            [apiKey]="tinyMCE"
            [init]="tinyMceInit"
            >
            </editor>
          </mat-card>
        </div>
    
        <div class="waitingText">
          <mat-card>
            <h2>Texte présent sur la page d'attente</h2>
            <editor
            [(ngModel)]="waitingText"
            ngDefaultControl
            modelEvents="change input nodechange undo redo"
            [apiKey]="tinyMCE"
            [init]="tinyMceInit"
            >
            </editor>
          </mat-card>
        </div>
      </div>
    </div>


  </div>


  <div class="action">
    <button mat-raised-button class="success" (click)="onSave()">
      Enregistrer
    </button>
  </div>
</div>