import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})

export class EditCategoryComponent implements OnInit {

  category: any;
  title: any;
  fileMedia: any = [];

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute, public brandsService: BrandsService) {
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
   }

  ngOnInit(): void {
    this.initialization();
  }

  initialization(){
    this.route.params.pipe(first()).subscribe(params => {
      this.getCategory(params['id']);
    });
  }

  getCategory(id: any) {
    this.apiService.listCategories().pipe(first()).subscribe({
      next: (v) => {
        let categories = v.data;

        this.category = categories.find((category: any) => category.id == id);
        this.title = this.category.title;

        if (this.category.imgCategory){
          for (const value of Object.entries(this.category.imgCategory)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileMedia);
          }
        }
      },
      error: (e) => console.error(e)
    });    
  }

  urlToFile(element: any, typeMedia: any) {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.original_url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.name.split('.');
      type = type[type.length - 1];
      let file = new File([blob], element.name, {type: "image/" + type, lastModified:new Date().getTime()});
      typeMedia.push(file);      
    }
    xhr.send();
  }

  onSelectMedia(event: any) {
    let files = event.addedFiles;
    this.fileMedia[0] = files[0];
  }

  onRemoveMedia(){
    this.fileMedia = [];
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteCategory);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeCategory(this.category.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Categorie supprimée", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/contents', 'categories']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue, vérifiez si la catégorie est liée avec des boites", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onSave() {
    let modifsObject: any = {};

    if (this.title !== this.category.title) modifsObject.title = this.title;

    if (this.fileMedia.length == 0){
      modifsObject.imgCategory = "delete";
    } else if(this.fileMedia[0] !== this.category.imgCategory){
      modifsObject.imgCategory = this.fileMedia[0];
    }

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editCategory(this.category.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
    }
    this.router.navigate(['/contents', 'categories']);
  }

  goBack() {
    this.router.navigate(['/contents', 'categories']);
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteCategory {}
