import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl } from '@angular/forms';
import { BrandsService } from 'src/app/services/brands.service';
import { environment } from 'src/environments/environment';
import SwiperCore from 'swiper';

@Component({
  selector: 'app-edit-box',
  templateUrl: './edit-box.component.html',
  styleUrls: ['./edit-box.component.scss']
})

export class EditBoxComponent implements OnInit {

  tinyMCE: string = environment.tinyMCE;

  box: any;
  title: any;
  rec_on : any;
  mail_end : any;
  audio_bed : any;
  top_logo : any;
  middle_logo : any;
  mandatory_form : any;
  transition: any;
  interview : any;
  status: any;
  private_code: any;
  admin_exhibition_code: any;
  status_array = [
    {value: 1, text: 'Brouillon'},
    {value: 2, text: 'Désactivée'},
    {value: 5, text: 'Mode salon'},
    {value: 4, text: 'Publiée'},
    {value: 10, text: 'Privée'}
  ]
  order_type: any;
  order_type_array = [
    {value: 1, text: "Respecter l'ordre"},
    {value: 2, text: 'Ordre aléatoire'},
    {value: 3, text: 'Ordre en conservant la première et la dernière'}
  ]

  moreinfo: any;
  moreinfoTextRefFocus: any;
  waitingText: any;
  waitingTextRefFocus: any;
  tinyMceInit: any;

  allCategories: any;
  allModels: any;
  allForms: any;
  category: any;
  categoryTitle: string = '';
  model: any;
  form: any;
  fileMedia: any = [];

  medias: any = [];

  questions: any = [];

  titleQuestion: any;
  questionId: any;
  newCatIds: any;

  categoriesQuestion = new FormControl();

  fileSlides: any = [];

  fileMediaUrl: any;


  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute, public brandsService: BrandsService) {
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
   }

  ngOnInit(): void {
    this.initialization();
  }

  initialization(){
    this.fileMedia = [];

    this.route.params.pipe(first()).subscribe(params => {
      this.getBox(params['id']);
    });
    this.listCategories();
    this.listModels();
    this.listForms();

    this.tinyMceInit = {
      plugins: 'preview importcss tinydrive searchreplace autolink save directionality visualblocks visualchars fullscreen link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
      toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
      importcss_append: true,
      height: 300,
      contextmenu: false,
      setup: (editor: any) => {
        editor.on('focus', () => {
          this.waitingTextFocus();
        });
        editor.on('blur', () => {
          this.waitingTextFocusOut();
        });
      }
    };
  }

  waitingTextFocus() {
    this.waitingTextRefFocus = this.waitingText;
  }

  waitingTextFocusOut() {
    if (!this.waitingText) {
      this.waitingText = this.waitingTextRefFocus;
      return;
    }
    if (this.waitingText !== this.waitingTextRefFocus) {
    }
  }

  moreinfoTextFocus() {
    this.moreinfoTextRefFocus = this.moreinfo;
  }

  moreinfoTextFocusOut() {
    if (!this.moreinfo) {
      this.moreinfo = this.moreinfoTextRefFocus;
      return;
    }
    if (this.moreinfo !== this.moreinfoTextRefFocus) {
    }
  }

  listCategories() {
    this.apiService.listCategories().pipe(first()).subscribe({
      next: (v) => {
        this.allCategories = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  onCategorySelect(category: any){
    this.category = category;
    console.log(this.category);
    console.log(this.allCategories);
    this.categoryTitle = this.allCategories.find((x: any) => x.id == this.category).title;
  }

  listModels() {
    this.apiService.listModels().pipe(first()).subscribe({
      next: (v) => {
        this.allModels = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  onModelSelect(model: any){
    this.model = model;
  }

  listForms() {
    this.apiService.listForms().pipe(first()).subscribe({
      next: (v) => {
        this.allForms = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  onFormSelect(form: any){
    this.form = form;
  }

  onOrderTypeSelect(order_type: any){
    this.order_type = Number(order_type);
  }

  onStatusSelect(status: any){
    this.status = Number(status);
    if(status == 10){
      this.private_code = Math.floor(100000 + Math.random() * 900000);
      this.admin_exhibition_code = "";
    } else if(status == 5) {
      let code4 = "";
      for (let i = 0; i < 4; i++) {
        code4 += Math.floor(Math.random() * 9);
      }
      this.admin_exhibition_code = Math.floor(1000 + Math.random() * 9000);
      this.private_code = "";
    } else {
      this.private_code = "";
      this.admin_exhibition_code = "";
    }
    
  }

  getBox(id: any) {
    this.apiService.getBox(id).pipe(first()).subscribe({
      next: (v) => {

        this.box = v.data[0];
        this.title = this.box.title;
        if(this.box.model){
          this.model = this.box.model.id;
          this.getModel(this.box.model.id);
        }

        if(this.box.category) {
          this.category = this.box.category.id;
          this.categoryTitle = this.box.category.title;
        }
        if(this.box.form) this.form = this.box.form.id;

        if(this.box.questions){
          this.box.questions.forEach((question: any) => {
            this.questions.push(question.title);
            this.box.box_questions.forEach((q: any) => {
              if(question.id === q.question_id) {
                question['order'] = q.order;
                question['pivotId'] = q.id;
              }
            });
          });
        this.box.questions.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
        } 
        
        this.rec_on = this.box.rec_on;
        this.mail_end = this.box.mail_end;
        this.audio_bed = this.box.audio_bed;
        this.top_logo = this.box.top_logo;
        this.middle_logo = this.box.middle_logo;
        this.mandatory_form = this.box.mandatory_form;
        this.transition = this.box.transition;
        this.interview = this.box.interview;
        this.status = this.box.status;
        this.waitingText = this.box.waiting_text;
        this.moreinfo = this.box.moreinfo;
        if(this.box.private_code) this.private_code = this.box.private_code;
        if(this.box.admin_exhibition_code) this.admin_exhibition_code = this.box.admin_exhibition_code;
        if(this.box.order_type) this.order_type = this.box.order_type;

        if (this.box.cover){
          for (const value of Object.entries(this.box.cover)) {
            const values: any = value[1];
            this.urlToFile(values);
          }
        }
      },
      error: (e) => console.error(e)
    });    
  }

  urlToFile(element: any) {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.original_url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.name.split('.');
      type = type[type.length - 1];
      let file = new File([blob], element.name, {type: "image/" + type, lastModified:new Date().getTime()});
      this.fileMedia[0] = file;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.fileMediaUrl = event.target.result;
      };
      reader.readAsDataURL(file);
    }
    xhr.send();
  }

  getModel(id: any){
    this.apiService.getModel(id).pipe(first()).subscribe({
      next: (v) => {

        let model = v.data[0];

        if (model.logo) {
          for (const value of Object.entries(model.logo)) {
            const values: any = value[1];
            this.medias['logo'] = values.original_url;
          }
        }

        if (model.logo_top_left){
          for (const value of Object.entries(model.logo_top_left)) {
            const values: any = value[1];
            this.medias['top_logo'] = values.original_url;
          }
        }

        if (model.middle_logo) {
          for (const value of Object.entries(model.middle_logo)) {
            const values: any = value[1];
            this.medias['middle_logo'] = values.original_url;
          }
        }

        if (model.intro_desktop) {
          for (const value of Object.entries(model.intro_desktop)) {
            const values: any = value[1];
            this.medias['intro_desktop'] = values.original_url;
          }
        }

        if (model.outro_desktop) {
          for (const value of Object.entries(model.outro_desktop)) {
            const values: any = value[1];
            this.medias['outro_desktop'] = values.original_url;
          }
        }

        if (model.intro_mobile) {
          for (const value of Object.entries(model.intro_mobile)) {
            const values: any = value[1];
            this.medias['intro_mobile'] = values.original_url;
          }
        }

        if (model.outro_mobile) {
          for (const value of Object.entries(model.outro_mobile)) {
            const values: any = value[1];
            this.medias['outro_mobile'] = values.original_url;
          }
        }

        if (model.music) {
          for (const value of Object.entries(model.music)) {
            const values: any = value[1];
            this.medias['music'] = values.original_url;
          }
        }

        if (model.transition) {
          for (const value of Object.entries(model.transition)) {
            const values: any = value[1];
            this.medias['transition'] = values.original_url;
          }
        }
      },
      error: (e) => console.error(e)
    });   
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteBox);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeBox(this.box.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Boite supprimée", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/contents', 'boxes']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onSelectMedia(event: any) {
    let files = event.addedFiles;
    this.fileMedia[0] = files[0];
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.fileMediaUrl = event.target.result;
    };
    reader.readAsDataURL(files[0]);
  }

  onRemoveMedia(){
    this.fileMedia = [];
    this.fileMediaUrl = "";
  }

  onSave() {
    let modifsObject: any = {};
    
    if(this.status !== 1 && typeof this.model === 'undefined'){
      this.snackBar.open("Un modèle est nécessaire pour enregistrer votre boite", "OK", {
        panelClass: ['error-snackbar']
      });
    } else {
      if (this.title !== this.box.title) modifsObject.title = this.title;
      if (this.model !== this.box.model_id) modifsObject.model = this.model;
      if (this.category !== this.box.category_id) modifsObject.category = this.category;
      if (this.form !== this.box.form_id) modifsObject.form = this.form;
      if (this.order_type !== this.box.order_type) modifsObject.order_type = this.order_type;
      if (this.status !== this.box.status) modifsObject.status = this.status;
      if (this.private_code !== this.box.private_code) modifsObject.private_code = this.private_code;
      if (this.admin_exhibition_code !== this.box.admin_exhibition_code) modifsObject.admin_exhibition_code = this.admin_exhibition_code;
      if (this.waitingText !== this.box.waiting_text) modifsObject.waiting_text = this.waitingText;
      if (this.moreinfo !== this.box.moreinfo) modifsObject.moreinfo = this.moreinfo;
  
      if (this.fileMedia.length == 0){
        modifsObject.cover = "delete";
      } else if(this.fileMedia[0] !== this.box.cover){
        modifsObject.cover = this.fileMedia[0];
      }
  
      let rec_on = this.rec_on ? '1' : '0';
      modifsObject.rec_on = rec_on;

      let mail_end = this.mail_end ? '1' : '0';
      modifsObject.mail_end = mail_end;
  
      let audio_bed = this.audio_bed ? '1' : '0';
      modifsObject.audio_bed = audio_bed;
  
      let top_logo = this.top_logo ? '1' : '0';
      modifsObject.top_logo = top_logo;
  
      let middle_logo = this.middle_logo ? '1' : '0';
      modifsObject.middle_logo = middle_logo;
  
      let mandatory_form = this.mandatory_form ? '1' : '0';
      modifsObject.mandatory_form = mandatory_form;
  
      let transition = this.transition ? '1' : '0';
      modifsObject.transition = transition;
  
      let interview = this.interview ? '1' : '0';
      modifsObject.interview = interview;
  
      const hasModifs = Object.keys(modifsObject).length !== 0;
  
      if (hasModifs) {
        this.apiService.editBox(this.box.id, modifsObject).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Modifications enregistrées", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/contents', 'boxes']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      } else {
        this.snackBar.open("Modifications enregistrées", "OK", {
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/contents', 'boxes']);
      }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.box.questions, event.previousIndex, event.currentIndex);
    let reorderArray: any = [];
    this.box.questions.forEach((question: any, index: number) => {
      question.order = index + 1;
      reorderArray.push({'id': question.id, 'order': question.order, 'pivotId': question.pivotId});
    });
      this.apiService.orderQuestion(JSON.stringify(reorderArray)).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Modifications enregistrées", "OK", {
              panelClass: ['success-snackbar']
            });
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
  }

  //ADD QUESTION
  OnAddQuestionSelected(){
    const dialogRef = this.dialog.open(DialogModalAddQuestion, {
      data: {question: ''},
    });
    dialogRef.afterClosed().subscribe(result => {      
      let questionId = result.question;

      if (!questionId) return;

      const match = this.box.questions.find((q: any) => {
        if (q.id == questionId) {
          return true;
        } else {
          return false;
        }
      });
  
      if (match) {
        this.snackBar.open("La question existe déjà sur cete boite ", "OK", {
          panelClass: ['warning-snackbar']
        });
        return;
      }
  
      this.apiService.linkBoxeToQuestion(questionId, this.box.id).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Question ajoutée", "OK", {
            panelClass: ['success-snackbar']
          });
          this.getBox(this.box.id);
        },
        error: (e) => {
          console.error(e);
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
        }
      });
    });

    return;
  }

  onAddQuestion() {
    if(this.titleQuestion){
      this.apiService.addQuestion(this.titleQuestion).pipe(first()).subscribe({
        next: (v) => {
          this.questionId = v.data.id;
          this.onSaveQuestion();
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Il faut renseigner un nom", "OK", {
        panelClass: ['warning-snackbar']
      });
    }
  }

  onSelectSlide(event: any) {
    let files = event.addedFiles;
    this.fileSlides.push(files[0]);
  }

  onRemoveSlide(){
    this.fileSlides = [];
  }

  onCategoriesQuestionSelect() {
    this.newCatIds = [...this.categoriesQuestion.value];
  }

  onSaveQuestion(){
    let modifsObject: any = {};

    if (this.fileSlides) modifsObject.slide = this.fileSlides[0];

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editQuestion(this.questionId, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.apiService.linkBoxeToQuestion(this.questionId, this.box.id).pipe(first()).subscribe({
            next: (v) => {
              if(this.newCatIds && this.newCatIds.length > 0){
                this.apiService.linkCategoryToQuestion(this.questionId, this.newCatIds).pipe(first()).subscribe({
                  next: (v) => {
                    this.getBox(this.box.id);
                    delete(this.titleQuestion);
                    this.fileSlides = [];
                    this.categoriesQuestion = new FormControl();

                  },
                  error: (e) => {
                    console.error(e);
                    this.snackBar.open("Une erreur est survenue", "OK", {
                      panelClass: ['error-snackbar']
                    });
                  }
                });
              } else {
                this.getBox(this.box.id);
              } 
              
            },
            error: (e) => {
              console.error(e);
              this.snackBar.open("Une erreur est survenue", "OK", {
                panelClass: ['error-snackbar']
              });
            }
          });
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
          this.titleQuestion = '';
          this.fileSlides = [];
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
    }    
  }

  //REMOVE QUESTION
  unLinkQuestion(question: any){
    this.apiService.unlinkBoxeToQuestion(question.id, this.box.id).pipe(first()).subscribe({
      next: (v) => {
        this.getBox(this.box.id);
        this.snackBar.open("Modifications enregistrées", "OK", {
          panelClass: ['success-snackbar']
        });
      },
      error: (e) => {
        console.error(e);
        this.snackBar.open("Une erreur est survenue", "OK", {
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  slideGenerated(event: any) {
    this.fileSlides = [];
    this.fileSlides.push(event);
  }

  onCopyCode() {
    navigator.clipboard.writeText("<iframe src='https://questions.place/box/" + this.box.id + "?embed=true' frameborder='0' allow='camera *;microphone *'></iframe>");
    this.snackBar.open("Code d'intégration copié", "OK", {
      panelClass: ['success-snackbar']
    });
  }

  goBack() {
    this.router.navigate(['/contents', 'boxes']);
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteBox {}



export interface DialogData {
  question: string;
}

@Component({
  selector: 'add-existing-question',
  templateUrl: './add-existing-question.html',
})
export class DialogModalAddQuestion implements OnInit {

  questions: any;
  questionsOptions: string[] = [];
  questionsOptionsFiltered: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogModalAddQuestion>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
    this.listAllQuestions();
  }

  listAllQuestions() {
    this.apiService.listQuestions().pipe(first()).subscribe({
      next: (v) => {
        this.questions = v.data;
        this.questions.forEach((question: any) => {
          this.questionsOptions.push(question.title);
          this.questionsOptionsFiltered.push(question.title);
        });
      },
      error: (e) => console.error(e)
    });
  }

  filter() {
    const filterValue = this.data.question.toLowerCase();
    this.questionsOptionsFiltered = this.questionsOptions.filter(
      (option: any) => option.toLowerCase().includes(filterValue)
    );
  }

  ngOnDestroy() {
    let question = this.questions.filter(
      (question: any) => question.title == this.data.question
    );
    if (!question.length) {
      this.data.question = '';
      this.dialogRef.close(this.data);
    } else {
      this.data.question = question[0].id;
      this.dialogRef.close(this.data);
    }
  }

  onNoClick() {
    this.data.question = '';
    this.dialogRef.close();
  }

}
