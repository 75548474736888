import { Component, Inject, Input, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr-FR');

@Component({
  selector: 'app-abonnement',
  templateUrl: './abonnement.component.html',
  styleUrls: ['./abonnement.component.scss'],
})
export class AbonnementComponent implements OnInit {
  @Input() user: any;
  plans: any;
  isLoading: boolean = false;

  paymentPage: boolean = false;
  paymentId: any;
  subPlan: any;
  subPlanId: any;
  activeSubId: string = '';

  showPromo: boolean = true;
  promoCode: string = '';

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private apiService: ApiService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (!this.user) return;
    if (!this.user.id) return;
    this.listPlans();
  }

  listPlans() {
    this.apiService
      .listSubscriptionPlans()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.plans = v.data;
          this.plans.forEach((plan: any) => {
            plan.parameters = JSON.parse(plan.parameters);
            plan.subtitle = plan.description.split('</p>')[0] + '</p>';
            plan.description = plan.description.replace(plan.subtitle, '');

            plan.subtitle = this.sanitizer.bypassSecurityTrustHtml(
              plan.subtitle
            );
            plan.description = this.sanitizer.bypassSecurityTrustHtml(
              plan.description
            );
          });
          this.plans.sort((a: any, b: any) =>
            a.parameters.order > b.parameters.order
              ? 1
              : b.parameters.order > a.parameters.order
              ? -1
              : 0
          );
          this.getActivePlan();
        },
        error: (e) => console.error(e),
      });
  }

  getActivePlan() {
    this.apiService
      .listActiveSubscription()
      .pipe(first())
      .subscribe({
        next: (v) => {
          if (v.data.length == 0) {
            this.user.activeSubPlan = this.plans.find(
              (plan: any) => plan.title == this.user.subscription_type
            );
            if (!this.user.activeSubPlan) {
              this.user.activeSubPlan = this.plans.find(
                (plan: any) => plan.title == 'Freemium'
              );
            }
            if (typeof this.user.activeSubPlan.parameters == 'string') {
              this.user.activeSubPlan.parameters = JSON.parse(
                this.user.activeSubPlan.parameters
              );
            }
            this.user.activeSubPlan.subscription_plan = this.user.activeSubPlan;
          } else {
            const dateDisabled = new Date(
              v.data[v.data.length - 1].disable_date
            );
            const lastPayment = new Date(
              v.data[v.data.length - 1].next_payment
            );

            if (dateDisabled == null) {
              this.user.activeSubPlan = v.data[v.data.length - 1];
              this.user.activeSubPlan.subscription_plan.parameters = JSON.parse(
                this.user.activeSubPlan.subscription_plan.parameters
              );
            } else if (lastPayment != null && new Date() < lastPayment) {
              this.user.activeSubPlan = v.data[v.data.length - 1];
              this.user.activeSubPlan.subscription_plan.parameters = JSON.parse(
                this.user.activeSubPlan.subscription_plan.parameters
              );
            } else if (
              lastPayment != null &&
              new Date() > lastPayment &&
              new Date() > dateDisabled
            ) {
              this.user.endedSubPlan = v.data[v.data.length - 1];
              this.user.activeSubPlan = this.plans.find(
                (plan: any) => plan.title == 'Freemium'
              );

              this.user.activeSubPlan.subscription_plan =
                this.user.activeSubPlan;
            }
          }
        },
        error: (e) => console.error(e),
      });
  }

  subscribe(plan: any) {
    if (plan.subscription_type == 'Pro') {
      window.open('https://www.place-aux-questions.com/contact/', '_blank');
      return;
    }
    this.isLoading = true;
    this.apiService
      .createStancerIntentActiveSubscription(plan.price_ttc, 'eur', {
        auth: true,
      })
      .pipe(first())
      .subscribe({
        next: (v) => {
          console.log(plan);
          this.paymentId = v.data.id;
          this.subPlan = plan;
          this.subPlanId = plan.id;
          this.paymentPage = true;
          this.isLoading = false;

          setTimeout(() => {
            const element = document.querySelector('.beforePayment');
            if (element) element.scrollIntoView({ behavior: 'smooth' });
          }, 200);
        },
        error: (e) => {
          this.snackBar.open('Une erreur est survenue.', 'OK', {
            panelClass: ['error-snackbar'],
          });
          this.isLoading = false;
        },
      });
  }

  cancelSub() {
    const dialogRef = this.dialog.open(DialogCancelSub, {
      data: { title: '' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.isLoading = true;
        this.apiService
          .deleteActiveSubscription(this.user.activeSubPlan.id, result)
          .pipe(first())
          .subscribe({
            next: (v) => {
              this.snackBar.open('Abonnement résilié', 'OK', {
                panelClass: ['success-snackbar'],
              });
              this.listPlans();
              this.getActivePlan();
              this.isLoading = false;
            },
            error: (e) => {
              this.snackBar.open('Une erreur est survenue', 'OK', {
                panelClass: ['error-snackbar'],
              });
              console.error(e);
              this.isLoading = false;
            },
          });
      }
    });
  }

  reSub() {
    this.isLoading = true;
    this.apiService
      .reSub(this.user.activeSubPlan.id)
      .pipe(first())
      .subscribe({
        next: (v) => {
          if (v.success) {
            this.snackBar.open('Abonnement réactivé.', 'OK', {
              panelClass: ['success-snackbar'],
            });
            this.listPlans();
            this.getActivePlan();
            this.isLoading = false;
          }
        },
        error: (e) => {
          this.snackBar.open('Une erreur est survenue.', 'OK', {
            panelClass: ['error-snackbar'],
          });
          this.isLoading = false;
        },
      });
  }

  changeCard() {
    const dialogRef = this.dialog.open(DialogConfirmChangeCard);
    dialogRef.afterClosed().subscribe((validation) => {
      if (validation) {
        this.isLoading = true;
        this.apiService
          .createStancerIntentActiveSubscription(
            this.user.activeSubPlan.subscription_plan.price_ttc,
            'eur',
            { auth: true }
          )
          .pipe(first())
          .subscribe({
            next: (v) => {
              this.paymentId = v.data.id;
              this.activeSubId = this.user.activeSubPlan.id;
              this.paymentPage = true;
              this.isLoading = false;
            },
            error: (e) => {
              this.snackBar.open('Une erreur est survenue.', 'OK', {
                panelClass: ['error-snackbar'],
              });
              this.isLoading = false;
            },
          });
      }
    });
  }

  usePromoCode() {
    if (!this.promoCode) {
      return;
    }

    this.isLoading = true;
    this.apiService
      .listConfigs()
      .pipe(first())
      .subscribe({
        next: (v) => {
          let promoFound = v.data.find(
            (el: any) => el.title == 'promo_' + this.promoCode
          );
          if (promoFound) {
            this.activatePromo(promoFound);
          } else {
            this.snackBar.open('Code non valide.', 'OK', {
              panelClass: ['error-snackbar'],
            });
            this.isLoading = false;
          }
        },
        error: (e) => {
          this.snackBar.open('Une erreur est survenue.', 'OK', {
            panelClass: ['error-snackbar'],
          });
          this.isLoading = false;
        },
      });
  }

  activatePromo(promo: any) {
    this.apiService
      .activatePromo(promo.value)
      .pipe(first())
      .subscribe({
        next: (v) => {
          if (v.success) {
            this.snackBar.open('Abonnement promo activé', 'OK', {
              panelClass: ['success-snackbar'],
            });
            this.listPlans();
            this.getActivePlan();
            this.isLoading = false;
          } else {
            this.snackBar.open(v.message, 'OK', {
              panelClass: ['error-snackbar'],
            });
            this.isLoading = false;
          }
        },
        error: (e) => {
          this.snackBar.open(e.message, 'OK', {
            panelClass: ['error-snackbar'],
          });
          this.isLoading = false;
        },
      });
  }
}

export interface DialogData {
  title: string;
}

@Component({
  selector: 'resiliation',
  templateUrl: './resiliation.html',
})
export class DialogCancelSub implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogCancelSub>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  onNoClick() {
    this.data.title = '';
    this.dialogRef.close();
  }
}

@Component({
  selector: 'confirm-change',
  templateUrl: './confirm-change.html',
})
export class DialogConfirmChangeCard {}
