import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-single-sent',
  templateUrl: './single-sent.component.html',
  styleUrls: ['./single-sent.component.scss']
})

export class SingleSentComponent implements OnInit {

  sent: any;
  form_entries: any;
  date: any;
  hours: any;
  vimeoId: any;
  donwloadLink: any;
  list_interviews: any = [];

  constructor(private apiService: ApiService, private route: ActivatedRoute, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.getSent(params['id']);
    });
  }

  getSent(id: any) {
    this.apiService.allSents().pipe(first()).subscribe({
      next: (v) => {
        let sents = v.data;
        this.sent = sents.find((sent: any) => sent.id == id);
        console.log(this.sent);
        this.sent.box_url = window.location.origin + '/boxes/edit-box/setup/' + this.sent.box_id;
        this.sent.front_url = "https://questions.place/box/" + this.sent.box_id;
        this.date = new Date(this.sent.created_at.substring(0, 10)).toLocaleDateString("fr");
        this.hours = this.sent.created_at.substring(11, 16);

        let vimeo = this.sent.vimeo_url.split('/');
        this.vimeoId = vimeo[3];
        let url = "https://player.vimeo.com/video/" + this.vimeoId + "?h=" + vimeo[4];
        this.sent.vimeo_player = this.sanitizer.bypassSecurityTrustResourceUrl(url);        

        if(this.sent.list_videos_interview){
          let itws = this.sent.list_videos_interview;
          itws = itws.split(',');
          let list_vimeo_url: any[] = [];
          let list_videos_interviews: any[] = [];
          itws.forEach((itw: any) => {

            let vimeo = itw.replace(/\\/g, '');
            vimeo = vimeo.replace(/"/g, '');
            vimeo = vimeo.replace(/]/g, '');
            vimeo = vimeo.replace(/\[/g, '');
            list_vimeo_url.push(vimeo);
            
            let truncate = itw.split('/');
            let id = truncate[3].replace(/\\/g, '');
            let ref = truncate[4].replace(/\\/g, '');
            ref = ref.replace(/"/g, '');
            ref = ref.replace(/]/g, '');

            let url = "https://player.vimeo.com/video/" + id + "?h=" + ref;
            let urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            list_videos_interviews.push(urlSafe);

            this.list_interviews = list_vimeo_url.map(function (x, i) { 
                return [x, list_videos_interviews[i]] 
            });
          });
        };

        if(this.sent.form_response_id != null){
          this.form_elements(this.sent.form_response_id);
        };
        
      },
      error: (e) => console.error(e)
    });
  }

  form_elements(form_response_id: any){
    this.apiService.getFormEntries(form_response_id).pipe(first()).subscribe({
      next: (v) => {
        this.form_entries = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  download_video(){
    this.apiService.download(this.vimeoId).pipe(first()).subscribe({
      next: (v) => {
          let datas = v.data;

          let video = datas.find((data: {rendition: string; quality: string; }) => data.quality == 'hd' && data.rendition == '1080p');

          if(!video){
            video = datas.find((data: {rendition: string; quality: string; }) => data.quality == 'hd' && data.rendition == '720p');
          }
          if(!video){
            video = datas.find((data: {rendition: string; quality: string; }) => data.quality == 'sd' && data.rendition == '540p');
          }
          if(!video){
            video = datas.find((data: {rendition: string; quality: string; }) => data.quality == 'sd' && data.rendition == '360p');
          }
          if(!video){
            video = datas.find((data: {rendition: string; quality: string; }) => data.quality == 'sd' && data.rendition == '240p');
          }

          window.location.href = video['link'];
      },
      error: (e) => console.error(e)
    });
  }

}
