import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';
import { first } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {

  @Input() box: any;

  boxUrl: string = '';
  iframeContent: string = '';
  inviteText: string = '';
  contacts: any = [];

  isLoading: boolean = false;

  user: any = {firstname: '', lastname: ''};

  @Output() changeStep = new EventEmitter<string>();

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, private apiService: ApiService, public brandsService: BrandsService) {
  }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.getBox(params['id']);
      this.getCurrentUser();
    });
  }

  getBox(id: any) {
    this.apiService.getBox(id).pipe(first()).subscribe({
      next: (v) => {
        this.box = v.data[0];
        if (this.box.questions && this.box.questions.length) {
          this.box.questions.forEach((question: any) => {
            if (this.box.encode_type == 3 && !question.is_video) {
              this.box.has_problem = true;
            }
          });
        }
        this.boxUrl = `https://www.questions.place/box/${this.box.id}`;
        this.iframeContent = `<iframe src='https://questions.place/box/${this.box.id}?embed=true' frameborder='0' allow='camera *;microphone *'></iframe>`;
      },
      error: (e) => console.error(e)
    });
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().pipe(first()).subscribe({
      next: (v) => {
        // this.user = v.data[0]
  this.user = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  goBack() {
    this.router.navigate(['/boxes/edit-box/config-montage/' + this.box.id]);
  }

  seeOnline() {
    window.open(this.boxUrl, '_blank');
  }

  onCopyCode() {
    navigator.clipboard.writeText(this.iframeContent);
    this.snackBar.open("Code d'intégration copié", "OK", {
      panelClass: ['success-snackbar']
    });
  }

  onCopyLink() {
    navigator.clipboard.writeText(this.boxUrl);
    this.snackBar.open("Lien copié", "OK", {
      panelClass: ['success-snackbar']
    });
  }


  onAddContact() {
    const dialogRef = this.dialog.open(DialogAddContact, {
      data: {title: ''},
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (
          result.toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.contacts.push(result);
        } else {
          this.snackBar.open("Adresse non valide", "OK", {
            panelClass: ['error-snackbar']
          });
        }
      }
    });
  }

  onRemoveContact(contactName: any) {
    this.contacts = this.contacts.filter((contact: any) => contact !== contactName);
  }

  sendInvites() {
    if (!this.contacts.length) {
      this.snackBar.open("Aucun contact renseigné", "OK", {
        panelClass: ['error-snackbar']
      });
      return;
    }

    if (!this.inviteText.length) {
      this.snackBar.open("Veuillez saisir un texte d'invitation", "OK", {
        panelClass: ['error-snackbar']
      });
      return;
    }

    this.isLoading = true;
    this.apiService.addBoxInvitation(this.box.id, this.contacts, this.inviteText, this.boxUrl).pipe(first()).subscribe({
      next: (v) => {
        if (v.success) {
          this.snackBar.open("Invitation envoyée", "OK", {
            panelClass: ['success-snackbar']
          });
          this.contacts = [];
          this.inviteText = '';
          this.isLoading = false;
        }
      },
      error: (e) => {
        this.snackBar.open("Une erreur est survenue", "OK", {
          panelClass: ['error-snackbar']
        });
        this.isLoading = false;
        console.error(e);
      }
    });

  }

}

export interface DialogData {
  title: string;
}

@Component({
  selector: 'add-contact',
  templateUrl: './add-contact.html',
})
export class DialogAddContact implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogAddContact>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }

  onNoClick() {
    this.data.title = '';
    this.dialogRef.close();
  }

}