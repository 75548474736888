<mat-card *ngIf="credits && credits.length">
  <h2>Utilisation des crédits de votre abonnement</h2>
  <div class="achats">
    <div class="achat" *ngFor="let credit of credits">
      <div class="title">
        {{ credit.credits }} crédit<span *ngIf="credit.credits > 1">s</span>
      </div>
      <div class="date">
        {{ credit.created_at }}
      </div>
      <div class="sent" (click)="viewSent(credit.sent_id)">
        Voir la vidéo
      </div>
    </div>
  </div>
</mat-card>

<mat-card *ngIf="purchasesSub && purchasesSub.length">
  <h2>Vos achats d'abonnement</h2>
  <div class="achats">
    <div class="achat" *ngFor="let purchase of purchasesSub">
      <div class="title">
        Abonnement {{ purchase.active_subscription.subscription_type }}
      </div>
      <div class="date">
        {{ purchase.created_at }}
      </div>
      <div class="price">
        {{ purchase.price_ttc / 100 }} €
      </div>
    </div>
  </div>
</mat-card>

<mat-card *ngIf="purchases && purchases.length">
  <h2>Vos achats de crédits</h2>
  <div class="achats">
    <div class="achat" *ngFor="let purchase of purchases">
      <div class="title">
        {{ purchase.credits }} crédits
      </div>
      <div class="date">
        {{ purchase.created_at }}
      </div>
      <div class="price">
        {{ purchase.price_ttc / 100 }} €
      </div>
    </div>
  </div>
</mat-card>

<mat-card *ngIf="!purchases || !purchases.length">
  Aucun achat de crédits
  <br><br>
  <a (click)="navigateCredits()">Créditer mon compte</a>
</mat-card>