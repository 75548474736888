import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';
import { CompressImageConfig } from 'ngx-dropzone-compressing/lib/ngx-dropzone.service';

@Component({
  selector: 'app-config-montage',
  templateUrl: './config-montage.component.html',
  styleUrls: ['./config-montage.component.scss']
})
export class ConfigMontageComponent implements OnInit {

  isLoading: any = false;
  isLoadingNext: any = false;

  box: any;
  model: any;

  questionVideo: any;
  questionTxt: any;
  questionImg: any;
  formatPortrait: any;
  formatPaysage: any;
  formatIdentique: any;
  answersOnly: any;
  seperateFiles: any;
  allFormats: any;

  fileTopLogo: any = [];
  fileTopLogoDeleted: boolean = false;
  fileMiddleLogo: any = [];
  fileMiddleLogoDeleted: boolean = false;
  fileIntroDesktop: any = [];
  fileIntroDesktopDeleted: boolean = false;
  fileOutroDesktop: any = [];
  fileOutroDesktopDeleted: boolean = false;
  fileIntroMobile: any = [];
  fileIntroMobileDeleted: boolean = false;
  fileOutroMobile: any = [];
  fileOutroMobileDeleted: boolean = false;
  fileMusic: any = [];
  fileMusicDeleted: boolean = false;
  musicUrl: any;

  countLoads: number = 0;

  user: any;

  compressImageConfig1: CompressImageConfig = {
    orientation: -1,
    ratio: 100,
    quality: 100,
    maxWidth: 250,
    maxHeight: 102,
  };

  compressImageConfig2: CompressImageConfig = {
    orientation: -1,
    ratio: 100,
    quality: 100,
    maxWidth: 500,
    maxHeight: 205,
  };

  constructor(public sanitizer: DomSanitizer, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, public brandsService: BrandsService) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.route.params.pipe(first()).subscribe(params => {
      this.getBox(params['id']);
    });
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().pipe(first()).subscribe({
      next: (v) => {
        // this.user = v.data[0];
        this.user = v.data;
        this.apiService.listSubscriptionPlans().pipe(first()).subscribe({
          next: (v) => {
            this.user.subPlan = v.data.find((plan: any) => plan.title == this.user.subscription_type);
            if (!this.user.subPlan) {
              this.user.subPlan = v.data.find((plan: any) => plan.title == 'Freemium');
            }
            this.user.subPlan.parameters = JSON.parse(this.user.subPlan.parameters);
            if (this.user.is_admin) {
              this.user.subPlan.parameters = {
                box_type_1: 2,
                box_type_2: 2,
                box_type_3: 2,
                mount_bed: 2,
                mount_intro: 2,
                mount_logo_first: 2,
                mount_logo_second: 2,
                mount_type_result: 2,
                mount_type_show_questions: 2,
                nav_categories_list: 2,
                nav_form_list: 2,
                nav_modeles_list: 2,
                nav_questions_list: 2,
                order: 3,
                question_limit: 0,
                rec_box_background: 2,
                rec_box_btn_color: 2,
                rec_box_description: 2,
                rec_box_general: 2,
                rec_box_illustration: 2,
                rec_box_logo: 2,
                rec_box_transition_sound: 2,
                rec_box_waiting_text: 2,
              }
            }
          },
          error: (e) => console.error(e)
        });
      },
      error: (e) => console.error(e)
    });
  }

  getBox(id: any) {
    this.apiService.getBox(id).pipe(first()).subscribe({
      next: (v) => {
        this.box = v.data[0];
        if (this.box.questions && this.box.questions.length) {
          this.box.questions.forEach((question: any) => {
            if (this.box.encode_type == 3 && !question.is_video) {
              this.box.has_problem = true;
            }
          });
        }
        this.setupModel();
        this.setupFormats();
      },
      error: (e) => console.error(e)
    });
  }

  goBack() {
    this.router.navigate(['/boxes/edit-box/config-record/' + this.box.id]);
  }

  setupFormats() {
    if (this.box.parameters === null) {
      this.box.parameters = {};
    } else {
      this.box.parameters = JSON.parse(this.box.parameters);
    }

    if (this.box.parameters.formatSortie) {
      this.questionVideo = this.box.parameters.formatSortie.questionVideo;
      this.questionTxt = this.box.parameters.formatSortie.questionTxt;
      this.questionImg = this.box.parameters.formatSortie.questionImg;
      this.formatPortrait = this.box.parameters.formatSortie.formatPortrait;
      this.formatPaysage = this.box.parameters.formatSortie.formatPaysage;
      this.formatIdentique = this.box.parameters.formatSortie.formatIdentique;
      this.answersOnly = this.box.parameters.formatSortie.answersOnly;
      this.seperateFiles = this.box.parameters.formatSortie.seperateFiles;
    } else {
      if (this.box.encode_type == 3) {
        this.questionVideo = true;
      } else {
        this.questionVideo = false;
      }
      if (this.box.encode_type == 2) {
        this.questionImg = true;
      } else {
        this.questionImg = false;
      }
      if (this.box.encode_type == 1) {
        this.questionTxt = true;
      } else {
        this.questionTxt = false;
      }
      this.formatPortrait = false;
      this.formatPaysage = false;
      this.formatIdentique = true;
      this.answersOnly = false;
      this.seperateFiles = false;
    }
  }

  selectQuestionVideo() {
    if (this.user.subPlan.parameters.mount_type_show_questions !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    setTimeout(() => {
      this.questionTxt = false;
      this.questionImg = false;
      this.questionVideo = true;
    }, 10);
  }

  selectQuestionTxt() {
    if (this.user.subPlan.parameters.mount_type_show_questions !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    setTimeout(() => {
      this.questionVideo = false;
      this.questionImg = false;
      this.questionTxt = true;
    }, 10);
  }

  selectQuestionImg() {
    if (this.user.subPlan.parameters.mount_type_show_questions !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    setTimeout(() => {
      this.questionTxt = false;
      this.questionVideo = false;
      this.questionImg = true;
    }, 10);
  }

  selectAllFormats() {
    setTimeout(() => {
      if (this.allFormats) {
        this.formatPortrait = true;
        this.formatPaysage = true;
        this.formatIdentique = true;
        this.answersOnly = true;
        this.seperateFiles = true;
      }
    }, 10);
  }

  selectPortrait() {
    if (this.user.subPlan.parameters.mount_type_result !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    setTimeout(() => {
      this.formatPortrait = true;
      this.formatPaysage = false;
      this.formatIdentique = false;
    }, 10);
  }

  selectPaysage() {
    if (this.user.subPlan.parameters.mount_type_result !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    setTimeout(() => {
      this.formatPortrait = false;
      this.formatPaysage = true;
      this.formatIdentique = false;
    }, 10);
  }

  selectIdentique() {
    if (this.user.subPlan.parameters.mount_type_result !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    setTimeout(() => {
      this.formatPortrait = false;
      this.formatPaysage = false;
      this.formatIdentique = true;
    }, 10);
  }

  selectAnswersOnly() {
    setTimeout(() => {
      if (!this.answersOnly) {
        this.allFormats = false;
      }
    }, 10);
  }

  selectSeperateFiles() {
    setTimeout(() => {
      if (!this.seperateFiles) {
        this.allFormats = false;
      }
    }, 10);
  }

  setupModel() {
    this.fileTopLogo = [];
    this.fileMiddleLogo = [];
    this.fileIntroDesktop = [];
    this.fileOutroDesktop = [];
    this.fileIntroMobile = [];
    this.fileOutroMobile = [];
    this.fileMusic = [];

    this.model = this.box.model;

    if (this.model.logo_top_left){
      for (const value of Object.entries(this.model.logo_top_left)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileTopLogo);
      }
    }

    if (this.model.middle_logo) {
      for (const value of Object.entries(this.model.middle_logo)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileMiddleLogo);
      }
    }

    if (this.model.intro_desktop) {
      for (const value of Object.entries(this.model.intro_desktop)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileIntroDesktop);
      }
    }

    if (this.model.outro_desktop) {
      for (const value of Object.entries(this.model.outro_desktop)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileOutroDesktop);
      }
    }

    if (this.model.intro_mobile) {
      for (const value of Object.entries(this.model.intro_mobile)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileIntroMobile);
      }
    }

    if (this.model.outro_mobile) {
      for (const value of Object.entries(this.model.outro_mobile)) {
        const values: any = value[1];
        this.urlToFile(values, this.fileOutroMobile);
      }
    }

    if (this.model.music) {
      for (const value of Object.entries(this.model.music)) {
        const values: any = value[1];
        this.musicUrl = values.original_url;
        this.urlToFile(values, this.fileMusic);
      }
    }
  }

  urlToFile(element: any, typeMedia: any) {
    this.isLoading = true;
    this.countLoads = this.countLoads + 1;
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.original_url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.name.split('.');
      type = type[type.length - 1];
      if(type === 'mp3'){
        var file = new File([blob], element.name, {type: "audio/" + type, lastModified:new Date().getTime()});
        typeMedia.push(file);
      } else {
        var file = new File([blob], element.name, {type: "image/" + type, lastModified:new Date().getTime()});
        typeMedia.push(file);
      }
      this.countLoads = this.countLoads - 1;
      if (this.countLoads == 0) {
        this.isLoading = false;
      }
    }
    xhr.send();
  }

  onSelectTopLogo(event: any) {
    if (this.user.subPlan.parameters.mount_logo_first !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    let files = event.addedFiles;
    console.log(files[0]);
    this.fileTopLogo[0] = files[0];
  }

  onRemoveTopLogo(){
    this.fileTopLogo = [];
    this.fileTopLogoDeleted = true;
  }

  onSelectMiddleLogo(event: any) {
    if (this.user.subPlan.parameters.mount_logo_second !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    let files = event.addedFiles;
    this.fileMiddleLogo[0] = files[0];
    console.log(files[0]);

  }

  onRemoveMiddleLogo(){
    this.fileMiddleLogo = [];
    this.fileMiddleLogoDeleted = true;
  }

  onSelectIntroDesktop(event: any) {
    if (this.user.subPlan.parameters.mount_intro !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    let files = event.addedFiles;
    this.fileIntroDesktop[0] = files[0];
  }

  onRemoveIntroDesktop(){
    this.fileIntroDesktop = [];
    this.fileIntroDesktopDeleted = true;
  }

  onSelectOutroDesktop(event: any) {
    if (this.user.subPlan.parameters.mount_intro !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    let files = event.addedFiles;
    this.fileOutroDesktop[0] = files[0];
  }

  onRemoveOutroDesktop(){
    this.fileOutroDesktop = [];
    this.fileOutroDesktopDeleted = true;
  }

  onSelectIntroMobile(event: any) {
    if (this.user.subPlan.parameters.mount_intro !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    let files = event.addedFiles;
    this.fileIntroMobile[0] = files[0];
  }

  onRemoveIntroMobile(){
    this.fileIntroMobile = [];
    this.fileIntroMobileDeleted = true;
  }

  onSelectOutroMobile(event: any) {
    if (this.user.subPlan.parameters.mount_intro !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    let files = event.addedFiles;
    this.fileOutroMobile[0] = files[0];
  }

  onRemoveOutroMobile(){
    this.fileOutroMobile = [];
    this.fileOutroMobileDeleted = true;
  }

  onSelectMusic(event: any) {
    if (this.user.subPlan.parameters.mount_bed !== 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", "OK", {
        panelClass: ['error-snackbar']
      });
      return
    }
    let files = event.addedFiles;
    this.fileMusic[0] = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.musicUrl = (<FileReader>event.target).result;
      this.musicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.musicUrl);
    }
  }

  onRemoveMusic(){
    this.fileMusic = [];
    this.musicUrl = "";
    this.fileMusicDeleted = true;
  }

  next() {
    this.isLoadingNext = true;

    if (!this.box.private_code) {
      this.box.private_code = Math.floor(100000 + Math.random() * 900000);
    }

    let top_logo = '1';
    let middle_logo = '1';
    let audio_bed = '1';

    let modifsObject: any = {};

    if (this.fileTopLogo.length == 0) {
      top_logo = '0';
      if (this.fileTopLogoDeleted) {
        modifsObject.logo_top_left = 'delete';
      }
    } else {
      top_logo = '1';
      if (this.model.logo_top_left.length && ((this.fileTopLogo[0].name !== this.model.logo_top_left[0].name) && (this.fileTopLogo[0].size !== this.model.logo_top_left[0].size))){
        modifsObject.logo_top_left = this.fileTopLogo[0];
      } else if (!this.model.logo_top_left.length) {
        modifsObject.logo_top_left = this.fileTopLogo[0];
      }
    }

    if (this.fileMiddleLogo.length == 0){
      middle_logo = '0';
      if (this.fileMiddleLogoDeleted) {
        modifsObject.middle_logo = 'delete';
      }
    } else {
      middle_logo = '1';
      if (this.model.middle_logo.length && ((this.fileMiddleLogo[0].name !== this.model.middle_logo[0].name) && (this.fileMiddleLogo[0].size !== this.model.middle_logo[0].size))){
        modifsObject.middle_logo = this.fileMiddleLogo[0];
      } else if (!this.model.middle_logo.length) {
        modifsObject.middle_logo = this.fileMiddleLogo[0];
      }
    }

    if (this.fileIntroDesktop.length == 0 && this.fileIntroDesktopDeleted){
      modifsObject.intro_desktop = "delete";
    } else if (this.model.intro_desktop.length && this.fileIntroDesktop.length && ((this.fileIntroDesktop[0].name !== this.model.intro_desktop[0].name) && (this.fileIntroDesktop[0].size !== this.model.intro_desktop[0].size))){
      modifsObject.intro_desktop = this.fileIntroDesktop[0];
    } else if (!this.model.intro_desktop.length && this.fileIntroDesktop.length) {
      modifsObject.intro_desktop = this.fileIntroDesktop[0];
    }


    if (this.fileOutroDesktop.length == 0 && this.fileOutroDesktopDeleted){
      modifsObject.outro_desktop = "delete";
    } else if (this.model.outro_desktop.length && this.fileOutroDesktop.length && ((this.fileOutroDesktop[0].name !== this.model.outro_desktop[0].name) && (this.fileOutroDesktop[0].size !== this.model.outro_desktop[0].size))){
      modifsObject.outro_desktop = this.fileOutroDesktop[0];
    } else if (!this.model.outro_desktop.length && this.fileOutroDesktop.length) {
      modifsObject.outro_desktop = this.fileOutroDesktop[0];
    }

    if (this.fileIntroMobile.length == 0 && this.fileIntroMobileDeleted){
      modifsObject.intro_mobile = "delete";
    } else if (this.model.intro_mobile.length && this.fileIntroMobile.length && ((this.fileIntroMobile[0].name !== this.model.intro_mobile[0].name) && (this.fileIntroMobile[0].size !== this.model.intro_mobile[0].size))){
      modifsObject.intro_mobile = this.fileIntroMobile[0];
    } else if (!this.model.intro_mobile.length && this.fileIntroMobile.length) {
      modifsObject.intro_mobile = this.fileIntroMobile[0];
    }

    if (this.fileOutroMobile.length == 0 && this.fileOutroMobileDeleted){
      modifsObject.outro_mobile = "delete";
    } else if (this.model.outro_mobile.length && this.fileOutroMobile.length && ((this.fileOutroMobile[0].name !== this.model.outro_mobile[0].name) && (this.fileOutroMobile[0].size !== this.model.outro_mobile[0].size))){
      modifsObject.outro_mobile = this.fileOutroMobile[0];
    } else if (!this.model.outro_mobile.length && this.fileOutroMobile.length) {
      modifsObject.outro_mobile = this.fileOutroMobile[0];
    }

    if (this.fileMusic.length == 0){
      audio_bed = '0';
      if (this.fileMusicDeleted) {
        modifsObject.music = 'delete';
      }
    } else {
      audio_bed = '1';
      if (this.model.music.length && ((this.fileMusic[0].name !== this.model.music[0].name) && (this.fileMusic[0].size !== this.model.music[0].size))){
        modifsObject.music = this.fileMusic[0];
      } else if (!this.model.music.length) {
        modifsObject.music = this.fileMusic[0];
      }
    }


    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (this.box.encode_type == 1) {
      this.questionImg = false;
      this.questionVideo = false;
      this.questionTxt = true;
    }

    if (this.box.encode_type == 2 && !this.questionImg && !this.questionTxt) {
      this.questionImg = true;
      this.questionVideo = false;
      this.questionTxt = false;
    }

    if (this.box.encode_type == 3 && !this.questionImg && !this.questionTxt && !this.questionVideo) {
      this.questionImg = false;
      this.questionVideo = true;
      this.questionTxt = false;
    }

    if (!this.formatPortrait && !this.formatPaysage && !this.formatIdentique) {
      this.formatIdentique = true;
    }

    let parameters = this.box.parameters;
    parameters.formatSortie = {
      questionVideo: this.questionVideo,
      questionTxt: this.questionTxt,
      questionImg: this.questionImg,
      formatPortrait: this.formatPortrait,
      formatPaysage: this.formatPaysage,
      formatIdentique: this.formatIdentique,
      answersOnly: this.answersOnly,
      seperateFiles: this.seperateFiles,
      allFormats: this.allFormats,
    };

    parameters.freemium = 1;

    const modifsBox = {
      status: this.box.status || 10,
      private_code: this.box.private_code,
      parameters: JSON.stringify(parameters),
      top_logo: top_logo,
      middle_logo: middle_logo,
      audio_bed: audio_bed
    }

    console.log(modifsObject);
    if (hasModifs) {
      this.apiService.editModel(this.model.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.apiService.editBox(this.box.id, modifsBox).pipe(first()).subscribe({
            next: (v) => {
              this.isLoadingNext = false;
              this.router.navigate(['/boxes/edit-box/validation/' + this.box.id]);
            },
            error: (e) => {
              this.isLoadingNext = false;
              console.error(e);
            }
          });
        },
        error: (e) => {
          this.isLoadingNext = false;
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.apiService.editBox(this.box.id, modifsBox).pipe(first()).subscribe({
        next: (v) => {
          this.isLoadingNext = false;
          this.router.navigate(['/boxes/edit-box/validation/' + this.box.id]);
        },
        error: (e) => {
          this.isLoadingNext = false;
          console.error(e);
        }
      });
    }

  }

  // seePlans() {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/account/abonnement'])
  //   );
  //   window.open(url, '_blank');
  // }

  seePlans(active_type?: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/account/abonnement'])
    );
    if (!active_type) window.open(url, '_blank');
    else {
      this.apiService.sendClickInfo(active_type).subscribe({
        next: (v) => {
          window.open(url, '_blank');
        },
        error: (e) => console.error(e.message),
      });
    }
  }
}
