import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { LoginEventService } from 'src/app/services/login-event.service';
import { AuthService } from '../auth.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.scss']
})
export class UserValidationComponent implements OnInit {

  isLoading: boolean = true;

  constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router, private authService: AuthService, public loginEvent: LoginEventService, private apiService: ApiService) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.activateUser(params['token'], params['id']);
    });
  }

  activateUser(token: any, id: any) {
    this.apiService.activateUser(id, token).pipe(first()).subscribe({
      next: (v) => {
        if (v.success) {
          this.snackBar.open('Compte activé avec succès', "OK", {
            panelClass: ['success-snackbar']
          });
          this.isLoading = false;
          this.router.navigate(['/login']);
        }
      },
      error: (e) => {
        this.snackBar.open('Une erreur est survenue', "OK", {
          panelClass: ['error-snackbar']
        });
        this.isLoading = false;
      }
    });
  }

}
