<mat-progress-bar
  mode="indeterminate"
  color="primary"
  *ngIf="isLoading"
></mat-progress-bar>
<div class="page">
  <div class="head">
    <h1>Mes vidéos</h1>
    <mat-form-field appearance="fill">
      <mat-label>Rechercher par boite</mat-label>
      <mat-select
        type="text"
        placeholder="Rechercher par boite"
        (selectionChange)="filterBoxes($event.value)"
      >
        <mat-option
          *ngFor="let option of boxesOptionsFiltered"
          [value]="option.id"
        >
          {{ option.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="content">
    <mat-tab-group mat-stretch-tabs="true" style="padding-bottom: 64px;">
      <mat-tab label="Vue globale">
        <div class="video-list">
          <mat-card *ngFor="let sent of sentsToShowGlobal">
            <section class="header">
              <section class="header-start">
                {{ sent.box.title }}
              </section>
              <section class="header-end" (click)="view(sent)">
                <mat-icon>info_outline</mat-icon>
              </section>
            </section>
            <section class="container-body">
              <iframe
                [src]="sent.vimeo_url"
                width="320"
                height="240"
                frameborder="0"
                webkitallowfullscreen=""
                mozallowfullscreen=""
                allowfullscreen=""
              ></iframe>
            </section>
            <section class="footer">
              <div class="id"><b>ID :</b> {{ sent.id }}</div>
              <div class="date"><b>Date :</b> {{ sent.created_at }}</div>
              <div class="email"><b>Contact :</b> {{ sent.email }}</div>
            </section>
          </mat-card>
          <div class="pages">
            <button
              mat-icon-button
              matTooltip="Page précédente"
              *ngIf="startIndexGlobal > 0"
              (click)="previousPageGlobal()"
            >
              <mat-icon>arrow_back</mat-icon>
            </button>
            <button
              mat-icon-button
              matTooltip="Page suivante"
              *ngIf="canNext"
              (click)="nextPageGlobal()"
            >
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Vue tableau">
        <div class="infos-list">
          <div class="table-container mat-elevation-z2">
            <table mat-table [dataSource]="list">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let element">{{ element.id }}</td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.created_at }}
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>

              <ng-container matColumnDef="info" stickyEnd>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <button
                    mat-icon-button
                    class="info"
                    matTooltip="Voir le détail"
                    (click)="view(element)"
                  >
                    <mat-icon>info_outline</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Aucun résultat</td>
              </tr>
            </table>

            <mat-paginator
              [pageSizeOptions]="[5, 10, 20, 100]"
              [pageSize]="20"
            ></mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="no-video" *ngIf="sentIds === 'none' && !isLoading">
      Vous n'avez pas encore de vidéos, c'est le moment !
      <button mat-raised-button color="success" (click)="viewBoxes()">
        Voir mes boîtes à questions
      </button>
    </div>
  </div>
</div>
