<div *ngIf="form" class="page">
  <div class="head">
    <h1>Entrée du formulaire N°{{form.id}} {{form.title}}</h1>
    <button mat-raised-button class="success" (click)="export()">
      Exporter les entrées
    </button>
  </div>


  <div class="content">
    <div class="table-container mat-elevation-z2">
      <table mat-table [dataSource]="elements">

        <ng-container *ngFor="let disCol of displayedColumns" matColumnDef="{{disCol}}">
          <th mat-header-cell *matHeaderCellDef>{{disCol}}</th>
          <td mat-cell *matCellDef="let element">{{element[disCol]}}</td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Aucun résultat</td>
        </tr>
    
      </table>
    
      <mat-paginator [pageSizeOptions]="[5, 10, 20, 100]" [pageSize]="20"></mat-paginator>
    </div>
  </div>
</div>

