<div class="questionProblem" *ngIf="box && box.has_problem">
  Attention, votre boîte contient des questions qui ne sont pas correctement configurées. Veuillez <a [routerLink]="'/boxes/edit-box/scenario/'+ box.id">les modifier</a>
</div>

<mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
<div class="page" *ngIf="box">
  <div class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>Votre boîte est prête !</h1>
    <div class="empty"></div>
  </div>

  <div class="content">

    <div class="flex-col">
      <mat-card class="congrats">
        <div class="title">
          Bravo {{user.firstname}} {{user.lastname}} !
        </div>
        <div class="text">
          Votre scénario de questions est prêt à être diffusé, vous pouvez désormais inviter tous les contacts desquels vous aimeriez demander un témoignage en vidéo.
        </div>
      </mat-card>

      <mat-card class="share-top">
        <div class="action">
          <button mat-raised-button class="success" (click)="seeOnline()">
            Voir ma boîte
          </button>
          <div class="icon-copy">
            <button mat-raised-button aria-label="Copier" matTooltip="Copier l'url de ma boîte" (click)="onCopyLink()">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </div>
        <div class="iframe">
          <div class="copy" (click)="onCopyCode()">
            Intégrer la boîte sur mon site internet
          </div>
          <div class="visu">
            <div class="text">
              {{ iframeContent }}
            </div>
            <div class="icon-copy">
              <button mat-icon-button aria-label="Copier" class="blue" matTooltip="Copier le code" (click)="onCopyCode()">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>




    <mat-card class="share">
      <div class="title">
        Partagez votre boîte !
      </div>

      <div class="text">
        Vous pouvez envoyez un e-mail pour inviter vos contacts à enregistrer votre "Boîte à Questions".
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Texte d'invitation à enregistrer votre "Boîte à Questions"</mat-label>
        <textarea matInput [(ngModel)]="inviteText"></textarea>
      </mat-form-field>

      <div class="contacts">
        <div class="title">
          <div class="text">
            Vos contacts à qui envoyer l'invitation
          </div>
          <button mat-icon-button aria-label="Ajouter" class="success" matTooltip="Ajouter un contact" (click)="onAddContact()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
        <div class="list">
          <mat-card class="contact" *ngFor="let contact of contacts">
            <div class="email">
              {{ contact }}
            </div>
            <div class="remove">
              <button mat-icon-button aria-label="Supprimer" class="error" matTooltip="Enlever le contact de la liste" (click)="onRemoveContact(contact)">
                <mat-icon>backspace</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="action">
        <button mat-raised-button class="success" (click)="sendInvites()" [class.disabled]="!contacts.length">
          Envoyer l'invitation
        </button>
      </div>
    </mat-card>

    <mat-card class="code">
      Vos visiteurs peuvent également accéder à votre boîte en utilisant le code <span>{{ box.private_code }}</span> dans l'espace "contenu privé" de l'application
    </mat-card>
  </div>
</div>