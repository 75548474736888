import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-slide-generator',
  templateUrl: './slide-generator.component.html',
  styleUrls: ['./slide-generator.component.scss'],
})
export class SlideGeneratorComponent implements OnInit {
  canvas: any;
  canvasCtx: any;

  @Input() text: string = '';
  @Input() encodeType: any;
  @Input() config: any;
  fontSize: number = 62;
  fontFamily: string = 'Helvetica';
  fontWeight: any = 400;
  lineHeight: any = 30;

  bgColor: string = '#FEFEFE';
  bgColor2: string = '#FEFEFE';
  fontColor: string = '#1D1D1D';

  logo: any;
  logoWidth: any = 100;
  logoOffset: any = 100;

  fonts: any = [
    'Helvetica',
    'Alexandria',
    'Arial',
    'Verdana',
    'Lato',
    'Open Sans',
    'Roboto',
    'Oswald',
    'Nerko One',
  ];

  options: any = [
    { dir: '→', coor: [] },
    { dir: '←', coor: [] },
    { dir: '↓', coor: [] },
    { dir: '↑', coor: [] },
    { dir: '↗', coor: [] },
    { dir: '↙', coor: [] },
    { dir: '↖', coor: [] },
    { dir: '↘', coor: [] },
  ];

  direction = { dir: '→', coor: [0, 0, 1365, 0] };

  fileSlides: any = [];
  isDegradee: boolean = false;

  @Output() slideGenerated = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    if (this.encodeType == 1) this.bgColor = '#FEFEFE';
    this.canvas = document.getElementById('canvas');
    this.canvasCtx = this.canvas.getContext('2d');

    if (this.config) {
      this.config = JSON.parse(this.config);

      localStorage.setItem('canvasFontSize', String(this.config.canvasFontSize));
      localStorage.setItem('canvasFontFamily', this.config.canvasFontFamily);
      localStorage.setItem('canvasFontWeight', this.config.canvasFontWeight);
      localStorage.setItem('canvasLineHeight', this.config.canvasLineHeight);
      localStorage.setItem('canvasBgColor', this.config.canvasBgColor);
      localStorage.setItem('canvasFontColor', this.config.canvasFontColor);
      if (this.config.canvasBgColor2) {
        this.isDegradee = true;
        localStorage.setItem('canvasBgColor2', this.config.canvasBgColor2);
      } else {
        this.isDegradee = false;
        localStorage.removeItem('canvasBgColor2');
      }
      if (this.config.canvasDirection) {
        this.isDegradee = true;
        localStorage.setItem('canvasDirection', JSON.stringify(this.config.canvasDirection));
      } else {
        this.isDegradee = false;
        localStorage.removeItem('canvasDirection');
      }      
    }

    if (localStorage.getItem('canvasFontSize'))
      this.fontSize = Number(localStorage.getItem('canvasFontSize'));
    if (localStorage.getItem('canvasFontFamily'))
      this.fontFamily = String(localStorage.getItem('canvasFontFamily'));
    if (localStorage.getItem('canvasFontWeight'))
      this.fontWeight = String(localStorage.getItem('canvasFontWeight'));
    if (localStorage.getItem('canvasLineHeight'))
      this.lineHeight = String(localStorage.getItem('canvasLineHeight'));
    if (localStorage.getItem('canvasBgColor') && this.encodeType != 1)
      this.bgColor = String(localStorage.getItem('canvasBgColor'));
    if (localStorage.getItem('canvasBgColor2') && this.encodeType != 1)
      this.bgColor2 = String(localStorage.getItem('canvasBgColor2'));
    if (localStorage.getItem('canvasDirection') && this.encodeType != 1) {
      this.isDegradee = true;
      let temp = String(localStorage.getItem('canvasDirection'));
      this.direction = JSON.parse(temp);
    }
    if (localStorage.getItem('canvasFontColor'))
      this.fontColor = String(localStorage.getItem('canvasFontColor'));
    setTimeout(() => {
      this.shrinkFontSize();
      this.enlargeFontSize();
      setTimeout(() => {
        this.updateCanvas();
      }, 200);
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName == 'text' && this.canvas) {
        this.updateCanvas();
      }
    }
  }

  updateCanvas() {
    this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.canvasCtx.rect(0, 0, 1365, 1024);

    this.options = [
      { dir: '→', coor: [0, 0, this.canvas.width, 0] },
      { dir: '←', coor: [this.canvas.width, 0, 0, 0] },
      { dir: '↓', coor: [0, 0, 0, this.canvas.height] },
      { dir: '↑', coor: [0, this.canvas.height, 0, 0] },
      { dir: '↗', coor: [0, this.canvas.height, this.canvas.width, 0] },
      { dir: '↙', coor: [this.canvas.width, 0, 0, this.canvas.height] },
      { dir: '↖', coor: [this.canvas.width, this.canvas.height, 0, 0] },
      { dir: '↘', coor: [0, 0, this.canvas.width, this.canvas.height] },
    ];

    if (this.isDegradee) {
      let option = this.options.find((o: any) => o.dir == this.direction.dir);
      let gradient = this.canvasCtx.createLinearGradient(
        option.coor[0],
        option.coor[1],
        option.coor[2],
        option.coor[3]
      );
      gradient.addColorStop(0, this.bgColor);
      gradient.addColorStop(1, this.bgColor2);
      this.canvasCtx.fillStyle = gradient;
    } else {
      this.canvasCtx.fillStyle = this.bgColor;
    }

    this.canvasCtx.fill();

    this.canvasCtx.font = `${this.fontWeight} ${this.fontSize}px ${this.fontFamily}`;

    this.canvasCtx.fillStyle = this.fontColor;
    this.canvasCtx.textAlign = 'center';

    var x = this.canvas.width / 2;
    var y = this.canvas.height / 2 - 20;
    var maxWidth = this.canvas.width - 100;

    if (this.logo) {
      var ratio = this.logo.naturalWidth / this.logo.naturalHeight;
      var w = this.logoWidth;
      var h = w / ratio;
      this.canvasCtx.drawImage(this.logo, this.logoOffset, 20, w, h);
    }

    this.wrapText(this.canvasCtx, this.text, x, y, maxWidth, this.lineHeight);
  }

  wrapText(
    context: any,
    text: any,
    x: any,
    y: any,
    maxWidth: any,
    lineHeight: any
  ) {
    // var words = text.split(' ');
    // var line = '';

    // for (var n = 0; n < words.length; n++) {
    //   var testLine = line + words[n] + ' ';
    //   var metrics = context.measureText(testLine);
    //   var testWidth = metrics.width;
    //   if (testWidth > maxWidth && n > 0) {
    //     context.fillText(line, x, y);
    //     line = words[n] + ' ';
    //     y += lineHeight;
    //   } else {
    //     line = testLine;
    //   }
    // }
    // context.fillText(line, x, y);

    var words = text.split(' ');
    var line = '';
    var lines = [];

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + ' ';
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line);

    var totalHeight = lines.length * lineHeight;
    var startY = (this.canvas.height - totalHeight) / 2;
    for (var i = 0; i < lines.length; i++) {
      context.fillText(lines[i], x, startY + (i + 1) * lineHeight);
    }
  }

  enlargeFontSize() {
    this.fontSize = this.fontSize + 2;
    this.lineHeight = this.lineHeight + 2;
    this.updateCanvas();
  }

  shrinkFontSize() {
    this.fontSize = this.fontSize - 2;
    this.lineHeight = this.lineHeight - 2;
    this.updateCanvas();
  }

  onFontSizeChange(event: any) {
    this.fontSize = event.value;
    this.lineHeight = this.fontSize + 8;
    this.updateCanvas();
  }

  updateLogo(e: any) {
    var URL = window.URL;
    var url = URL.createObjectURL(e.target.files[0]);
    var logo = new Image();
    logo.src = url;
    this.logo = logo;
    logo.onload = () => {
      this.updateCanvas();
    };
  }

  enlargeLogo() {
    this.logoWidth = this.logoWidth + 10;
    this.logoOffset = this.logoOffset - 5;
    this.updateCanvas();
  }

  shrinkLogo() {
    this.logoWidth = this.logoWidth - 10;
    this.logoOffset = this.logoOffset + 5;
    this.updateCanvas();
  }

  onChangeFont(event: any) {
    let selectedFont = event.value;
    this.fontFamily = selectedFont;
    this.updateCanvas();
    setTimeout(() => {
      this.fontFamily = selectedFont;
      this.updateCanvas();
    }, 100);
  }

  onChangeFontWeight(event: any) {
    let selectedWeight = event.value;
    this.fontWeight = selectedWeight;
    this.updateCanvas();
    setTimeout(() => {
      this.fontWeight = selectedWeight;
      this.updateCanvas();
    }, 100);
  }

  downloadCanvas() {
    // let imageBase64 = this.canvas.toDataURL();
    // var a = document.createElement("a");
    // a.href = imageBase64;
    // a.download = `generated-slide.png`;
    // a.click();

    this.canvas.toBlob((blob: any) => {
      let file = new File([blob], 'generated-slide.jpg', {
        type: 'image/jpeg',
      });
      if (this.isDegradee) {
        this.slideGenerated.emit({
          file: file,
          color: this.bgColor,
          color2: this.bgColor2,
          direction: this.direction,
          fontSize: this.fontSize,
          fontFamily: this.fontFamily,
          fontWeight: this.fontWeight,
          lineHeight: this.lineHeight,
          fontColor: this.fontColor,
        });
      } else {
        this.slideGenerated.emit({
          file: file,
          color: this.bgColor,
          fontSize: this.fontSize,
          fontFamily: this.fontFamily,
          fontWeight: this.fontWeight,
          lineHeight: this.lineHeight,
          fontColor: this.fontColor,
        });
      }
    }, 'image/jpeg');

    localStorage.setItem('canvasFontSize', String(this.fontSize));
    localStorage.setItem('canvasFontFamily', this.fontFamily);
    localStorage.setItem('canvasFontWeight', this.fontWeight);
    localStorage.setItem('canvasLineHeight', this.lineHeight);
    localStorage.setItem('canvasBgColor', this.bgColor);
    if (this.isDegradee) {
      localStorage.setItem('canvasBgColor2', this.bgColor2);
      localStorage.setItem('canvasDirection', JSON.stringify(this.direction));
    } else {
      localStorage.removeItem('canvasBgColor2');
      localStorage.removeItem('canvasDirection');
    }

    localStorage.setItem('canvasFontColor', this.fontColor);
  }
}
