<div *ngIf="product" class="page">
  <div  class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>{{product.title}}</h1>
    <div class="buttons">
      <button mat-icon-button class="delete" matTooltip="Supprimer le produit" (click)="onDelete()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div class="content">

    <mat-card>

      <div class="cover">
        <h2>Importer une image</h2>
        <div class="rowSlide">
          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectMedia($event)" *ngIf="media?.length == 0">
              <mat-icon>cloud_upload</mat-icon>
          </div>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of media" [file]="f" [removable]="true" (removed)="onRemoveMedia()">
          </ngx-dropzone-image-preview>
        </div>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>Titre</mat-label>
        <input matInput type="text" [(ngModel)]="title">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <input matInput type="text" [(ngModel)]="description">
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-50">
        <mat-label>Prix HT (en centimes)</mat-label>
        <input matInput type="text" [(ngModel)]="price_ht">
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-50">
        <mat-label>Prix TTC (en centimes)</mat-label>
        <input matInput type="text" [(ngModel)]="price_ttc">
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-50">
        <mat-label>Taxe (en %)</mat-label>
        <input matInput type="text" [(ngModel)]="taxe">
      </mat-form-field>

      <mat-form-field appearance="fill" class="w-50">
        <mat-label>Nombre de crédits attribués</mat-label>
        <input matInput type="text" [(ngModel)]="credits">
      </mat-form-field>

      <mat-checkbox [(ngModel)]="visibility">Produit visible</mat-checkbox>

      <div class="action">
        <button mat-raised-button class="success" (click)="onSave()">
          Enregistrer
        </button>
      </div>

    </mat-card>

  </div>




</div>