import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrandsService } from 'src/app/services/brands.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html',
  styleUrls: ['./sent.component.scss'],
})
export class SentComponent implements OnInit {
  sents: any = [];
  sentsToShow: any;
  sentsToShowGlobal: any;

  boxes: any;
  boxesOptionsFiltered: any[] = [];

  isLoading: boolean = false;

  list: any;
  displayedColumns: string[] = ['id', 'date', 'email', 'info'];

  sentIds: any;
  startIndexGlobal: number = 0;
  endIndexGlobal: number = 10;
  canNext: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private apiService: ApiService,
    public brandsService: BrandsService,
    public sanitizer: DomSanitizer
  ) {
    this.brandsService.getBrand().subscribe((res: any) => {
      this.initialization();
    });
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.isLoading = true;
    this.boxesOptionsFiltered = [];

    this.sents = [];
    this.sentIds = [];

    this.listBoxes();
    this.listSentIds();
  }

  listBoxes() {
    this.apiService
      .listBoxes()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.boxes = v.data;

          this.boxes.forEach((box: any) => {
            this.boxesOptionsFiltered.push(box);
          });
        },
        error: (e) => console.error(e),
      });
  }

  filterBoxes(box: string) {
    if (typeof box != 'string') {
      this.sentsToShow = this.sents.filter(
        (sent: { box_id: any }) => sent.box_id === box
      );
    } else if (typeof box === 'string') {
      this.sentsToShow = this.sents;
    }

    this.list = new MatTableDataSource(this.sentsToShow);
    this.list.paginator = this.paginator;

    this.paginator._intl.itemsPerPageLabel = 'Résultats par page';
    this.paginator._intl.nextPageLabel = 'Page suivante';
    this.paginator._intl.previousPageLabel = 'Page précédente';
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 à ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }

  listSentIds() {
    this.apiService
      .listSentIds()
      .pipe(first())
      .subscribe({
        next: (v) => {
          let datas = v.data;
          this.sentIds = datas;
          if (this.sentIds.length && this.sentIds != 'none') {
            this.getSents();
          } else {
            this.sentIds = 'none';
            this.isLoading = false;
          }
        },
        error: (e) => console.error(e),
      });
  }

  getSents() {
    let chunk = this.sentIds.splice(0, 10);
    if (!chunk.length) {
      this.isLoading = false;
      return;
    }
    this.apiService
      .getSents(chunk)
      .pipe(first())
      .subscribe({
        next: (v) => {
          let datas = v.data.reverse();

          datas.forEach((data: any) => {
            data.created_at = new Date(
              data.created_at.substring(0, 10)
            ).toLocaleDateString('fr');

            let vimeoId = data.vimeo_url.split('/');
            let url =
              'https://player.vimeo.com/video/' +
              vimeoId[3] +
              '?h=' +
              vimeoId[4];
            data.vimeo_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
          });

          this.sents = this.sents.concat(datas);
          this.sentsToShow = this.sents;
          this.sentsToShowGlobal = this.sents.slice(
            this.startIndexGlobal,
            this.endIndexGlobal
          );

          if (this.sentsToShow.length > 10) {
            this.canNext = true;
          }

          this.list = new MatTableDataSource(this.sentsToShow);
          this.list.paginator = this.paginator;

          this.paginator._intl.itemsPerPageLabel = 'Résultats par page';
          this.paginator._intl.nextPageLabel = 'Page suivante';
          this.paginator._intl.previousPageLabel = 'Page précédente';
          this.paginator._intl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number
          ) => {
            if (length === 0 || pageSize === 0) {
              return `0 à ${length}`;
            }
            length = Math.max(length, 0);
            const startIndex = page * pageSize;
            const endIndex =
              startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
            return `${startIndex + 1} - ${endIndex} sur ${length}`;
          };

          this.getSents();
        },
        error: (e) => console.error(e),
      });
  }

  view(sent: any) {
    this.router.navigate(['/sent/' + sent.id]);
  }

  viewBoxes() {
    this.router.navigate(['/contents/']);
  }

  nextPageGlobal() {
    this.startIndexGlobal = this.startIndexGlobal + 10;
    this.endIndexGlobal = this.endIndexGlobal + 10;
    this.sentsToShowGlobal = this.sents.slice(
      this.startIndexGlobal,
      this.endIndexGlobal
    );
    if (this.endIndexGlobal >= this.sents.length) {
      this.canNext = false;
    } else {
      this.canNext = true;
    }
  }

  previousPageGlobal() {
    this.startIndexGlobal = this.startIndexGlobal - 10;
    this.endIndexGlobal = this.endIndexGlobal - 10;
    this.sentsToShowGlobal = this.sents.slice(
      this.startIndexGlobal,
      this.endIndexGlobal
    );
    this.canNext = true;
  }
}
