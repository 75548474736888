import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-config',
  templateUrl: './edit-config.component.html',
  styleUrls: ['./edit-config.component.scss']
})

export class EditConfigComponent implements OnInit {

  config: any;
  title: any;
  value: any;

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.getConfig(params['id']);
    });
  }

  getConfig(id: any) {
    this.apiService.listConfigs().pipe(first()).subscribe({
      next: (v) => {
        let configs = v.data;

        this.config = configs.find((config: any) => config.id == id);
        this.title = this.config.title;
        this.value = this.config.value;
      },
      error: (e) => console.error(e)
    });    
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteConfig);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeConfig(this.config.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Configuration supprimée", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/configs']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue, vérifiez si la configuration", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onSave() {
    let modifsObject: any = {};

    if (this.title !== this.config.title) modifsObject.title = this.title;
    if (this.value !== this.config.value) modifsObject.value = this.value;

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editConfig(this.config.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
    }
    this.router.navigate(['/configs']);
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteConfig {}
