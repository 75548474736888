<mat-dialog-content>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Prénom</mat-label>
    <input matInput [(ngModel)]="data.firstname">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Nom</mat-label>
    <input matInput [(ngModel)]="data.lastname">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="data.email">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Mot de passe</mat-label>
    <input matInput [(ngModel)]="data.password">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button mat-button class="success" [mat-dialog-close]="data.firstname" cdkFocusInitial>Valider</button>
</mat-dialog-actions>