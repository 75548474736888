import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-edit-model',
  templateUrl: './edit-model.component.html',
  styleUrls: ['./edit-model.component.scss']
})

export class EditModelComponent implements OnInit {

  model: any;
  title: any;

  fileLogo: any = [];
  fileTopLogo: any = [];
  fileMiddleLogo: any = [];
  fileIntroDesktop: any = [];
  fileOutroDesktop: any = [];
  fileIntroMobile: any = [];
  fileOutroMobile: any = [];
  fileMusic: any = [];
  musicUrl: any;
  fileTransition: any = [];
  transitionUrl: any;
  fileBackgroundRecImage: any = [];

  background_next_btn : any;
  color_next_btn : any;

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute, public sanitizer: DomSanitizer, public brandsService: BrandsService) { 
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization(){
    this.route.params.pipe(first()).subscribe(params => {
      this.getModel(params['id']);
    });
  }

  getModel(id: any) {
    this.fileLogo = [];
    this.fileTopLogo = [];
    this.fileMiddleLogo = [];
    this.fileIntroDesktop = [];
    this.fileOutroDesktop = [];
    this.fileIntroMobile = [];
    this.fileOutroMobile = [];
    this.fileMusic = [];
    this.fileTransition = [];
    this.fileBackgroundRecImage = [];

    this.apiService.getModel(id).pipe(first()).subscribe({
      next: (v) => {
        let model = v.data[0];
        this.model = model;
        
        this.title = model.title;

        if (this.model.background_next_btn ) {
          this.background_next_btn  = this.model.background_next_btn ;
        }

        if (this.model.color_next_btn ) {
          this.color_next_btn  = this.model.color_next_btn ;
        }

        if (this.model.logo) {
          for (const value of Object.entries(this.model.logo)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileLogo);
          }
        }

        if (this.model.logo_top_left){
          for (const value of Object.entries(this.model.logo_top_left)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileTopLogo);
          }
        }

        if (this.model.middle_logo) {
          for (const value of Object.entries(this.model.middle_logo)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileMiddleLogo);
          }
        }

        if (this.model.intro_desktop) {
          for (const value of Object.entries(this.model.intro_desktop)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileIntroDesktop);
          }
        }

        if (this.model.outro_desktop) {
          for (const value of Object.entries(this.model.outro_desktop)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileOutroDesktop);
          }
        }

        if (this.model.intro_mobile) {
          for (const value of Object.entries(this.model.intro_mobile)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileIntroMobile);
          }
        }

        if (this.model.outro_mobile) {
          for (const value of Object.entries(this.model.outro_mobile)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileOutroMobile);
          }
        }

        if (this.model.music) {
          for (const value of Object.entries(this.model.music)) {
            const values: any = value[1];
            this.musicUrl = values.original_url;
            this.urlToFile(values, this.fileMusic);
          }
        }

        if (this.model.transition) {
          for (const value of Object.entries(this.model.transition)) {
            const values: any = value[1];
            this.transitionUrl = values.original_url;
            this.urlToFile(values, this.fileTransition);
          }
        }

        if (this.model.background_rec_image) {
          for (const value of Object.entries(this.model.background_rec_image)) {
            const values: any = value[1];
            this.urlToFile(values, this.fileBackgroundRecImage);
          }
        }
      },
      error: (e) => console.error(e)
    });    
  }

  urlToFile(element: any, typeMedia: any) {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", "https://cors.belooga-inc.ca/raw?url=" + element.original_url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      blob = xhr.response;
      let type = element.name.split('.');
      type = type[type.length - 1];
      if(type === 'mp3'){
        var file = new File([blob], element.name, {type: "audio/" + type, lastModified:new Date().getTime()});
        typeMedia.push(file);
      } else {
        var file = new File([blob], element.name, {type: "image/" + type, lastModified:new Date().getTime()});
        typeMedia.push(file);
      }
      
    }
    xhr.send();
  }

  onSelectLogo(event: any) {
    let files = event.addedFiles;
    this.fileLogo[0] = files[0];
  }

  onRemoveLogo(){
    this.fileLogo = [];
  }

  onSelectTopLogo(event: any) {
    let files = event.addedFiles;
    this.fileTopLogo[0] = files[0];
  }

  onRemoveTopLogo(){
    this.fileTopLogo = [];
  }

  onSelectMiddleLogo(event: any) {
    let files = event.addedFiles;
    this.fileMiddleLogo[0] = files[0];
  }

  onRemoveMiddleLogo(){
    this.fileMiddleLogo = [];
  }

  onSelectIntroDesktop(event: any) {
    let files = event.addedFiles;
    this.fileIntroDesktop[0] = files[0];
  }

  onRemoveIntroDesktop(){
    this.fileIntroDesktop = [];
  }

  onSelectOutroDesktop(event: any) {
    let files = event.addedFiles;
    this.fileOutroDesktop[0] = files[0];
  }

  onRemoveOutroDesktop(){
    this.fileOutroDesktop = [];
  }

  onSelectIntroMobile(event: any) {
    let files = event.addedFiles;
    this.fileIntroMobile[0] = files[0];
  }

  onRemoveIntroMobile(){
    this.fileIntroMobile = [];
  }

  onSelectOutroMobile(event: any) {
    let files = event.addedFiles;
    this.fileOutroMobile[0] = files[0];
  }

  onRemoveOutroMobile(){
    this.fileOutroMobile = [];
  }

  onSelectMusic(event: any) {
    let files = event.addedFiles;
    this.fileMusic[0] = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);    
    reader.onload = (event) => {
      this.musicUrl = (<FileReader>event.target).result;
      this.musicUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.musicUrl);
    }
  }

  onRemoveMusic(){
    this.fileMusic = [];
    this.musicUrl = "";
  }

  onSelectTransition(event: any) {
    let files = event.addedFiles;
    this.fileTransition[0] = files[0];

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);    
    reader.onload = (event) => {
      this.transitionUrl = (<FileReader>event.target).result;
      this.transitionUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.transitionUrl);
    }
  }

  onRemoveTransition(){
    this.fileTransition = [];
    this.transitionUrl = "";
  }

  onSelectBackgroundRecImage(event: any) {
    let files = event.addedFiles;
    this.fileBackgroundRecImage[0] = files[0];
  }

  onRemoveBackgroundRecImage(){
    this.fileBackgroundRecImage = [];
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteModel);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeModel(this.model.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Modèle supprimé", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/contents', 'models']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue, vérifiez si la catégorie est liée avec des boites", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onSave() {
    let modifsObject: any = {};

    if (this.title !== this.model.title) modifsObject.title = this.title;
    if (this.background_next_btn !== this.model.background_next_btn) modifsObject.background_next_btn = this.background_next_btn;
    if (this.color_next_btn !== this.model.color_next_btn) modifsObject.color_next_btn = this.color_next_btn;

    if (this.fileLogo.length == 0){
      modifsObject.logo = "delete";
    } else if(this.fileLogo[0] !== this.model.logo){
      modifsObject.logo = this.fileLogo[0];
    }

    if (this.fileTopLogo.length == 0) {
      modifsObject.logo_top_left = "delete";
    } else if(this.fileTopLogo[0] !== this.model.logo_top_left){
      modifsObject.logo_top_left = this.fileTopLogo[0];
    }

    if (this.fileMiddleLogo.length == 0){
      modifsObject.middle_logo = "delete";
    } else if(this.fileMiddleLogo[0] !== this.model.middle_logo){
      modifsObject.middle_logo = this.fileMiddleLogo[0];
    }

    if (this.fileIntroDesktop.length == 0){
      modifsObject.intro_desktop = "delete";
    } else if(this.fileIntroDesktop[0] !== this.model.intro_desktop){
      modifsObject.intro_desktop = this.fileIntroDesktop[0];
    }

    if (this.fileOutroDesktop.length == 0){
      modifsObject.outro_desktop = "delete";
    } else if(this.fileOutroDesktop[0] !== this.model.outro_desktop){
      modifsObject.outro_desktop = this.fileOutroDesktop[0];
    }

    if (this.fileIntroMobile.length == 0){
      modifsObject.intro_mobile = "delete";
    } else if(this.fileIntroMobile[0] !== this.model.intro_mobile){
      modifsObject.intro_mobile = this.fileIntroMobile[0];
    }

    if (this.fileOutroMobile.length == 0){
      modifsObject.outro_mobile = "delete";
    } else if(this.fileOutroMobile[0] !== this.model.outro_mobile){
      modifsObject.outro_mobile = this.fileOutroMobile[0];
    }

    if (this.fileMusic.length == 0){
      modifsObject.music = "delete";
    } else if(this.fileMusic[0] !== this.model.music){
      modifsObject.music = this.fileMusic[0];
    }

    if (this.fileTransition.length == 0){
      modifsObject.transition = "delete";
    } else if(this.fileTransition[0] !== this.model.transition){
      modifsObject.transition = this.fileTransition[0];
    }

    if (this.fileBackgroundRecImage.length == 0){
      modifsObject.background_rec_image = "delete";
    } else if(this.fileBackgroundRecImage[0] !== this.model.background_rec_image){
      modifsObject.background_rec_image = this.fileBackgroundRecImage[0];
    }

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editModel(this.model.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          console.log(v);
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
    }
    setTimeout(() => {
      this.router.navigate(['/contents', 'models']);
    }, 2000);
    
  }

  goBack() {
    this.router.navigate(['/contents', 'models']);
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteModel {}
