import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-order-brands',
  templateUrl: './order-brands.component.html',
  styleUrls: ['./order-brands.component.scss']
})

export class OrderBrandsComponent implements OnInit {

  brands: any;

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.listBrands();
  }

  listBrands() {
    this.apiService.listBrands().pipe(first()).subscribe({
      next: (v) => {
        this.brands = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.brands, event.previousIndex, event.currentIndex);
    let reorderArray: any = [];
    this.brands.forEach((element: any, index: number) => {
      element.order = index + 1;
      reorderArray.push({'id': element.id, 'order': element.order});
    });
    this.apiService.orderBrands(JSON.stringify(reorderArray)).pipe(first()).subscribe({
      next: (v) => {
        console.log(v);
        this.snackBar.open("Modifications enregistrées", "OK", {
          panelClass: ['success-snackbar']
        });
      },
      error: (e) => {
        console.error(e);
        this.snackBar.open("Une erreur est survenue", "OK", {
          panelClass: ['error-snackbar']
        });
      }
    });
  }

}