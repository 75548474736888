<mat-card>
  <h2>Mes informations</h2>
  <div class="infos">
    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Nom d'utilisateur</mat-label>
        <input matInput type="text" [(ngModel)]="username">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput type="text" [(ngModel)]="email">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Nom</mat-label>
        <input matInput type="text" [(ngModel)]="lastname">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Prénom</mat-label>
        <input matInput type="text" [(ngModel)]="firstname">
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Nouveau mot de passe</mat-label>
        <input matInput type="text" [(ngModel)]="password">
      </mat-form-field>
    </div>
  </div>  
  <div class="action">
    <button mat-raised-button class="success" (click)="onSave()">
      Enregistrer
    </button>
  </div>
</mat-card>

<!-- <mat-card>
  <h2>Vos accès</h2>
  <div class="acces">
    <div class="subtitle">
      Vous avez accès aux marques suivantes :
    </div>
    <div class="brands">
      <div class="brand" *ngFor="let brand of user.brands">
        {{ brand.title }}
      </div>
    </div>
  </div>
</mat-card> -->