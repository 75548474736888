import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-single-form',
  templateUrl: './single-form.component.html',
  styleUrls: ['./single-form.component.scss']
})

export class SingleFormComponent implements OnInit {

  form: any;
  displayedColumns: string[] = ['id'];

  arrayFormElementIds: any = [];

  arrayFormEntries: any = [];
  arrayFormElements: any = [];
  displayArray: any = [];
  elements: any;


  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private apiService: ApiService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.getForm(params['id']);
    });
  }

  getForm(id: any) {
    this.apiService.getForm(id).pipe(first()).subscribe({
      next: (v) => {
        this.form = v.data[0];

        if(this.form.form_elements){
          this.form.form_elements.forEach((element: any) => {
            this.arrayFormElementIds.push(element.id);
            this.arrayFormElements.push(element);
            this.displayedColumns.push(element.title);
          });
        }

        // console.log(this.arrayFormElementIds)
        this.getFormEntries(this.arrayFormElementIds);
        
      },
      error: (e) => console.error(e)
    });    
  }

  getFormEntries(form_element_ids: any){
    this.apiService.getEntries(form_element_ids).pipe(first()).subscribe({
      next: (v) => {
        this.arrayFormEntries = v.data;  

        this.arrayFormEntries.forEach((element: any) => {

          this.arrayFormElements.forEach((e: any) => {

            if (element.form_element_id === e.id) {
              let modifsObject: any = {};
              modifsObject.id = element.form_response_id;
              modifsObject[e.title] = element.value;
    
              this.displayArray.push(modifsObject);
            }
          });
        });

        this.displayArray= this.displayArray.reduce((item: any, value: any) => {
          const fIndex = item.findIndex((v: { id: any; }) => v.id === value.id);
          if (fIndex >= 0) {
            item[fIndex] = { ...item[fIndex],
              ...value
            }
          } else {
            item.push(value);
          }
          return item;
        }, []);
        
        this.elements = new MatTableDataSource(this.displayArray);
        this.elements.paginator = this.paginator;
        
        this.paginator._intl.itemsPerPageLabel = "Elément par page";
        this.paginator._intl.nextPageLabel = 'Page suivante';
        this.paginator._intl.previousPageLabel = 'Page précédente'; 
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 à ${length }`;
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1} - ${endIndex} sur ${length}`;
        };

      },
      error: (e) => console.error(e)
    });    
  }

  export(){
    console.log('À faire');
  }

}