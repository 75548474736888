<mat-progress-bar
  mode="indeterminate"
  color="primary"
  *ngIf="isLoading"
></mat-progress-bar>
<div class="page">
  <div class="head">
    <h1>Mon compte</h1>

    <div
      *ngIf="
        user &&
        (user.subscription_type_active == 'Freemium' ||
          user.subscription_type_active == 'Basic')
      "
      class="cta"
    >
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewAbonnements()">
        <strong>Passer en Premium</strong>
      </button>
    </div>
    <div *ngIf="user && user.subscription_type_active == 'Premium'" class="cta">
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewCredits()">
        Acheter des crédits vidéo
      </button>
    </div>
  </div>
  <div class="content">
    <mat-tab-group
      mat-stretch-tabs="true"
      [selectedIndex]="currentTabIndex"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab label="Mes infos">
        <app-infos [user]="user"></app-infos>
      </mat-tab>
      <mat-tab label="Mes crédits">
        <app-credits [user]="user"></app-credits>
      </mat-tab>
      <mat-tab label="Historique">
        <app-historique [user]="user"></app-historique>
      </mat-tab>
      <mat-tab label="Mon abonnement">
        <app-abonnement [user]="user"></app-abonnement>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
