import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BrandsService } from 'src/app/services/brands.service';
import { FormControl } from '@angular/forms';
import SwiperCore from 'swiper';

@Component({
  selector: 'app-edit-form',
  templateUrl: './edit-form.component.html',
  styleUrls: ['./edit-form.component.scss']
})

export class EditFormComponent implements OnInit {

  form: any;
  title: any;
  public_title: any;
  cc_email : any;
  name_mail : any;
  
  allCategories: any;
  category: any;

  //ADD FIELD
  titleFormElement: any;
  formElementId: any;

  description: any;
  width: any;
  required: any;
  type: any;
  types_array = [
    {value: "email", text: "Email"},
    {value: "text", text: "Texte simple"},
    {value: "textarea", text: "Zone de texte"},
    {value: "number", text: "Nombre"},
    {value: "date", text: "Date"},
    {value: "submit", text: "Bouton envoyer"},
  ]

  constructor(private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute, public brandsService: BrandsService) {
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
   }

  ngOnInit(): void {
    this.initialization();
  }

  initialization(){
    this.route.params.pipe(first()).subscribe(params => {
      this.getForm(params['id']);
    });
    this.listCategories();
  }

  listCategories() {
    this.apiService.listCategories().pipe(first()).subscribe({
      next: (v) => {
        this.allCategories = v.data;
      },
      error: (e) => console.error(e)
    });
  }

  onCategorySelect(category: any){
    this.category = category;
  }

  getForm(id: any) {
    this.apiService.getForm(id).pipe(first()).subscribe({
      next: (v) => {
        this.form = v.data[0];
        this.title = this.form.title;
        this.public_title = this.form.public_title;
        if(this.form.cc_email) this.cc_email = this.form.cc_email;
        if(this.form.name_mail) this.name_mail = this.form.name_mail;
        if(this.form.category) this.category = this.form.category.id;

        if(this.form.form_elements){
          this.form.form_elements.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
          this.form.form_elements.forEach((element: any) => {
           element['typeText'] = this.types_array.find(e => e.value === element.type);
          });
        }
      },
      error: (e) => console.error(e)
    });    
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteForm);
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.apiService.removeForm(this.form.id).pipe(first()).subscribe({
          next: (v) => {
            this.snackBar.open("Formulaire supprimé", "OK", {
              panelClass: ['success-snackbar']
            });
            this.router.navigate(['/contents', 'forms']);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  onSave() {
    let modifsObject: any = {};

    if (this.title !== this.form.title) modifsObject.title = this.title;
    if (this.public_title !== this.form.public_title) modifsObject.public_title = this.public_title;
    if (this.category !== this.form.category_id) modifsObject.category_id = this.category;
    if (this.cc_email !== this.form.cc_email) modifsObject.cc_email = this.cc_email;
    if (this.name_mail !== this.form.name_mail) modifsObject.name_mail = this.name_mail;

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editForm(this.form.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/contents', 'forms']);
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
      this.router.navigate(['/contents', 'forms']);
    }
    
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.form.form_elements, event.previousIndex, event.currentIndex);
    let reorderArray: any = [];
    this.form.form_elements.forEach((element: any, index: number) => {
      element.order = index + 1;
      reorderArray.push({'id': element.id, 'order': element.order});
    });
    this.apiService.orderFormElements(JSON.stringify(reorderArray)).pipe(first()).subscribe({
      next: (v) => {
        this.snackBar.open("Modifications enregistrées", "OK", {
          panelClass: ['success-snackbar']
        });
      },
      error: (e) => {
        console.error(e);
        this.snackBar.open("Une erreur est survenue", "OK", {
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  //ADD FIELD

  onAddFormElement() {
    if(this.titleFormElement){
      this.apiService.addFormElement(this.titleFormElement, this.form.id).pipe(first()).subscribe({
        next: (v) => {
          this.formElementId = v.data.id;
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Il faut renseigner un nom", "OK", {
        panelClass: ['warning-snackbar']
      });
    }
  }

  onTypeSelect(type: any){
    this.type = type;
  }

  onSaveFormElement(){
    if(this.titleFormElement){
      this.apiService.addFormElement(this.titleFormElement, this.form.id).pipe(first()).subscribe({
        next: (v) => {
          let formElementId = v.data.id;

          let modifsObject: any = {};

          if (this.description) modifsObject.description = this.description;
          if (this.type) modifsObject.type = this.type;
          if (this.width) modifsObject.width = this.width;
          if (this.required) modifsObject.required = this.required;
      
          const hasModifs = Object.keys(modifsObject).length !== 0;
    
          if (hasModifs) {
            this.apiService.editFormElement(formElementId, modifsObject).pipe(first()).subscribe({
              next: (v) => {
                console.log(v);
                this.getForm(this.form.id);
                this.snackBar.open("Modifications enregistrées", "OK", {
                  panelClass: ['success-snackbar']
                });
              },
              error: (e) => {
                this.snackBar.open("Une erreur est survenue", "OK", {
                  panelClass: ['error-snackbar']
                });
                console.error(e);
              }
            });
          }
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Il faut renseigner un nom", "OK", {
        panelClass: ['warning-snackbar']
      });
    }
    
    
  }

  //EDIT FIELD
  onEditElement(form_element: any) {
    const dialogRef = this.dialog.open(DialogConfirmEditElement, {
      panelClass: 'dialog-container-custom',
      data: {form_element: form_element},
    });
    dialogRef.afterClosed().subscribe(validation => {
      if (validation) {
        this.getForm(this.form.id);
      }
    });
  }

  //REMOVE FIELD
  removeFormElement(form_element: any){
    this.apiService.deleteFormElement(form_element.id).pipe(first()).subscribe({
      next: (v) => {
        this.getForm(this.form.id);
        this.snackBar.open("Modifications enregistrées", "OK", {
          panelClass: ['success-snackbar']
        });
      },
      error: (e) => {
        console.error(e);
        this.snackBar.open("Une erreur est survenue", "OK", {
          panelClass: ['error-snackbar']
        });
      }
    });
  }

  goBack() {
    this.router.navigate(['/contents', 'forms']);
  }

}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteForm {}

@Component({
  selector: 'edit-element',
  templateUrl: './edit-element.html',
})
export class DialogConfirmEditElement {
  form_element: any;

  element_title: any;
  element_description: any;
  element_width: any;
  element_required: any;
  element_type: any;

  types_array = [
    {value: "email", text: "Email"},
    {value: "text", text: "Texte simple"},
    {value: "textarea", text: "Zone de texte"},
    {value: "number", text: "Nombre"},
    {value: "date", text: "Date"},
    {value: "submit", text: "Bouton envoyer"},
  ]

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmEditElement>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.form_element = this.data.form_element;

    this.element_title = this.form_element.title;
    this.element_description = this.form_element.description;
    this.element_type = this.form_element.type;
    this.element_width = this.form_element.width;
    this.element_required = this.form_element.required;
  }

  onTypeSelect(type: any){
    this.element_type = type;
  }

  onEdit() {
    let modifsObject: any = {};

    if (this.element_title !== this.form_element.title) modifsObject.title = this.element_title;
    if (this.element_description !== this.form_element.description) modifsObject.description = this.element_description;
    if (this.element_type !== this.form_element.type) modifsObject.type = this.element_type;
    if (this.element_width !== this.form_element.width) modifsObject.width = this.element_width;
    if (this.element_required !== this.form_element.required) modifsObject.required = this.element_required;

    const hasModifs = Object.keys(modifsObject).length !== 0;

    if (hasModifs) {
      this.apiService.editFormElement(this.form_element.id, modifsObject).pipe(first()).subscribe({
        next: (v) => {
          this.snackBar.open("Modifications enregistrées", "OK", {
            panelClass: ['success-snackbar']
          });
        },
        error: (e) => {
          this.snackBar.open("Une erreur est survenue", "OK", {
            panelClass: ['error-snackbar']
          });
          console.error(e);
        }
      });
    } else {
      this.snackBar.open("Modifications enregistrées", "OK", {
        panelClass: ['success-snackbar']
      });
    }
  }
}
