import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  LOCALE_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';

// import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxDropzoneModule } from 'ngx-dropzone-compressing';
import { SwiperModule } from 'swiper/angular';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { MatGridListModule } from '@angular/material/grid-list';
import { EditorModule } from '@tinymce/tinymce-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './pages/home/home.component';
import {
  SidebarComponent,
  DialogConfirmLogout,
} from './sidebar/sidebar.component';
import {
  DialogCreateCategory,
  CategoriesComponent,
} from './pages/contents/categories/categories.component';
import {
  DialogConfirmDeleteCategory,
  EditCategoryComponent,
} from './pages/contents/categories/edit-category/edit-category.component';
import {
  DialogCreateForm,
  FormsComponent,
} from './pages/contents/forms/forms.component';
import { SingleFormComponent } from './pages/contents/forms/single-form/single-form.component';
import {
  DialogConfirmDeleteForm,
  DialogConfirmEditElement,
  EditFormComponent,
} from './pages/contents/forms/edit-form/edit-form.component';
import {
  DialogCreateConfig,
  ConfigsComponent,
} from './pages/configs/configs.component';
import {
  DialogConfirmDeleteConfig,
  EditConfigComponent,
} from './pages/configs/edit-config/edit-config.component';
import { SentComponent } from './pages/sent/sent.component';
import { SingleSentComponent } from './pages/sent/single-sent/single-sent.component';
import {
  DialogCreateUser,
  UsersComponent,
} from './pages/users/users.component';
import {
  DialogGiveCredits,
  EditUserComponent,
} from './pages/users/edit-user/edit-user.component';
import {
  DialogCreateBox,
  BoxesComponent,
  DialogConfirmDeleteBoxArchive,
} from './pages/contents/boxes/boxes.component';
import {
  DialogModalAddQuestion,
  DialogConfirmDeleteBox,
  EditBoxComponent,
} from './pages/contents/boxes/edit-box/edit-box.component';
import {
  DialogCreateQuestion,
  QuestionsComponent,
} from './pages/contents/questions/questions.component';
import {
  DialogConfirmDeleteQuestion,
  EditQuestionComponent,
} from './pages/contents/questions/edit-question/edit-question.component';
import {
  DialogCreateModel,
  ModelsComponent,
} from './pages/contents/models/models.component';
import {
  DialogConfirmDeleteModel,
  EditModelComponent,
} from './pages/contents/models/edit-model/edit-model.component';
import {
  DialogCreateBrand,
  BrandsComponent,
} from './pages/brands/brands.component';
import {
  DialogConfirmDeleteBrand,
  EditBrandComponent,
} from './pages/brands/edit-brand/edit-brand.component';
import { OrderBrandsComponent } from './pages/brands/order-brands/order-brands.component';
import { BoardComponent } from './pages/board/board.component';
import { SlideGeneratorComponent } from './pages/boxes-light/scenario/slide-generator/slide-generator.component';
import { ContentsComponent } from './pages/contents/contents.component';
import {
  DialogCreateProduct,
  ProductsComponent,
} from './pages/products/products.component';
import { AccountComponent } from './pages/account/account.component';
import { UserBoardComponent } from './pages/user-board/user-board.component';
import { InfosComponent } from './pages/account/infos/infos.component';
import { CreditsComponent } from './pages/account/credits/credits.component';
import { HistoriqueComponent } from './pages/account/historique/historique.component';
import {
  DialogConfirmDeleteProduct,
  EditProductComponent,
} from './pages/products/edit-product/edit-product.component';
import { CartComponent } from './pages/cart/cart.component';
import { PaymentComponent } from './pages/cart/payment/payment.component';
import {
  BoxesLightComponent,
  DialogCreateBoxLight,
  DialogConfirmDeleteBoxArchiveLight,
} from './pages/boxes-light/boxes-light.component';
import {
  SetupComponent,
  DialogConfirmDeleteBoxLight,
} from './pages/boxes-light/setup/setup.component';
import { ScenarioComponent } from './pages/boxes-light/scenario/scenario.component';
import { EditQuestionLightComponent } from './pages/boxes-light/scenario/edit-question-light/edit-question-light.component';
import {
  ValidationComponent,
  DialogAddContact,
} from './pages/boxes-light/validation/validation.component';
import { ConfigRecordComponent } from './pages/boxes-light/config-record/config-record.component';
import { ConfigMontageComponent } from './pages/boxes-light/config-montage/config-montage.component';
import { MatRadioModule } from '@angular/material/radio';
import { UserValidationComponent } from './auth/user-validation/user-validation.component';
import {
  AbonnementComponent,
  DialogCancelSub,
  DialogConfirmChangeCard,
} from './pages/account/abonnement/abonnement.component';
import { PaymentAbonnementComponent } from './pages/account/abonnement/payment-abonnement/payment-abonnement.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { TokenInterceptorProvider } from './providers/token.interceptor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    SidebarComponent,
    DialogConfirmLogout,
    CategoriesComponent,
    DialogConfirmDeleteCategory,
    EditCategoryComponent,
    FormsComponent,
    SingleFormComponent,
    DialogConfirmDeleteForm,
    DialogConfirmEditElement,
    EditFormComponent,
    ConfigsComponent,
    DialogConfirmDeleteConfig,
    EditConfigComponent,
    SentComponent,
    SingleSentComponent,
    UsersComponent,
    EditUserComponent,
    BoxesComponent,
    DialogConfirmDeleteBox,
    EditBoxComponent,
    QuestionsComponent,
    EditQuestionComponent,
    DialogConfirmDeleteQuestion,
    ModelsComponent,
    EditModelComponent,
    DialogConfirmDeleteModel,
    BrandsComponent,
    EditBrandComponent,
    DialogConfirmDeleteBrand,
    OrderBrandsComponent,
    BoardComponent,
    SlideGeneratorComponent,
    ContentsComponent,
    ProductsComponent,
    AccountComponent,
    UserBoardComponent,
    InfosComponent,
    CreditsComponent,
    HistoriqueComponent,
    EditProductComponent,
    DialogConfirmDeleteProduct,
    DialogCreateProduct,
    CartComponent,
    DialogCreateBrand,
    DialogCreateConfig,
    DialogCreateUser,
    DialogCreateBox,
    DialogCreateQuestion,
    DialogCreateForm,
    DialogCreateCategory,
    DialogCreateModel,
    DialogModalAddQuestion,
    PaymentComponent,
    BoxesLightComponent,
    DialogCreateBoxLight,
    SetupComponent,
    DialogConfirmDeleteBoxLight,
    ScenarioComponent,
    EditQuestionLightComponent,
    ValidationComponent,
    ConfigRecordComponent,
    ConfigMontageComponent,
    DialogConfirmDeleteBoxArchive,
    DialogConfirmDeleteBoxArchiveLight,
    UserValidationComponent,
    AbonnementComponent,
    DialogCancelSub,
    DialogConfirmChangeCard,
    PaymentAbonnementComponent,
    DialogAddContact,
    ResetPasswordComponent,
    DialogGiveCredits,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatTooltipModule,
    MatDividerModule,
    MatChipsModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    DragDropModule,
    MatAutocompleteModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRippleModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSliderModule,
    NgxDropzoneModule,
    SwiperModule,
    NgxAudioPlayerModule,
    MatGridListModule,
    EditorModule,
    MatTabsModule,
    MatRadioModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-5N63B46M',
    }),
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 3000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      },
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-5N63B46M' },
    TokenInterceptorProvider,
    { provide: LOCALE_ID, useValue: 'fr-FR'}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
