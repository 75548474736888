<mat-dialog-content>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Nom de la configuration</mat-label>
    <input matInput [(ngModel)]="data.title">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 100%">
    <mat-label>Valeur</mat-label>
    <input matInput [(ngModel)]="data.value">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button mat-button class="success" [mat-dialog-close]="data.title" cdkFocusInitial>Valider</button>
</mat-dialog-actions>