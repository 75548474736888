<div class="content">

  <div class="preview">
    <canvas id="canvas" width="1365" height="1024"></canvas>
  </div>

  <div class="options">

    <div class="col" *ngIf="encodeType != 1">
      <h2>Couleur de fond :</h2>
      <input type="color" name="bg_color" [(ngModel)]="bgColor" (ngModelChange)="updateCanvas()">
      <input *ngIf="isDegradee" type="color" name="bg_color" [(ngModel)]="bgColor2" (ngModelChange)="updateCanvas()">
    </div>

    <div class="col" *ngIf="encodeType != 1">
      <h4>Dégradé :</h4>
      <mat-checkbox [(ngModel)]="isDegradee" (change)="updateCanvas()"></mat-checkbox>
    </div>

    <div class="col" *ngIf="encodeType != 1 && isDegradee">
      <h4>Direction du dégradé :</h4>
      <mat-form-field appearance="fill">
        <mat-label>Sélectionner une direction</mat-label>
        <mat-select [(ngModel)]="direction" (selectionChange)="updateCanvas()">
          <mat-option *ngFor="let o of options" [value]="o" [class]="o">
            {{o.dir}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <h2>Couleur de police :</h2>
      <input type="color" name="font_color" [(ngModel)]="fontColor" (ngModelChange)="updateCanvas()">
    </div>

    <div class="col">
      <h2>Police :</h2>
      <mat-form-field appearance="fill">
        <mat-label>Sélectionner une police</mat-label>
        <mat-select [ngModel]="fontFamily" (selectionChange)="onChangeFont($event)">
          <mat-option *ngFor="let font of fonts" [value]="font" [class]="font">
            {{font}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col">
      <h2>Taille du texte :</h2>
      <mat-slider
          max="122"
          min="58"
          step="1"
          [value]="fontSize"
          (input)="onFontSizeChange($event)"
        >
        <input matSliderThumb [(ngModel)]="fontSize">
      </mat-slider>
    </div>

    <div class="col">
      <h2>Graisse :</h2>
      <mat-form-field appearance="fill">
        <mat-label>Sélectionner une graisse</mat-label>
        <mat-select [ngModel]="fontWeight" (selectionChange)="onChangeFontWeight($event)">
          <mat-option value="300">Fine</mat-option>
          <mat-option value="400">Normal</mat-option>
          <mat-option value="600">Épaisse</mat-option>
          <mat-option value="700">Très épaisse</mat-option>
          <mat-option value="900">Maximum</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- <div class="col">
      <h2>Ajouter un logo</h2>
      <input type="file" (change)="updateLogo($event)"/>
    </div>

    <div class="col">
      <h2>Taille du logo :</h2>
      <div class="btns">
        <div class="btn more" (click)="enlargeLogo()">
          <mat-icon>add_circle</mat-icon>
        </div>
        <div class="btn less" (click)="shrinkLogo()">
          <mat-icon>remove_circle</mat-icon>
        </div>
      </div>
    </div> -->

  </div>

  <button mat-raised-button class="success" (click)="downloadCanvas()">
    Valider
  </button>

</div>