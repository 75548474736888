<mat-progress-bar mode="indeterminate" color="primary" *ngIf="isLoading"></mat-progress-bar>
<div class="page">

  <div class="header">
    <mat-form-field appearance="fill">
      <mat-label>Rechercher une question</mat-label>
      <input matInput (keyup)="filterTable($event)" placeholder="Effectuer une recherche par nom de question" autocomplete="off">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Rechercher par catégorie</mat-label>
      <mat-select  type="text" placeholder="Rechercher par catégorie" (selectionChange)="filterCategories($event.value)">
        <mat-option  value = ""></mat-option >
        <mat-option *ngFor="let option of categoriesOptionsFiltered" [value]="option.id">
          {{option.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="action">
      <button mat-raised-button class="success" (click)="onAddQuestion()">Créer une question</button>
    </div>
  </div>

  <div class="content" [class.hide]="isLoading">
    <div class="table-container mat-elevation-z2">
      <table mat-table [dataSource]="questionsToShow">

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Nom de la question</th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="categories">
          <th mat-header-cell *matHeaderCellDef> Catégorie(s)</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let category of element.categories">
              <span style="padding: 5px 10px;
              background: var(--darkViolet);
              color: #FFF;
              border-radius: 25px;">{{category.title}}</span>
              <br/>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="boxes">
          <th mat-header-cell *matHeaderCellDef> Nombre de boites</th>
          <td mat-cell *matCellDef="let element"> {{element.nbrBoxes}} </td>
        </ng-container>
        
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.slide.length">image</mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="edit" matTooltip="Éditer la question" (click)="onEdit(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Aucun résultat</td>
        </tr>
    
      </table>
    
      <mat-paginator [pageSizeOptions]="[5, 10, 20, 100]" [pageSize]="20"></mat-paginator>
    </div>

  </div>
</div>