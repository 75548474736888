import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { first } from 'rxjs';
import { LoginEventService } from './services/login-event.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showSidebar: boolean = false;
  menuMobile: boolean = false;
  canSwitch: boolean = false;
  breakpoint: number = 760;

  user: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    public loginEvent: LoginEventService,
    private gtmService: GoogleTagManagerService
  ) {
    if (window.innerWidth < this.breakpoint) this.mobileMenu();

    router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) return;
      this.showSidebar = evt.url == '/login' ? false : true;
      if (evt.url.includes('user-validation/')) this.showSidebar = false;
      if (evt.url.includes('reset-password/')) this.showSidebar = false;
      document.getElementById('app-content')!.scroll(0, 0);

      if (this.showSidebar) {
        if (window.innerWidth < this.breakpoint) {
          this.mobileMenu();
        } else {
          this.desktopMenu();
          if (window.innerWidth > this.breakpoint) {
            this.canSwitch = false;
          } else {
            this.canSwitch = true;
          }
        }
      }

      // GTM service
      const gtmTag = {
        event: 'page',
        pageName: evt.url,
      };
      this.gtmService.pushTag(gtmTag);
    });

    this.loginEvent.getLoginEvent().subscribe((res: any) => {
      this.initialization();
    });
  }

  ngOnInit() {
    this.authService.autoLogin();
    this.initialization();
  }

  initialization() {
    const jsonData: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(jsonData);
    const token: any = localStorage.getItem('jwt_token');
    if (token) {
      this.updateUser(userData);
    }
  }

  updateUser(userData: any) {
    // this.apiService.getUser(userData.id).pipe(first()).subscribe({
    //   next: (v) => {
    //     // this.user = v.data[0];
    //     this.user = v.data;
    //   },
    //   error: (e) => console.error(e)
    // });
    this.apiService
      .getCurrentUser()
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.user = v.data;
        },
        error: (e) => console.error(e),
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < this.breakpoint) {
      this.mobileMenu();
    } else {
      this.desktopMenu();
    }
  }

  mobileMenu() {
    this.menuMobile = true;
    this.canSwitch = true;
  }

  desktopMenu() {
    this.menuMobile = false;
  }
}
