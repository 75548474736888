import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppComponent } from '../../app.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  isLoading: boolean = true;
  brands: any;
  displayedColumns: string[] = ['title', 'description', 'edit'];


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  constructor(private dialog: MatDialog, private snackBar: MatSnackBar, private router: Router, private apiService: ApiService, private app: AppComponent) { }

  ngOnInit(): void {
    this.listBrands();
  }

  listBrands() {
    this.isLoading = true;
    this.apiService.listBrands().pipe(first()).subscribe({
      next: (v) => {
        let datas = v.data;
        datas.forEach((data: any) => {
          data['titleWithoutAccents'] = data.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        });

        this.brands = new MatTableDataSource(datas);
        this.brands.paginator = this.paginator;
        
        this.paginator._intl.itemsPerPageLabel = "Marque par page";
        this.paginator._intl.nextPageLabel = 'Page suivante';
        this.paginator._intl.previousPageLabel = 'Page précédente'; 
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 à ${length }`;
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1} - ${endIndex} sur ${length}`;
        };
        this.isLoading = false;
      },
      error: (e) => console.error(e)
    });
  }

  filterTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.brands.filter = filterValue.trim().toLowerCase();
  }

  onEdit(brand: any) {
    this.router.navigate(['/brands/edit/' + brand.id]);
  }

  onAddBrand() {
    const dialogRef = this.dialog.open(DialogCreateBrand, {
      data: {title: ''},
    });
    dialogRef.afterClosed().subscribe((result: any) => {  
      if (result) {
        this.apiService.addBrand(result).pipe(first()).subscribe({
          next: (v) => {
            this.listBrands();
            // this.app.listAllBrands();
            this.app.initialization();
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

  orderBrands(){
    this.router.navigate(['/brands/order']);
  }

}


export interface DialogData {
  title: string;
}

@Component({
  selector: 'add-brand',
  templateUrl: './add-brand.html',
})
export class DialogCreateBrand implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCreateBrand>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }

  onNoClick() {
    this.data.title = '';
    this.dialogRef.close();
  }

}