<div *ngIf="config" class="page">
  <div class="head">
    <h1>{{config.title}}</h1>
    <button mat-icon-button class="delete" matTooltip="Supprimer la configuration" (click)="onDelete()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <mat-card>
    <div class="infos">
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Nom de la configuration</mat-label>
          <input matInput type="text" [(ngModel)]="title">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Valeur</mat-label>
          <textarea matInput type="text" [(ngModel)]="value"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="action">
      <button mat-raised-button class="success" (click)="onSave()">
        Enregistrer
      </button>
    </div>
  </mat-card>
</div>