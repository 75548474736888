<mat-progress-bar mode="indeterminate" color="primary" *ngIf="!sent"></mat-progress-bar>
<div *ngIf="sent" class="page">
  <div class="head">
    <h1>Enregistrement N°{{sent.id}}</h1>
    <div class="infos">
      <h3>Enregistrée le {{date}} à {{hours}}</h3>
      <h3>Contact : {{sent.email}}</h3>
    </div>
  </div>

  <div class="content">
    <div class="redirection">
      <a [href]="sent.box_url" rel="noopener noreferrer">Éditer la boite</a>
      <a [href]="sent.front_url" target="_blank" rel="noopener noreferrer">Créer ma vidéo</a>
    </div>

    <mat-card>
      <section class="header">
        <section class="header-start">
          {{sent.box ? sent.box.title : 'Boîte supprimée'}}
        </section>
      </section>
      <section class="container-body">
        <iframe [src]='sent.vimeo_player' height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
      </section>
      <div *ngIf="form_entries && form_entries.length > 0" class="entries">
        <div class="entry" *ngFor="let entry of form_entries">
          <b>{{entry.form_element.title}} :</b> {{entry.value}}
        </div>
      </div>
      <section class="footer">
        <div class="download" (click)="download_video()">
          Télécharger la vidéo
        </div>
        <a [href]="sent.vimeo_url" target="_blank" rel="noopener noreferrer">Voir sur Vimeo</a>
      </section>
    </mat-card>

  </div>

  <div class="contentBottom">
    <div class="listItw" *ngIf="sent.list_videos_interview != null">
      <h2>Les vidéos par question</h2>
      <mat-card *ngFor="let itw of list_interviews">
        <section class="container-body">
          <iframe [src]='itw[1]' height="450" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
        </section>
        <div *ngIf="form_entries && form_entries.length > 0" class="entries">
          <div class="entry" *ngFor="let entry of form_entries">
            <b>{{entry.form_element.title}} :</b> {{entry.value}}
          </div>
        </div>
        <section class="footer">
          <div class="download" (click)="download_video()">
            Télécharger la vidéo
          </div>
          <a [href]="itw[0]" target="_blank" rel="noopener noreferrer">Voir sur Vimeo</a>
        </section>
      </mat-card>
    </div>
  </div>
</div>