import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit {
  box: any;

  title: any;

  user: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    public brandsService: BrandsService
  ) {}

  ngOnInit(): void {
    this.getCurrentUser();
    this.route.params.pipe(first()).subscribe((params) => {
      this.getBox(params['id']);
    });
  }

  getCurrentUser() {
    this.apiService
      .getCurrentUser()
      .pipe(first())
      .subscribe({
        next: (v) => {
          // this.user = v.data[0];
          this.user = v.data;
          this.apiService
            .listSubscriptionPlans()
            .pipe(first())
            .subscribe({
              next: (v) => {
                this.user.subPlan = v.data.find(
                  (plan: any) => plan.title == this.user.subscription_type
                );
                if (!this.user.subPlan) {
                  this.user.subPlan = v.data.find(
                    (plan: any) => plan.title == 'Freemium'
                  );
                }
                this.user.subPlan.parameters = JSON.parse(
                  this.user.subPlan.parameters
                );
              },
              error: (e) => console.error(e),
            });
        },
        error: (e) => console.error(e),
      });
  }

  getBox(id: any) {
    this.apiService
      .getBox(id)
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.box = v.data[0];
          this.title = this.box.title;
          if (!this.box.encode_type) {
            this.box.encode_type = 1;
          }
        },
        error: (e) => console.error(e),
      });
  }

  goBack() {
    this.router.navigate(['/contents']);
  }

  onDelete() {
    const dialogRef = this.dialog.open(DialogConfirmDeleteBoxLight);
    dialogRef.afterClosed().subscribe((validation) => {
      if (validation) {
        this.apiService
          .removeBox(this.box.id)
          .pipe(first())
          .subscribe({
            next: (v) => {
              if (this.box.model_id) {
                this.apiService
                  .removeModel(this.box.model_id)
                  .pipe(first())
                  .subscribe({
                    next: (v) => {
                      this.snackBar.open('Boite supprimée', 'OK', {
                        panelClass: ['success-snackbar'],
                      });
                      this.router.navigate(['/contents']);
                    },
                    error: (e) => {
                      console.error(e);
                    },
                  });
              } else {
                this.snackBar.open('Boite supprimée', 'OK', {
                  panelClass: ['success-snackbar'],
                });
                this.router.navigate(['/contents']);
              }
            },
            error: (e) => {
              this.snackBar.open('Une erreur est survenue', 'OK', {
                panelClass: ['error-snackbar'],
              });
              console.error(e);
            },
          });
      }
    });
  }

  setType(type: number) {
    if (this.user.subPlan.parameters.box_type_1 !== 2 && type == 1) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    if (this.user.subPlan.parameters.box_type_2 !== 2 && type == 2) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    if (this.user.subPlan.parameters.box_type_3 !== 2 && type == 3) {
      this.snackBar.open("Vous n'avez pas accès à cette fonctionnalité", 'OK', {
        panelClass: ['error-snackbar'],
      });
      return;
    }
    this.box.encode_type = type;
  }

  next() {
    let modifsObject: any = {};

    modifsObject.title = this.title;
    modifsObject.encode_type = this.box.encode_type;

    if (this.box.model_id) {
      this.apiService
        .editBox(this.box.id, modifsObject)
        .pipe(first())
        .subscribe({
          next: (v) => {
            this.router.navigate(['/boxes/edit-box/scenario/' + this.box.id]);
          },
          error: (e) => {
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
            console.error(e);
          },
        });
    } else {
      let modeleTitle = this.title + '-' + this.box.id;
      this.apiService
        .addModel(modeleTitle)
        .pipe(first())
        .subscribe({
          next: (vModele) => {
            modifsObject.model = vModele.data.id;
            this.apiService
              .editBox(this.box.id, modifsObject)
              .pipe(first())
              .subscribe({
                next: (v) => {
                  this.router.navigate([
                    '/boxes/edit-box/scenario/' + this.box.id,
                  ]);
                },
                error: (e) => {
                  this.snackBar.open('Une erreur est survenue', 'OK', {
                    panelClass: ['error-snackbar'],
                  });
                  console.error(e);
                },
              });
          },
          error: (e) => {
            this.snackBar.open('Une erreur est survenue', 'OK', {
              panelClass: ['error-snackbar'],
            });
            console.error(e);
          },
        });
    }
  }

  seePlans() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/account/abonnement'])
    );
    this.apiService.sendClickInfo('PAQ_ACTIVERMODELE').subscribe({
      next: (v) => {
        window.open(url, '_blank');
      },
      error: (e) => console.error(e.message),
    });
  }
}

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.html',
})
export class DialogConfirmDeleteBoxLight {}
