import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  private fooSubject = new Subject<any>();
  private subject = new BehaviorSubject('random');

constructor() { }


    setBrand(data: any) {
        this.fooSubject.next(data);
        this.subject.next(data);
    }

    getBrand(): Subject<any> {
        return this.fooSubject;
    }

    public get subjectValue() {
        return this.subject.value;
    }
}
