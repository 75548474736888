<div *ngIf="category" class="page">
  <div class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>{{category.title}}</h1>
    <button mat-icon-button class="delete" matTooltip="Supprimer la categorie" (click)="onDelete()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <mat-card>
    <div class="infos">
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Nom de la catégorie</mat-label>
          <input matInput type="text" [(ngModel)]="title">
        </mat-form-field>
      </div>
      <div class="row" style="flex-direction:column;">
        <h2>Importer une image</h2>
        <div class="rowSlide">
          <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelectMedia($event)" *ngIf="fileMedia?.length == 0">
              <mat-icon>cloud_upload</mat-icon>
          </div>
          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of fileMedia" [file]="f" [removable]="true" (removed)="onRemoveMedia()">
          </ngx-dropzone-image-preview>
        </div>
      </div>
    </div>
    <div class="action">
      <button mat-raised-button class="success" (click)="onSave()">
        Enregistrer
      </button>
    </div>
  </mat-card>
</div>