import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';
import { first } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss']
})
export class ScenarioComponent implements OnInit {

  box: any;

  questions: any;

  editQuestionModal: boolean = false;
  questionToEdit: any;

  user: any;

  constructor(private cd: ChangeDetectorRef, private router: Router, private dialog: MatDialog, private snackBar: MatSnackBar, private apiService: ApiService, private route: ActivatedRoute, public brandsService: BrandsService) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.route.params.pipe(first()).subscribe(params => {
      this.getBox(params['id']);
    });
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().pipe(first()).subscribe({
      next: (v) => {
        // this.user = v.data[0];
        this.user = v.data;
        this.apiService.listSubscriptionPlans().pipe(first()).subscribe({
          next: (v) => {
            this.user.subPlan = v.data.find((plan: any) => plan.title == this.user.subscription_type);
            if (!this.user.subPlan) {
              this.user.subPlan = v.data.find((plan: any) => plan.title == 'Freemium');
            }
            this.user.subPlan.parameters = JSON.parse(this.user.subPlan.parameters);
          },
          error: (e) => console.error(e)
        });
      },
      error: (e) => console.error(e)
    });
  }

  getBox(id: any) {
    this.apiService.getBox(id).pipe(first()).subscribe({
      next: (v) => {
        this.box = v.data[0];

        if (this.box.questions && this.box.questions.length) {
          this.box.questions.forEach((question: any) => {
            this.box.box_questions.forEach((q: any) => {
              if(question.id === q.question_id) {
                question['order'] = q.order;
                question['pivotId'] = q.id;
              }
            });

            if (this.box.encode_type == 3 && !question.is_video) {
              this.box.has_problem = true;
            }
          });
          this.box.questions.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
        }
      },
      error: (e) => console.error(e)
    });
  }

  goBack() {
    this.router.navigate(['/boxes/edit-box/setup/' + this.box.id]);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.box.questions, event.previousIndex, event.currentIndex);
    let reorderArray: any = [];
    this.box.questions.forEach((question: any, index: number) => {
      question.order = index + 1;
      reorderArray.push({'id': question.id, 'order': question.order, 'pivotId': question.pivotId});
    });
    this.apiService.orderQuestion(JSON.stringify(reorderArray)).pipe(first()).subscribe({
      next: (v) => {
        this.snackBar.open("Modifications enregistrées", "OK", {
          panelClass: ['success-snackbar']
        });
      },
      error: (e) => {
        this.snackBar.open("Une erreur est survenue", "OK", {
          panelClass: ['error-snackbar']
        });
        console.error(e);
      }
    });
  }

  addQuestion() {
    if (this.user.subPlan.parameters.question_limit == 0 || (this.user.subPlan.parameters.question_limit > this.box.questions.length) || (this.user.is_admin)) {
      this.router.navigate(['/boxes/edit-box/' + this.box.id + '/question/new']);
    } else {
      this.snackBar.open("Nombre maximum de questions atteint", "OK", {
        panelClass: ['error-snackbar']
      });
    }
  }

  editQuestion(question: any) {
    this.router.navigate(['/boxes/edit-box/' + this.box.id + '/question/' + question.id]);
  }

  next() {
    this.router.navigate(['/boxes/edit-box/config-record/' + this.box.id]);
  }

  end() {
    if (this.box.parameters === null) {
      let parameters: any = {};

      parameters.freemium = true;

      parameters.formatSortie = {
        formatPortrait: false,
        formatPaysage: false,
        formatIdentique: true,
        answersOnly: false,
        seperateFiles: false,
        allFormats: false,
      };

      if (this.box.encode_type == 1) {
        parameters.formatSortie.questionVideo = false;
        parameters.formatSortie.questionTxt = true;
        parameters.formatSortie.questionImg = false;
      } else if (this.box.encode_type == 2) {
        parameters.formatSortie.questionVideo = false;
        parameters.formatSortie.questionTxt = false;
        parameters.formatSortie.questionImg = true;
      } else if (this.box.encode_type == 3) {
        parameters.formatSortie.questionVideo = true;
        parameters.formatSortie.questionTxt = false;
        parameters.formatSortie.questionImg = false;
      }

      if (!this.box.private_code) {
        this.box.private_code = Math.floor(100000 + Math.random() * 900000);
      }

      const modifsBox = {
        status: this.box.status || 10,
        private_code: this.box.private_code,
        parameters: JSON.stringify(parameters)
      }

      this.apiService.editBox(this.box.id, modifsBox).pipe(first()).subscribe({
        next: (v) => {
          this.router.navigate(['/boxes/edit-box/validation/' + this.box.id]);
        },
        error: (e) => {
          console.error(e);
        }
      });
    } else {
      this.router.navigate(['/boxes/edit-box/validation/' + this.box.id]);
    }
  }

}
