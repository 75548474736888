<div class="sidebar-menu">
  <div class="icon">
    <img src="assets/icon.png" />
  </div>

  <mat-form-field
    appearance="outline"
    class="mat-focused"
    *ngIf="user"
  >
    <mat-label>Marque</mat-label>
    <mat-select
      [(ngModel)]="brandSelected"
      (selectionChange)="onBrandSelect($event.value)"
    >
      <mat-option *ngFor="let brand of listBrands" [value]="brand.id">
        {{ brand.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-selection-list [multiple]="false">
    <mat-nav-list *ngFor="let menu of menus">
      <a mat-list-item [routerLink]="menu.url" routerLinkActive="active">
        {{ menu.title }}
      </a>
    </mat-nav-list>
  </mat-selection-list>

  <mat-selection-list
    [multiple]="false"
    [class.collapsed]="adminCollapsed"
    [class.extand]="!adminCollapsed"
    *ngIf="administrations.length"
  >
    <div class="title" (click)="triggerAdminMenu()">
      <div class="text"><mat-icon>manage_accounts</mat-icon>Administration</div>
      <mat-icon>expand_more</mat-icon>
    </div>
    <mat-nav-list *ngFor="let administration of administrations">
      <a
        mat-list-item
        [routerLink]="administration.url"
        routerLinkActive="active"
      >
        {{ administration.title }}
      </a>
    </mat-nav-list>
  </mat-selection-list>

  <mat-selection-list
    [multiple]="false"
    *ngIf="user"
    [class.collapsed]="accountCollapsed"
    [class.extand]="!accountCollapsed"
  >
    <div class="title" (click)="triggerAccountMenu()">
      <div class="text">
        <mat-icon>account_circle</mat-icon> {{ user.firstname }}
        {{ user.lastname }}
      </div>
      <mat-icon>expand_more</mat-icon>
    </div>

    <mat-nav-list *ngFor="let account of accounts">
      <a mat-list-item [routerLink]="account.url" routerLinkActive="active">
        {{ account.title }}
      </a>
    </mat-nav-list>

    <div class="credits">
      <mat-icon>star</mat-icon>
      <span>{{ user.credits || 0 }}</span> crédits restants
    </div>

    <!-- <div class="logout" (click)="onLogout()">
      Se déconnecter
    </div> -->
  </mat-selection-list>
  <mat-selection-list class="logoutWrapper">
    <div class="logout" (click)="onLogout()">Se déconnecter</div>
  </mat-selection-list>
</div>
