<mat-progress-bar
  mode="indeterminate"
  color="primary"
  *ngIf="isLoading"
></mat-progress-bar>
<div class="page">
  <div class="head">
    <h1>Tableau de bord général</h1>
    <div
      *ngIf="
        user &&
        (user.subscription_type_active == 'Freemium' ||
          user.subscription_type_active == 'Basic')
      "
      class="cta"
    >
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewAbonnements()">
        <strong>Passer en Premium</strong>
      </button>
    </div>
    <div *ngIf="user && user.subscription_type_active == 'Premium'" class="cta">
      <p>
        Mon abonnement: <strong>{{ user.subscription_type_active }}</strong>
      </p>
      <button mat-raised-button class="success" (click)="viewCredits()">
        Acheter des crédits vidéo
      </button>
    </div>
  </div>
  <!-- <div class="content" [class.hide]="isLoading"> -->
  <div class="content">
    <div class="table-container mat-elevation-z2">
      <!-- <table mat-table [dataSource]="sents"> -->
      <table mat-table [dataSource]="tableData">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Contact</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>

        <ng-container matColumnDef="box">
          <th mat-header-cell *matHeaderCellDef>Nom de la boite</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.box">{{ element.box.title }}</span>
            <i *ngIf="element.box == null">Boîte supprimée</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef>Marque</th>
          <td mat-cell *matCellDef="let element">
            {{ element.box ? element.box.brand.title : "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="info" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="view(element)" *ngIf="element.box">
              <mat-icon>info_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Aucun résultat</td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 100]"
        [pageSize]="20"
        [length]="totalData"
        #paginator
      ></mat-paginator>
    </div>
  </div>
</div>
