import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from './auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoginEventService } from 'src/app/services/login-event.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  isLoading: boolean = false;

  formLogin: boolean = true;
  formLostPass: boolean = false;
  browser: string = "Unknown";

  constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router, private authService: AuthService, public loginEvent: LoginEventService) { }

  ngOnInit(): void {
    this.authService.user.subscribe(user => {
      if (user) {
        this.router.navigate(['/contents']);
      }
    });

    let userAgent = navigator.userAgent;
    if (/Chrome/.test(userAgent) && !/Chromium/.test(userAgent)) {
      this.browser = "Chrome";
    }
  }

  onLogin(form: NgForm) {
    if (!form.valid) return;

    this.isLoading = true;

    const email = form.value.login;
    const password = form.value.password;

    this.authService.login(email, password).subscribe(
      resData => {
        this.loginEvent.loginEvent(true);
        this.router.navigate(['/contents']);
      },
      errRes => {
        this.snackBar.open(errRes.message, "OK", {
          panelClass: ['error-snackbar']
        });
        this.isLoading = false;
      }
    );

    form.resetForm();
  }

  onGetToken(form: NgForm) {
    if (!form.valid) { return; }

    this.isLoading = true;
    const email = form.value.login;

    this.authService.resetPassword(email).subscribe(
      resData => {
        this.toggleLogin();
        this.snackBar.open("Un lien de réinitialisation vous a été envoyé par e-mail", "OK", {
          panelClass: ['success-snackbar']
        });
        this.isLoading = false;
        form.reset();
      },
      errRes => {
        this.snackBar.open(errRes.message, "OK", {
          panelClass: ['error-snackbar']
        });
        this.isLoading = false;
      }
    );

  }

  toggleLogin() {
    this.formLogin = true;
    this.formLostPass = false;
  }

  toggleLostPass() {
    this.formLogin = false;
    this.formLostPass = true;
  }

}
