<ng-container *ngIf="!paymentPage">
  <mat-progress-bar
    mode="indeterminate"
    color="primary"
    *ngIf="isLoading"
  ></mat-progress-bar>

  <mat-card *ngIf="user && user.activeSubPlan">
    <h2>Mon abonnement</h2>
    <div class="current">
      <ng-container *ngIf="!user.activeSubPlan">
        Vous n'avez pas d'abonnement actif
      </ng-container>
      <div *ngIf="user.endedSubPlan" style="margin-bottom: 10px">
        Votre abonnement <b>{{ user.endedSubPlan.subscription_type }}</b> a pris
        fin le
        <span *ngIf="user.endedSubPlan.next_payment">{{
          user.endedSubPlan.next_payment | date : "dd/MM/yyyy"
        }}</span>
        <span *ngIf="user.endedSubPlan.next_payment == null">{{
          user.endedSubPlan.disable_date | date : "dd/MM/yyyy"
        }}</span
        >.
      </div>
      <div class="abo" *ngIf="user.activeSubPlan">
        <div class="actif">
          Votre abonnement actuel :
          <b>{{ user.activeSubPlan.subscription_type }}</b>
        </div>
        <div
          class="facturation"
          *ngIf="
            !user.activeSubPlan.disable_date && user.activeSubPlan.next_payment
          "
        >
          Votre prochaine facturation est prévue pour le
          {{ user.activeSubPlan.next_payment | date : "dd/MM/yyyy" }}
          <span *ngIf="user.activeSubPlan.subscription_plan.price_ttc"
            >pour un montant de
            {{ user.activeSubPlan.subscription_plan.price_ttc / 100 }} €
            TTC.</span
          >
        </div>
        <br />
        <div
          class="cancel"
          *ngIf="
            !user.activeSubPlan.disable_date && user.activeSubPlan.next_payment
          "
        >
          <a (click)="cancelSub()">Résilier</a>
        </div>
        <div
          class="abo-resilie"
          *ngIf="
            user.activeSubPlan.disable_date &&
            user.activeSubPlan.disable_reason != 'Promotion'
          "
        >
          Votre abonnement a été résilié, il est encore actif jusqu'au
          {{ user.activeSubPlan.next_payment | date : "dd/MM/yyyy" }}
          <br /><br />
          <a (click)="reSub()">Annuler ma résiliation</a>
        </div>
        <div
          class="abo-resilie"
          *ngIf="
            user.activeSubPlan.disable_date &&
            user.activeSubPlan.disable_reason == 'Promotion'
          "
        >
          Votre abonnement promotionnel est actif jusqu'au
          {{ user.activeSubPlan.next_payment | date : "dd/MM/yyyy" }}
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card *ngIf="user && showPromo">
    <h2>Activer un abonnement promo</h2>
    <div class="subtitle">
      Vous avez reçu un code pour activer un abonnement promo ?
    </div>
    <div class="input-promo">
      <mat-form-field appearance="fill" (keydown.enter)="usePromoCode()">
        <mat-label>Code</mat-label>
        <input matInput type="text" [(ngModel)]="promoCode" />
      </mat-form-field>
      <button mat-raised-button class="success" (click)="usePromoCode()">
        Valider
      </button>
    </div>
  </mat-card>

  <mat-card
    *ngIf="
      user &&
      user.activeSubPlan &&
      !user.activeSubPlan.disable_date &&
      user.activeSubPlan.end_validity_card
    "
  >
    <h2>Mon moyen de paiement</h2>
    <div class="card">
      Carte bancaire expirant en
      {{ user.activeSubPlan.end_validity_card | date : "MM/yyyy" }}
    </div>
    <br />
    <div class="change">
      <a (click)="changeCard()">Modifier mon moyen de paiement</a>
    </div>
  </mat-card>

  <mat-card *ngIf="user && plans && plans.length">
    <h2>Abonnements disponibles</h2>
    <div class="plans">
      <ng-container *ngFor="let plan of plans">
        <mat-card
          class="plan order-{{ plan.parameters.order }}"
          [class.downgrade]="
            user.activeSubPlan &&
            user.activeSubPlan.subscription_plan.parameters.order >
              plan.parameters.order
          "
        >
          <div class="populaire" *ngIf="plan.parameters.order === 3">
            Le + populaire
          </div>
          <div class="title" *ngIf="plan.title">
            {{ plan.title | uppercase }}
          </div>
          <div
            class="subtitle"
            *ngIf="plan.subtitle"
            [innerHTML]="plan.subtitle"
          ></div>
          <div class="price" *ngIf="plan.price_ht">
            <div
              style="display: flex; align-items: center; margin-bottom: 10px"
            >
              <span style="font-size: 50px">{{ plan.price_ht / 100 }}€</span>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  margin-left: 10px;
                  text-align: left;
                "
              >
                <span>HT</span><span>/ mois</span>
              </div>
            </div>
            <div class="price-ttc" *ngIf="plan.price_ttc">
              soit
              {{
                plan.price_ttc / 100
                  | currency : "EUR" : "symbol" : "1.2-2" : "fr-FR"
              }}
              TTC
            </div>
            <h3
              *ngIf="plan.parameters.order == 2"
              class="title"
              style="margin-top: 6px"
            >
              {{ plan.credits }} vidéos / mois
            </h3>
            <h3
              *ngIf="plan.parameters.order == 3"
              class="title"
              style="margin-top: 6px"
            >
              {{ plan.credits }} vidéos / mois
            </h3>
          </div>

          <div
            class="price"
            style="font-size: 30px"
            *ngIf="plan.parameters.order == 1"
          >
            <span style="font-size: 50px">0€</span>
            <h3 class="title" style="margin-top: 20px">
              {{ plan.credits }} vidéos offertes*
            </h3>
          </div>
          <div
            class="price"
            style="font-size: 30px"
            *ngIf="plan.parameters.order == 4"
          >
            Sur demande
          </div>

          <div
            class="action"
            *ngIf="
              plan.price_ht &&
              (!user.activeSubPlan ||
                (user.activeSubPlan &&
                  user.activeSubPlan.subscription_type !==
                    plan.subscription_type)) &&
              !(
                user.activeSubPlan &&
                user.activeSubPlan.subscription_plan.parameters.order >
                  plan.parameters.order
              )
            "
          >
            <button mat-raised-button (click)="subscribe(plan)">
              S'abonner
            </button>
          </div>
          <div
            class="action"
            *ngIf="
              plan.parameters.order == 4 &&
              (!user.activeSubPlan ||
                (user.activeSubPlan &&
                  user.activeSubPlan.subscription_type !==
                    plan.subscription_type))
            "
          >
            <button mat-raised-button (click)="subscribe(plan)">
              Prendre RDV
            </button>
          </div>
          <div
            class="active"
            *ngIf="
              user.activeSubPlan &&
              ((user.activeSubPlan &&
                user.activeSubPlan.subscription_type ==
                  plan.subscription_type) ||
                (!user.activeSubPlan &&
                  plan.subscription_type == 'Freemium')) &&
              !user.activeSubPlan.disable_date
            "
          >
            <!-- <div class="text">Abonnement actif</div> -->
            <button mat-raised-button disabled>
              Abonnement actif
              <mat-icon> check_circle </mat-icon>
            </button>
          </div>
          <div
            class="active resilie"
            *ngIf="
              user.activeSubPlan &&
              user.activeSubPlan.subscription_type == plan.subscription_type &&
              user.activeSubPlan.disable_date
            "
          >
            <button mat-raised-button disabled>
              Votre abonnement est actif jusqu'au
              {{ user.activeSubPlan.next_payment | date : "dd/MM/yyyy" }}
              <mat-icon> check_circle </mat-icon>
            </button>
          </div>
          <div
            class="inclu"
            *ngIf="
              user.activeSubPlan &&
              user.activeSubPlan.subscription_plan.parameters.order >
                plan.parameters.order
            "
          >
            <!-- <div class="text">Abonnement actif</div> -->
            <button mat-raised-button disabled>
              Inclus
              <mat-icon> check_circle </mat-icon>
            </button>
          </div>
          <div class="description" [innerHTML]="plan.description"></div>
        </mat-card>
      </ng-container>
    </div>
  </mat-card>
</ng-container>

<div *ngIf="paymentPage" class="beforePayment">
  <h2>Bravo ! Vous avez opté pour PAQ {{ subPlan.title | uppercase }} ✨</h2>
  <p>Votre abonnement sera renouvelé chaque mois. <br>Vous avez la possibilité de l'annuler à tout moment, <br> sans préavis. <a href="https://www.place-aux-questions.com/conditions-dutilisation/" target="_blank">Voir nos conditions d'utilisation</a>.</p>
</div>

<app-payment-abonnement
  *ngIf="paymentPage"
  [paymentId]="paymentId"
  [subPlanId]="subPlanId"
  [activeSubId]="activeSubId"
>
</app-payment-abonnement>
