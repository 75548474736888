<div class="questionProblem" *ngIf="box && box.has_problem">
  Attention, votre boîte contient des questions qui ne sont pas correctement configurées. Veuillez <a [routerLink]="'/boxes/edit-box/scenario/'+ box.id">les modifier</a>
</div>

<div class="page" *ngIf="box && !editQuestionModal">
  <div class="head">
    <div class="back">
      <button mat-icon-button class="copy" matTooltip="Revenir" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>Scénario de questions</h1>
    <div class="empty"></div>
  </div>

  <div class="content">
    <div class="title">Création du scénario de questions</div>

    <div cdkDropList class="order-list" (cdkDropListDropped)="drop($event)">
      <div class="questionsList" *ngFor="let question of box.questions; let i = index" cdkDrag (click)="editQuestion(question)">
        <mat-card class="question">
          <span>{{i+1}}</span>
          {{question.title}}
          <img src="./../../../../assets/icon-image.png" alt="" srcset="" *ngIf="box.encode_type == 2 && !question.is_generated && question.media.length">
          <img src="./../../../../assets/icon-video.png" alt="" srcset="" *ngIf="box.encode_type == 3 && question.is_video && question.video_slide.length">
          <span class="error" *ngIf="box.encode_type == 3 && !question.is_video && !question.video_slide.length">Veuillez importer une vidéo</span>
          <img src="./../../../../assets/icon-txt.png" alt="" srcset="" *ngIf="box.encode_type == 2 && question.is_generated">
          <mat-icon>chevron_right</mat-icon>
        </mat-card>
        <mat-card class="preview" *cdkDragPreview>
          {{question.title}}
        </mat-card>
      </div>
    </div>

    <div class="add-question">
      <button mat-raised-button class="success" (click)="addQuestion()">
        Ajouter une question
      </button>
    </div>
  </div>

  <div class="action">
    <button mat-button class="success light" [class.disabled]="!box.questions.length" (click)="next()">
      Configurer l'enregistrement <mat-icon>chevron_right</mat-icon>
    </button>
  </div>

  <div class="action">
    <!-- <button mat-raised-button class="success" [class.disabled]="!box.questions.length" (click)="end()">
      Visualisation de ma boîte <mat-icon>chevron_right</mat-icon>
    </button> -->
    <div class="skip" (click)="end()">
      <p>Passer la configuration</p> <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>