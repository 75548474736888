import { Component, Inject, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  isLoading: boolean = true;

  products: any;

  constructor(private snackBar: MatSnackBar, private dialog: MatDialog, private router: Router, public brandsService: BrandsService, private apiService: ApiService) { 
    this.brandsService.getBrand().subscribe((res:any)=>{
      this.initialization();
    })
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.isLoading = true;
    this.listProducts();
  }

  listProducts() {
    this.apiService.listProducts().pipe(first()).subscribe({
      next: (v) => {
        let datas = v.data;
        this.products = datas;      
        this.isLoading = false;
      },
      error: (e) => console.error(e)
    });
  }

  onEdit(product: any) {
    this.router.navigate(['/products/edit/' + product.id]);
  }

  addProduct() {
    const dialogRef = this.dialog.open(DialogCreateProduct, {
      data: {title: ''},
    });
    dialogRef.afterClosed().subscribe((result: any) => {  
      if (result) {
        this.apiService.addProduct(result).pipe(first()).subscribe({
          next: (v) => {
            this.router.navigate(['/products/edit/' + v.data.id]);
          },
          error: (e) => {
            this.snackBar.open("Une erreur est survenue", "OK", {
              panelClass: ['error-snackbar']
            });
            console.error(e);
          }
        });
      }
    });
  }

}

export interface DialogData {
  title: string;
}

@Component({
  selector: 'add-modal',
  templateUrl: './add-product.html',
})
export class DialogCreateProduct implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogCreateProduct>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngOnInit(): void {
  }

  onNoClick() {
    this.data.title = '';
    this.dialogRef.close();
  }

}
