import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginEventService {

  private fooSubject = new Subject<any>();

constructor() { }


    loginEvent(data: any) {
        this.fooSubject.next(data);
    }

    getLoginEvent(): Subject<any> {
        return this.fooSubject;
    }
}
