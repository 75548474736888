import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, from, mergeMap, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('jwt_token');

    if (request.headers.has('Authorization')) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.error.error == 'Expired token') {
          this.authService.logout();
        } else {
          console.log(error);
        }
        return throwError(error);
      })
    );

    // return from(this.storageService.get('jwt-token')).pipe(
    //   mergeMap((token) => {
    //     if (token) {
    //       // token += 'a';

    //       request = request.clone({
    //         setHeaders: {
    //           Authorization: `Bearer ${token}`,
    //           Accept: 'application/json',
    //         },
    //       });
    //     }
    //     return next.handle(request).pipe(
    //       catchError((error: HttpErrorResponse) => {
    //         if (error.status === 401 && (error.error.message == 'Unauthenticated' || error.error.message == 'Unauthenticated.')) {
    //           this.authService.logout();
    //         } else{
    //           console.log(error);
    //         }
    //         return throwError(error);
    //       })
    //     );
    //   })
    // );
  }
}

export const TokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
};
