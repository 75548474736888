import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginEventService } from 'src/app/services/login-event.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  token: any;
  id: any;

  isLoading: boolean = false;

  constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router, private authService: AuthService, public loginEvent: LoginEventService) { }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.token = params['token'];
      this.id = params['id'];
    });
  }

  onReset(form: NgForm) {
    if (!form.valid) return;

    const password = form.value.password;
    const confirmation = form.value.confirmation;

    if (password !== confirmation) {
      this.snackBar.open("Les mots de passe ne correspondent pas", "OK", {
        panelClass: ['error-snackbar']
      });
      return;
    }

    this.isLoading = true;

    this.authService.newPassword(this.id, this.token, password).subscribe(
      resData => {
        this.snackBar.open("Mot de passe modifié", "OK", {
          panelClass: ['success-snackbar']
        });
        this.router.navigate(['/login']);
      },
      errRes => {
        this.snackBar.open(errRes.message, "OK", {
          panelClass: ['error-snackbar']
        });
        this.isLoading = false;
      }
    );

    form.resetForm();
  }

}
