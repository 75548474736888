<div *ngIf="question" class="page">
  <div class="head">
    <div class="back">
      <button
        mat-icon-button
        class="copy"
        matTooltip="Revenir"
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <h1>{{ question.title }}</h1>
    <button
      mat-icon-button
      class="delete"
      matTooltip="Supprimer la question"
      (click)="onDelete()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
  <mat-card>
    <div class="infos">
      <div class="row">
        <mat-form-field appearance="fill">
          <mat-label>Titre de la question</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="title"
            (input)="isTitleValid()"
          />
        </mat-form-field>
      </div>
      <div
        *ngIf="isTitleDisabled"
        style="
          color: red;
          width: auto;
          margin: auto auto 32px 10px;
          font-weight: 600;
        "
      >
        N'utilisez pas d'emojis ou de caractères spéciaux comme #,&,*,/... svp.
      </div>

      <div class="row" style="flex-direction: column">
        <mat-form-field appearance="fill">
          <mat-label>Catégorie(s)</mat-label>
          <mat-select
            [formControl]="categories"
            type="text"
            multiple
            placeholder="Selectionner une ou plusieurs catégorie"
            (selectionChange)="onCategoriesSelect($event)"
          >
            <!-- <mat-select  [formControl]="categories" type="text" multiple placeholder="Selectionner une ou plusieurs catégorie"> -->
            <mat-select-trigger>Sélectionner</mat-select-trigger>
            <mat-option *ngFor="let cat of allCategories" [value]="cat.id">
              {{ cat.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="catList" *ngIf="categoriesList.length">
          <mat-chip-list>
            <mat-chip *ngFor="let category of categoriesList" disableRipple>{{
              category.title
            }}</mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div class="row" style="flex-direction: column">
        <h2>Importer une image</h2>
        <div class="rowSlide">
          <div
            class="custom-dropzone"
            ngx-dropzone
            [accept]="'image/*'"
            (change)="onSelectSlide($event)"
            *ngIf="fileSlides?.length == 0"
          >
            <mat-icon>cloud_upload</mat-icon>
          </div>
          <ngx-dropzone-image-preview
            ngProjectAs="ngx-dropzone-preview"
            *ngFor="let f of fileSlides"
            [file]="f"
            [removable]="true"
            (removed)="onRemoveSlide()"
          >
          </ngx-dropzone-image-preview>
        </div>
      </div>
    </div>
    <div class="action">
      <button mat-raised-button class="success" (click)="onSave()">
        Enregistrer
      </button>
    </div>
  </mat-card>
</div>
