import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    // return this.authService.user.pipe(
    //   take(1),
    //   map(user => {
    //     const isAuth = !user ? false : true;
    //     if (isAuth) {
    //       return true;
    //     }
    //     return this.router.createUrlTree(['/login']);
    //   })
    // );

    return this.authService.jwt_token.pipe(
      take(1),
      map(token => {
        const isAuth = token ? true : false;
        if (isAuth) {
          return true;
        }
        return this.router.createUrlTree(['/login']);
      })
    );
  }

}