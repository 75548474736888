import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() paymentId: string = '';

  url: any;
  publicKey: string = 'pprod_cUQUzMg8UusXseQ2iKKVMAbL';

  @Input() price_ht: any;
  @Input() price_ttc: any;
  @Input() taxe: any;
  @Input() credits: any;
  @Input() basket_id: any;

  constructor(private router: Router, private snackBar: MatSnackBar, private domSanitizer: DomSanitizer, private apiService: ApiService) { }

  ngOnInit(): void {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(`https://payment.stancer.com/${this.publicKey}/${this.paymentId}`);    
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    if (event.origin !== 'https://payment.stancer.com') {
      return;
    }
    if (event.data.status) {
      if (event.data.status == "error" || event.data.status == "invalid") {
        this.snackBar.open("Une erreur est survenue lors du paiement", "OK", {
          panelClass: ['error-snackbar']
        });
      }
      if (event.data.status == "finished") {
        this.snackBar.open("Paiement validé", "OK", {
          panelClass: ['success-snackbar']
        });

        this.apiService.getStancerPayment(`https://api.stancer.com/v1/checkout/${this.paymentId}`).pipe(first()).subscribe({
          next: (v) => {
            this.apiService.addPurchase({validity: '1', price_ht: this.price_ht, price_ttc: this.price_ttc, taxe: this.taxe, credits: this.credits, gift: '0', basket_id: this.basket_id, bank_token: v.data.order_id}).pipe(first()).subscribe({
              next: (v) => {
                this.apiService.addBasket().pipe(first()).subscribe({
                  next: (v) => {
                    setTimeout(() => {
                      this.router.navigate(['/account/credits']).then(() => {
                        window.location.reload();
                      });
                    }, 1000);
                  },
                  error: (e) => {
                  }
                });
              },
              error: (e) => {
                this.snackBar.open("Une erreur est survenue lors de l'attribution des crédits. Le paiement est bien valide. Merci de nous contacter.", "OK", {
                  panelClass: ['error-snackbar']
                });
                console.error(e)
              }
            });
          },
          error: (e) => {
            this.apiService.addPurchase({validity: '1', price_ht: this.price_ht, price_ttc: this.price_ttc, taxe: this.taxe, credits: this.credits, gift: '0', basket_id: this.basket_id, bank_token: 'Stancer call failed'}).pipe(first()).subscribe({
              next: (v) => {
                this.apiService.addBasket().pipe(first()).subscribe({
                  next: (v) => {
                    this.router.navigate(['/account/credits']).then(() => {
                      window.location.reload();
                    });
                  },
                  error: (e) => {
                  }
                });
              },
              error: (e) => {
                this.snackBar.open("Une erreur est survenue lors de l'attribution des crédits. Le paiement est bien valide. Merci de nous contacter.", "OK", {
                  panelClass: ['error-snackbar']
                });
                console.error(e)
              }
            });
            console.error(e)
          }
        });
      }
    }
  }

}