<mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Question</mat-label>
    <input type="text"
          placeholder="Rechercher une question existant"
          aria-label="Question"
          matInput
          [(ngModel)]="data.question"
          (keyup)="filter()"
          [matAutocomplete]="autoQuestions"
          >
    <mat-autocomplete #autoQuestions="matAutocomplete">
      <mat-option *ngFor="let q of questionsOptionsFiltered" [value]="q">
        {{ q }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Annuler</button>
  <button mat-button class="success" [mat-dialog-close]="data.question" cdkFocusInitial>Valider</button>
</mat-dialog-actions>