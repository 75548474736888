import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { BrandsService } from 'src/app/services/brands.service';
import { first } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  isLoading: boolean = true;

  currentTabName: string = '';
  currentTabIndex: number = 0;

  user: any;

  products: any = [];

  paymentPage: boolean = false;
  paymentId: string = '';

  price_ht: number = 0;
  price_ttc: number = 0;
  taxe: number = 0;
  credits: number = 0;
  basket_id: number = 0;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private brandsService: BrandsService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    const jsonData: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(jsonData);
    if (userData) {
      this.updateUser(userData);
    }
  }

  updateUser(userData: any) {
    this.apiService
      .getUser(userData.id)
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.user = v.data[0];
          if (this.user.baskets.length) {
            this.getProducts();
          } else {
            this.isLoading = false;
          }

          this.apiService
            .listActiveSubscription()
            .pipe(first())
            .subscribe({
              next: (v) => {
                if (v.data.length == 0) {
                  this.user.subscription_type_active =
                    this.user.subscription_type;
                  return;
                }

                const dateDisabled = new Date(
                  v.data[v.data.length - 1].disable_date
                );
                const lastPayment = new Date(
                  v.data[v.data.length - 1].next_payment
                );

                if (dateDisabled == null) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (lastPayment != null && new Date() < lastPayment) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (
                  lastPayment != null &&
                  new Date() > lastPayment &&
                  new Date() > dateDisabled
                ) {
                  this.user.subscription_type_active = 'Freemium';
                }
              },
              error: (e) => console.error(e),
            });
        },
        error: (e) => console.error(e),
      });
  }
  getProducts() {
    this.products = [];

    if (
      !this.user.baskets[this.user.baskets.length - 1].basket_products.length
    ) {
      this.isLoading = false;
      return;
    }

    this.user.baskets[this.user.baskets.length - 1].basket_products.forEach(
      (product: any, index: any) => {
        this.apiService
          .getProduct(product.product_id)
          .pipe(first())
          .subscribe({
            next: (v) => {
              this.products.push(v.data[0]);

              this.price_ht = 0;
              this.price_ttc = 0;
              this.taxe = 0;
              this.credits = 0;

              this.products.forEach((product: any) => {
                this.price_ht = this.price_ht + product.price_ht;
                this.price_ttc = this.price_ttc + product.price_ttc;
                this.taxe = this.taxe + product.taxe;
                this.credits = this.credits + product.credits;
              });

              this.basket_id =
                this.user.baskets[this.user.baskets.length - 1].id;

              if (
                index + 1 ==
                this.user.baskets[this.user.baskets.length - 1].basket_products
                  .length
              ) {
                this.isLoading = false;
              }
            },
            error: (e) => console.error(e),
          });
      }
    );
  }

  removeProduct(product: any) {
    this.apiService
      .removeProductFromBasket(
        product.id,
        this.user.baskets[this.user.baskets.length - 1].id
      )
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.snackBar.open('Produit supprimé du panier', 'OK', {
            panelClass: ['success-snackbar'],
          });
          this.initialization();
        },
        error: (e) => {
          this.snackBar.open('Une erreur est survenue', 'OK', {
            panelClass: ['error-snackbar'],
          });
          console.error(e);
        },
      });
  }

  validateCart() {
    this.isLoading = true;

    this.apiService
      .createStancerIntent(this.price_ttc, 'eur', {
        order_id: String(this.user.baskets[this.user.baskets.length - 1].id),
        auth: true,
      })
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.paymentId = v.data.id;
          this.paymentPage = true;
          this.isLoading = false;
        },
        error: (e) => {
          this.snackBar.open('Une erreur est survenue.', 'OK', {
            panelClass: ['error-snackbar'],
          });
          this.isLoading = false;
        },
      });
  }

  viewAbonnements() {
    this.router.navigate(['/account', 'abonnement']);
  }

  viewCredits() {
    this.router.navigate(['/account', 'credits']);
  }
}
