import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandsService } from 'src/app/services/brands.service';
import { first, last } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  isLoading: boolean = false;

  currentTabName: string = '';
  currentTabIndex: number = 0;

  user: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private brandsService: BrandsService,
    private apiService: ApiService
  ) {
    this.brandsService.getBrand().subscribe((res: any) => {
      this.initialization();
    });
  }

  ngOnInit(): void {
    this.initialization();
  }

  initialization() {
    this.currentTabName = 'infos';
    this.currentTabIndex = 0;
    this.route.params.subscribe((params: any) => {
      if (params['tab']) {
        this.currentTabName = params['tab'];
        switch (this.currentTabName) {
          case 'infos':
            this.currentTabIndex = 0;
            break;
          case 'credits':
            this.currentTabIndex = 1;
            break;
          case 'historique':
            this.currentTabIndex = 2;
            break;
          case 'abonnement':
            this.currentTabIndex = 3;
            break;
          default:
            this.currentTabName = 'infos';
            this.currentTabIndex = 0;
            break;
        }
      }
    });

    const jsonData: any = localStorage.getItem('userData');
    const userData: any = JSON.parse(jsonData);
    if (userData) {
      this.updateUser(userData);
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.currentTabIndex = event.index;
    switch (this.currentTabIndex) {
      case 0:
        this.router.navigate(['/account/infos']);
        break;
      case 1:
        this.router.navigate(['/account/credits']);
        break;
      case 2:
        this.router.navigate(['/account/historique']);
        break;
      case 3:
        this.router.navigate(['/account/abonnement']);
        break;
    }
  }

  updateUser(userData: any) {
    this.apiService
      .getUser(userData.id)
      .pipe(first())
      .subscribe({
        next: (v) => {
          this.user = v.data[0];
          this.apiService
            .listActiveSubscription()
            .pipe(first())
            .subscribe({
              next: (v) => {
                if (v.data.length == 0) {
                  this.user.subscription_type_active = this.user.subscription_type;
                  return;
                }

                const dateDisabled = new Date(
                  v.data[v.data.length - 1].disable_date
                );
                const lastPayment = new Date(
                  v.data[v.data.length - 1].next_payment
                );

                if (dateDisabled == null) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (lastPayment != null && new Date() < lastPayment) {
                  this.user.subscription_type_active =
                    v.data[v.data.length - 1].subscription_type;
                } else if (
                  lastPayment != null &&
                  new Date() > lastPayment &&
                  new Date() > dateDisabled
                ) {
                  this.user.subscription_type_active = 'Freemium';
                }
              },
              error: (e) => console.error(e),
            });
        },
        error: (e) => console.error(e),
      });
  }

  viewAbonnements() {
    this.router.navigate(['/account', 'abonnement']);
  }

  viewCredits() {
    this.router.navigate(['/account', 'credits']);
  }
}
